import React, {useCallback, useEffect, useState} from 'react';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import {Link} from 'react-router-dom';
import {registerGlobalStyle} from '../../../theme';
import {Button, TextInput} from '../../Basic';
import {PopupItem} from '../../Popovers';
import {GridCsvExportButton} from './GridCsvExportButton';

registerGlobalStyle('.power-grid .power-grid-toolbar', (theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5, 1.5, 1.5),
  '> div': {
    display: 'flex',
    columnGap: theme.spacing(2),
    '*': {
      minWidth: 'unset',
      textWrap: 'nowrap',
    },
    '.text-input': {fontSize: '1rem'},
    '.action-button': {fontWeight: theme.typography.dark}
  },
}));

export function GridToolbar({search: initialSearch, viewChanged, viewReadOnly, onSaveView, onChangeSearch}) {
  const [search, setSearch] = useState(initialSearch);

  useEffect(() => {
    setSearch(initialSearch);
  }, [initialSearch]);

  const handleChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
    onChangeSearch(e.target.value);
  }, [onChangeSearch]);

  const handleClearSearch = useCallback(() => {
    setSearch('');
    onChangeSearch('');
  }, [onChangeSearch]);

  return (
    <GridToolbarContainer className={'power-grid-toolbar'} sx={{}}>
      <div>
        <TextInput value={search} placeholder={'Search...'} onChange={handleChangeSearch} container clearable={!!search} onClear={handleClearSearch}/>
        <GridToolbarFilterButton>hello</GridToolbarFilterButton>
        <GridToolbarColumnsButton/>
        <GridToolbarDensitySelector/>
        <GridCsvExportButton/>
        {onSaveView && viewChanged && viewReadOnly &&
          <Button navPrimary text={'Save as New View'} onClick={() => onSaveView(false)}/>
        }
        {onSaveView && viewChanged && !viewReadOnly &&
          <Button navPrimary menu noWrap text={'Save View'}>
            <PopupItem onClick={() => onSaveView(false)}>As Existing View</PopupItem>
            <PopupItem onClick={() => onSaveView(true)}>As New View</PopupItem>
          </Button>
        }
      </div>
      <div>
        <Link to={'/settings/automations'}  style={{textDecoration: 'none'}}>
          <Button actionPrimary suffix={FlashOnIcon} text={'Automations'}/>
        </Link>
      </div>
    </GridToolbarContainer>
  );
}
