import React, {useCallback, useEffect, useState} from 'react';
import {Button, UserAvatar} from '../../componentsLib/Basic';
import {
  AutoCompleteContainer,
  AutoCompleteEndButtons,
  AutoCompleteList,
  AutoCompleteListItem,
  AutoCompletePopover,
  AutoCompleteSearchBar,
  AutoCompleteTextInput
} from '../../componentsLib/Popovers';
import {HoopsPropTypes} from '../../componentsLib';
import {useListVendors} from '../../hooks/api';
import {Column, Row} from '../../componentsLib/Layout';
import {initials} from '../../utils';
import {hoopsTheme} from '../../theme';
import {BodyText} from '../../componentsLib/Text';

export function VendorAutoComplete({className, label, vendor: initVendor, search: initSearch, onAddNewVendor, onChange, onSearchChange}) {
  const [currentVendor, setVendor] = useState(initVendor);
  const [search, setSearch] = useState(initSearch);
  const {data: {vendors}, isLoading} = useListVendors({search: search ?? ''});

  useEffect(() => {
    setVendor(initVendor);
  }, [initVendor]);

  useEffect(() => {
    setSearch(initSearch);
  }, [initSearch]);

  const handleChange = useCallback((_id) => {
    const newVendor = vendors.find((prod) => prod._id === _id);
    if (newVendor) {
      setVendor(newVendor);
      if (currentVendor !== newVendor) {
        onChange?.(newVendor);
      }
    }
  }, [vendors, currentVendor, onChange]);

  const handleSearchChange = useCallback((e) => {
    const newSearch = e.target.value;
    if (newSearch !== search) {
      onSearchChange?.(newSearch);
      setSearch(newSearch);
    }
  }, [onSearchChange, search]);

  return (
    <AutoCompleteContainer
      className={[className, 'vendor-autocomplete']}
      label={label}
      value={currentVendor?._id}
      search={search}
      onChange={handleChange}
      onSearchChange={handleSearchChange}
      loading={isLoading}
    >
      <AutoCompleteTextInput value={currentVendor?.name}/>
      <AutoCompletePopover className={'vendor-autocomplete-popover'}>
        <AutoCompleteSearchBar search={search}/>
        <AutoCompleteList>
          {vendors?.map((vendor) => (
            <AutoCompleteListItem key={vendor._id} value={vendor._id}>
              <Row gap>
                <UserAvatar color={hoopsTheme.colors.palette.purple} initials={initials(vendor.name)}/>
                <Column className={'vendor-details'} justifyCenter>
                  <BodyText className={'title'}>
                    {vendor.name}
                  </BodyText>
                </Column>
              </Row>
            </AutoCompleteListItem>
          ))}
        </AutoCompleteList>
        <AutoCompleteEndButtons>
          <Button navPrimary onClick={onAddNewVendor}>Add New Vendor</Button>
        </AutoCompleteEndButtons>
      </AutoCompletePopover>
    </AutoCompleteContainer>
  );
}

VendorAutoComplete.propTypes = {
  className: HoopsPropTypes.className,
  label: HoopsPropTypes.string,
  search: HoopsPropTypes.string,
  vendor: HoopsPropTypes.any,
  onAddNewVendor: HoopsPropTypes.func,
  onChange: HoopsPropTypes.func,
  onSearchChange: HoopsPropTypes.func,
};
