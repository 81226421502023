import {gql, useMutation} from '@apollo/client';
import {DialogContent, Link, Menu, Typography} from '@mui/material';
import {get} from 'lodash';
import moment from 'moment';
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {snackError, snackSuccess} from '../../../actions/action-types';
import {draftInvoiceFromJob, getInvoice} from '../../../actions/invoice';
import {SET_JOB, setEmailType, UPSERT_JOBS} from '../../../actions/jobs';
import {openJobDeleteModal, openProofUploadModal, openSendProofModal, setInvoiceModalOnClose} from '../../../actions/modals';
import {editNewTask} from '../../../actions/tasks';
import {HoopsMenuItem} from './HoopsMenuItem';
import {DecoratorSpecSheetModal} from '../../../componentsOld/shared/modals/DecoratorSpecSheetModal';
import {PermissionBasedContent, SubscriptionTypes} from '../../../componentsHoops/AccessControl';
import {GQL_DELETE_JOB} from '../../../queries/jobs';
import {useUpdateJob} from '../../../hooks/api';
import {hoopsQuerySetRefetch} from '../../../actions/hoopsQuery';
import {useState} from 'react';
import theme from '../../../ui/theme';
import {AddPrurchaseOrderModal} from '../../../componentsOld/shared/modals/PurchaseOrdersModals/AddPurchaseOrderModal';
import {AttentionConfirmDialog} from '../../../componentsLib/Popovers';
import {XeroPaymentInfoDialog} from '../../../componentsHoops/Invoice';

const ARCHIVE = gql`
    mutation jobSetArchived($jobId: String!, $archived: Boolean!){
        jobSetArchived(jobId: $jobId,archived: $archived){
            _id
            archived
        }
    }
`;

const DUPLICATE = gql`
    mutation jobDuplicate($jobId: String!){
        jobDuplicate(jobId: $jobId){
            _id
        }
    }`;

export const JobsPageActionMenu = ({
  job = {},
  onClose = () => null,
  updateData = () => null,
  ...props
}) => {
  const [openDecoratorSpecSheetModal, setOpenDecoratorSpecSheetModal] = React.useState(false);
  const [openVoidedModal, setOpenVoidedModal] = useState(false);
  const [openJobCompletedModal, setOpenJobCompletedModal] = useState(false);
  const [xeroPaymentInfoDialogOpen, setXeroPaymentInfoDialogOpen] = useState(false);
  const [accountingConnected, setAccountingConnected] = React.useState(false);
  const [openAddPo, setOpenAddPo] = React.useState(false);
  const handleAddPo = () => {
    dispatch({type: SET_JOB, payload: {...job, completed: true}});
    setOpenAddPo(true);
  };

  const dispatch = useDispatch();

  const userSelector = (state) => state.authReducer.userData;
  const user = useSelector(userSelector);

  const [setArchived] = useMutation(ARCHIVE, {
    onCompleted: (values) => {
      dispatch(snackSuccess(`Job ${job.number} ${values.jobSetArchived.archived ? 'Archived' : 'Unarchived'}`));
      updateData();
    },
    onError: (err) => dispatch(snackError(err.message))
  });

  const {mutateAsync: updateJob} = useUpdateJob();

  const [jobDuplicate] = useMutation(DUPLICATE, {
    onCompleted: () => {
      dispatch(snackSuccess(`Job ${job.number} duplicated.`));
      updateData();
    },
    onError: (err) => dispatch(snackError(err.message))
  });

  const [jobDelete] = useMutation(GQL_DELETE_JOB, {
    onCompleted: () => {
      dispatch(snackSuccess(`Job ${job.number} deleted.`));
      updateData();
    },
    onError: (err) => dispatch(snackError(err.message))
  });

  const pdfLink = `${process.env.REACT_APP_BASE_URL}/jobs/${job._id}/pdf`;
  const packingSlipLink = `${process.env.REACT_APP_BASE_URL}/jobs/${job._id}/packing-slip/pdf`;
  const shippingLabelLink = `${process.env.REACT_APP_BASE_URL}/jobs/${job._id}/shipping-label`;
  const invoicePdfLink = `${process.env.REACT_APP_BASE_URL}/rest/accounting/invoices/${(job.digest?.invoice ?? job.invoice)?._id}/pdf`;

  React.useEffect(() => {
    const refreshTokenExpiry = get(user, 'company.accountingPlatform.refreshTokenExpiry', 0);
    if (moment(refreshTokenExpiry, 'x').isAfter()) {
      setAccountingConnected(true);
    }
  }, [user]);

  const handleArchived = (archived) => {
    setArchived({variables: {archived, jobId: job._id}});
    onClose();
  };

  const handleCompletedMenuItem = async () => {
    setOpenJobCompletedModal(true);
  };
  const handleCompletedConfirmation = async (confirmed) => {
    onClose();
    setOpenJobCompletedModal(false);
    if (confirmed) {
      await updateJob({id: job._id, data: {completed: true}}, {successMessage: `Job ${job.number} Completed`});
      handleArchived(true);
      dispatch({type: SET_JOB, payload: {...job, completed: true}});
      dispatch({type: UPSERT_JOBS, payload: {...job, completed: true}});
      dispatch(hoopsQuerySetRefetch('job', true));
      updateData();
    }
  };

  const handleVoidedMenuItem = async () => {
    setOpenVoidedModal(true);
  };
  const handleVoidedConfirmation = async (confirmed) => {
    onClose();
    setOpenVoidedModal(false);
    if (confirmed) {
      await updateJob({id: job._id, data: {voided: true}}, {successMessage: `Job ${job.number} Voided`});
      dispatch({type: SET_JOB, payload: {...job, voided: true}});
      dispatch({type: UPSERT_JOBS, payload: {...job, voided: true}});
      dispatch(hoopsQuerySetRefetch('job', true));
      updateData();
    }
  };

  const handleUploadProof = () => {
    dispatch(openProofUploadModal(job._id));
    onClose();
  };

  const handleDuplicate = () => {
    jobDuplicate({variables: {jobId: job._id}});
    onClose();
  };

  const handleAddTask = () => {
    dispatch(editNewTask({
      jobIds: [job._id],
      jobs: [job]
    }));
    onClose();
  };

  const handleSend = async () => {
    dispatch({
      type: SET_JOB,
      payload: job
    });
    dispatch(setEmailType('job'));
    dispatch(openSendProofModal({jobId: job._id}));
  };

  const handleDelete = () => {
    const _onDelete = async () => {
      const res = await jobDelete({variables: {_id: job._id}});
      await updateData();
      return res;
    };

    dispatch(openJobDeleteModal({jobId: job._id, onDelete: _onDelete}));
    onClose();
  };

  const handleGetInvoice = () => {
    const invoiceId = job.digest?.invoice?._id;
    dispatch(setInvoiceModalOnClose(updateData));
    if (!invoiceId) {
      dispatch(draftInvoiceFromJob(job._id));
      onClose();
    } else if (user?.company?.accountingPlatform?.platform === 'XERO' && job.digest?.invoice?.balance < job.digest?.invoice?.totalAmount) {
      setXeroPaymentInfoDialogOpen(true);
    } else {
      dispatch(getInvoice(invoiceId));
      onClose();
    }
  };

  return (
    <>
      {openAddPo && <AddPrurchaseOrderModal jobId={job._id} open={openAddPo} closeModal={() => setOpenAddPo(false)}/>}
      {openDecoratorSpecSheetModal && <DecoratorSpecSheetModal jobId={job._id} handleClose={() => setOpenDecoratorSpecSheetModal(false)} />}
      {openVoidedModal && <VoidJobConfirmation onConfirm={handleVoidedConfirmation} />}
      {openJobCompletedModal && <CompleteJobConfirmation onConfirm={handleCompletedConfirmation} />}
      {xeroPaymentInfoDialogOpen && <XeroPaymentInfoDialog open={xeroPaymentInfoDialogOpen} onClose={() => { setXeroPaymentInfoDialogOpen(false); onClose(); }} />}
      <Menu onClose={onClose} {...props}>
        {!job.completed && !job.voided &&
          <HoopsMenuItem icon='edit' text='Edit' intercom={'job-edit'} to={`/jobs/${job._id}/edit`} />
        }
        {!job.voided &&
          <>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}><HoopsMenuItem icon='send' text='Send'
                                                                                                        onClick={handleSend} /></PermissionBasedContent>
            <HoopsMenuItem icon='description' text='PDF Job Sheet' href={pdfLink} />
            <HoopsMenuItem icon='description' text='PDF Proof Sheet' component={Link} href={`${pdfLink}?withProofs=1`} />
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}><HoopsMenuItem icon='description' text='PDF Packing Slip'
                                                                                                        href={`${packingSlipLink}`} /></PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}><HoopsMenuItem icon='description' text='PDF Decorator Spec Sheet'
                                                                                                        intercom={'job-add-po'}
                                                                                                        onClick={() => setOpenDecoratorSpecSheetModal(true)} /></PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
              <HoopsMenuItem icon='file_copy' text='Duplicate' intercom={'job-duplicate'} onClick={handleDuplicate} />
            </PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}><HoopsMenuItem icon='local_shipping' text='Shipping Label'
                                                                                                        href={`${shippingLabelLink}`} /></PermissionBasedContent>
            <HoopsMenuItem
              icon='image'
              text='Upload Proof'
              intercom={'job-upload-proof'}
              onClick={handleUploadProof}
            />
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
              <HoopsMenuItem
                icon='description'
                text='Add Purchase Order'
                intercom={'job-add-po'}
                onClick={handleAddPo}
              />
            </PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}><HoopsMenuItem icon='assignment_turned_in' text='Add Task'
                                                                                                        intercom={'job-add-task'}
                                                                                                        onClick={handleAddTask} /></PermissionBasedContent>
          </>
        }
        <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
          {job.archived
            ? <HoopsMenuItem icon='unarchived' text='UnArchive' intercom={'job-unarchived'} onClick={() => handleArchived(false)} />
            : <HoopsMenuItem icon='archived' text='Archive' intercom={'job-archived'} onClick={() => handleArchived(true)} />
          }
        </PermissionBasedContent>
        <PermissionBasedContent allowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
          {(job.completed || job.voided) &&
            (job.archived
              ? <HoopsMenuItem icon='unarchived' text='UnArchive' intercom={'job-unarchived'} onClick={() => handleArchived(false)} />
              : <HoopsMenuItem icon='archived' text='Archive' intercom={'job-archived'} onClick={() => handleArchived(true)} />
            )
          }
        </PermissionBasedContent>
        {!job.voided &&
          <>
            <PermissionBasedContent allowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
              {!job.completed && (job.digest?.invoice ?? job.invoice)?._id &&
                <HoopsMenuItem icon='attach_money' text='Mark as Complete' intercom={'job-completed'} onClick={() => handleCompletedMenuItem()} />
              }
            </PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
              <HoopsMenuItem icon='delete' text='Delete' intercom={'job-delete'} onClick={handleDelete} />
            </PermissionBasedContent>
            <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
              <>
                {accountingConnected && !job.completed && !job.voided &&
                  <HoopsMenuItem icon='payment' text={(job.digest?.invoice ?? job.invoice)?._id ? 'Edit Invoice' : 'Create Invoice'} onClick={handleGetInvoice} />
                }
                {accountingConnected && !job.completed && !job.voided && (job.digest?.invoice ?? job.invoice)?._id &&
                  <HoopsMenuItem icon='description' text='Get Invoice PDF' href={invoicePdfLink} />
                }
              </>
            </PermissionBasedContent>
          </>
        }
        <PermissionBasedContent allowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
          {!job.voided && <HoopsMenuItem icon='cancel' text='Void' intercom={'job-voided'} onClick={() => handleVoidedMenuItem(true)} />
          }
        </PermissionBasedContent>
      </Menu>
    </>
  );
};

export const CompleteJobConfirmation = ({onConfirm}) => (
  <AttentionConfirmDialog
    onClose={onConfirm}
    title={<Typography variant={'h1'}>Mark Job as Complete</Typography>}
    okText={'Mark as Complete'}
    cancelText={'Cancel'}
    warningIcon
    mild
  >
    <DialogContent>
      <Typography sx={{px: 8, color: theme.colors.grey.dark}} align={'center'}>
        Are you sure you want to mark this Job as Complete?
        <br /> No further edits can be made to the Job or Invoice.
        <br /><br /> This action cannot be undone.
      </Typography>
    </DialogContent>
  </AttentionConfirmDialog>
);

export const VoidJobConfirmation = ({onConfirm}) => (
  <AttentionConfirmDialog
    onClose={onConfirm}
    title={<Typography variant={'h1'}>Void Job</Typography>}
    okText={'Void'}
    cancelText={'Cancel'}
    warningIcon
    mild
  >
    <DialogContent>
      <Typography sx={{px: 8, color: theme.colors.grey.dark}} align={'center'}>
        Are you sure you want to Void this Job?
        <br /><br /> This action cannot be undone.
      </Typography>
    </DialogContent>
  </AttentionConfirmDialog>
);
