import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {Page, Row} from '../../../componentsLib/Layout';
import {useLocalStorageState, useS3UploadCache, useSnackbar} from '../../../hooks';
import {registerGlobalStyle} from '../../../theme';
import {useSalesDocGuestState} from '../State/useSalesDocGuestState';
import {PresentationView} from '../Views/PresentationView';
import {PdfPrintService} from '../../../services';
import {SalesDocView} from '../Views/SalesDocView';
import {BusySpinner} from '../../../componentsLib/Basic';
import {SalesDocSections} from '../Components';
import {ShoppingCartDrawer} from '../Cart/ShoppingCartDrawer';
import {useSalesDocCartState} from '../State/useSalesDocCartState';
import {LoadingDialog} from '../../../componentsLib/Popovers';
import {ReportErrorBoundary} from '../../../componentsHoops/Errors';

registerGlobalStyle('.salesdoc-guest-page', (theme) => ({
  '&.salesdoc-guest-page:has(.pdf-view)': {'.page-scroll-container': {overflow: 'hidden'}},
  '.salesdoc-view': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.loading-row': {height: '100%'},
  '.salesdoc-container': {height: '100%'},
  minWidth: 300,
  '&.presentation': {
    background: theme.colors.background.white,
    display: 'flex',
    justifyContent: 'center',
    '.presentation-view': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 1420,
      marginBottom: theme.spacing(10),
      height: 'max-content',
      '.product-gallery': {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(1),
        paddingLeft: 0
      }
    },
  },
  '.shopping-cart-drawer': {[theme.breakpoints.down('md')]: {'.toolbox-drawer': {width: '100%'}}}
}));

export function SalesDocGuestPage({salesDocId: salesDocIdProp, hideActionButtons, printPdf, onPrintPdfComplete}) {
  const {salesDocId: salesDocIdParam, 0: path} = useParams();
  const isHostedSalesDoc = !window.location.hostname.includes('hoopscrm') && !window.location.hostname.includes('localhost');
  const {salesDoc, salesDocToken, isLoading: salesDocLoading, refetchSalesDoc, updateCache, company, cartTemplate, apiError} =
    useSalesDocGuestState({
      guestToken: salesDocIdProp ?? salesDocIdParam,
      hosting: isHostedSalesDoc && {hostname: window.location.hostname, path}
    });
  const currencySymbol = company.currencySymbol;
  const [pdfLoading, setPdfLoading] = useState(false);
  const snackbar = useSnackbar();
  const salesDocPdf = useRef(null);
  const [selection, setSelection] = useState({section: SalesDocSections.documentHeader});
  const [cartOpen, setCartOpen] = useState(false);
  const {cartDoc, saveCart, cartToken, clearCart} = useSalesDocCartState({salesStore: salesDoc, cartTemplate});

  const isBuilder = false;
  const isPresentation = salesDoc?.isPresentation();

  const uploadCache = useS3UploadCache({anon: true, folder: company?._id});

  const [zoomProps, setZoomProps] = useState({zoom: 100, translateX: 0});
  const [autoZoom, _setAutoZoom] = useLocalStorageState('salesdoc|guestView|autoZoom', true);
  const zoomRef = useRef(null);

  const setZoom = useCallback((newZoom) => {
    if (zoomRef.current) {
      const paperElem = zoomRef.current.querySelector('.salesdoc-paper');
      if (paperElem) {
        const containerWidth = zoomRef.current.offsetWidth;
        const paperWidth = paperElem.offsetWidth;
        const availWidth = containerWidth;
        const zoomFit = 100 * (availWidth - 16) / paperWidth;
        const zoom = newZoom === 'fit' ? Math.min(100, zoomFit) : newZoom;
        const translateX = Math.max(0, (availWidth - paperWidth * zoom / 100) / 2);
        setZoomProps({zoom, translateX});
        _setAutoZoom((prev) => newZoom === 'fit' ? prev : false);
      }
    }
  }, [_setAutoZoom]);

  const setAutoZoom = useCallback((az) => {
    if (az) {
      setZoom('fit');
    }
    _setAutoZoom(az);
  }, [_setAutoZoom, setZoom]);

  useLayoutEffect(() => {
    setZoom(autoZoom ? 'fit' : 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomRef.current != null, salesDoc != null]);

  const handleDownloadPdf = useCallback(async () => {
    if (salesDocPdf.current) {
      setZoom(100);
      setPdfLoading(true);
      const pdf = await PdfPrintService.print(salesDocPdf.current);
      if (!document.body.contains(salesDocPdf.current)) {
        snackbar.showSnackbarError('PDF download canceled');
      } else if (pdf) {
        await pdf.save(`${salesDoc.docTypeName}-${salesDoc.number}`);
      } else {
        snackbar.showSnackbarError();
      }
      setPdfLoading(false);
      setZoom(autoZoom ? 'fit' : zoomProps.zoom);
    }
  }, [autoZoom, salesDoc, setZoom, snackbar, zoomProps]);

  useEffect(() => {
    (async () => {
      if (printPdf && salesDocPdf.current) {
        // If these conditions have been met, this component has been accessed through an action menu to generate a PDF.
        await handleDownloadPdf();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesDoc]);

  useEffect(() => {
    if (isHostedSalesDoc) {
      let link = document.getElementById('favicon');
      link.href = salesDoc?.hosting?.faviconUrl ?? '/blank_favicon.png';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesDoc?.hosting]);

  if ((salesDocIdProp ?? salesDocIdParam) === '123') {
    salesDoc.notAFunction(); // TODO: REMOVE THIS BEFORE DEPLOY ? MAYBE ??
  }

  const context = useMemo(
    () => ({
      salesDoc,
      salesDocToken: cartToken ?? salesDocToken,
      cartDoc,
      cartOpen,
      setCartOpen,
      company,
      currencySymbol,
      refetchSalesDoc,
      isBuilder,
      hideActionButtons,
      printPdf,
      onPrintPdfComplete,
      handleDownloadPdf,
      pdfLoading,
      salesDocPdf,
      saveCart,
      clearCart,
      selection,
      setSelection,
      updateCache,
      uploadCache,
      zoom: {
        zoom: zoomProps.zoom,
        setZoom,
        auto: autoZoom,
        setAuto: setAutoZoom,
        fitScreen: () => setZoom('fit'),
      },
    }),
    [
      salesDoc,
      salesDocToken,
      cartDoc,
      cartOpen,
      cartToken,
      setCartOpen,
      company,
      currencySymbol,
      refetchSalesDoc,
      isBuilder,
      hideActionButtons,
      printPdf,
      onPrintPdfComplete,
      handleDownloadPdf,
      pdfLoading,
      saveCart,
      clearCart,
      salesDocPdf,
      selection,
      setSelection,
      updateCache,
      uploadCache,
      zoomProps,
      setZoom,
      autoZoom,
      setAutoZoom,
    ]
  );

  return (
    <Page
      testId={'salesdoc-guest-page'}
      className={['salesdoc-guest-page', isPresentation && 'presentation']}
      context={context}
      title={salesDoc?.hosting?.title ?? 'Hoops'}
      alignCenter
    >
        {!salesDoc && salesDocLoading &&
          <Row justifyCenter alignCenter className={'loading-row'}>
            <BusySpinner large center message={'Loading'} />
          </Row>
        }
        {printPdf && pdfLoading &&
          <LoadingDialog />
        }
        {salesDoc && !isPresentation &&
          <Row className={'salesdoc-container'} ref={zoomRef} style={{'--zoom': zoomProps.zoom / 100, '--zoom-x': `${zoomProps.translateX}px`}}>
            <SalesDocView />
          </Row>
        }
        {salesDoc && isPresentation && <PresentationView />}
        {isPresentation && salesDoc?.template.cartButtonEnabled &&
          <ShoppingCartDrawer refetchSalesDoc={refetchSalesDoc} />
        }
        {!salesDoc && apiError &&
          <ReportErrorBoundary error={apiError}/>
        }
    </Page>
  );
}
