import {useCallback, useEffect, useMemo} from 'react';
import {cloneDeep, set} from 'lodash';
import {useGetPaginatedJobs, useUpdateJob} from '../../../hooks/api';
import {useDeepObjectMemo} from '../../../hooks';

export function useJobsState({page, pageSize, filters, search, sort, params, fixedFilter: _fixedFilter}) {
  const apiFilters = useMemo(() => mapJobBoardFiltersToApi(filters), [filters]);
  const fixedFilter = useDeepObjectMemo(_fixedFilter);
  const {
    data: {docs: jobs = [], total} = {},
    isLoading,
    refetch,
    updateCache,
    setParams: setFetchParams,
  } = useGetPaginatedJobs({page, pageSize, search, sort, filters: apiFilters, fixedFilter});
  const {mutateAsync: updateJobApi, isLoading: isSaving} = useUpdateJob();

  // useGetPaginatedJobs does not rerun the query when the params change. We have to tell it explicitly.
  useEffect(() => {
    setFetchParams((prev) => ({...prev, page, pageSize, search, sort, filters: mapJobBoardFiltersToApi(filters, params), fixedFilter}));
  }, [filters, fixedFilter, page, pageSize, params, search, setFetchParams, sort]);

  // Update the value in the field
  const updateField = useCallback(async ({id, field, value, props}) => {
    const index = jobs?.findIndex((row) => row._id === id);
    if (index >= 0) {
      const docs = [...jobs];
      const doc = cloneDeep(docs[index]);
      props = props ?? {[field]: value};
      Object.entries(props).forEach(([propField, propValue]) => set(doc, propField, propValue));
      docs[index] = doc;
      updateCache({docs, total});
    }
    if (field && field.startsWith('digest.')) {
      // Don't do anything if the digest is being updated
      return null;
    }
    const res = await updateJobApi({data: props, id});
    await refetch();
    return res;
  }, [jobs, updateJobApi, refetch, updateCache, total]);

  return useMemo(() => ({
    jobs: jobs?.map((row) => ({id: row._id, ...row})) ?? [],
    updateField,
    isLoading,
    isSaving,
    refetch,
    total: total ?? 0,
  }), [isLoading, isSaving, jobs, refetch, total, updateField]);
}

function mapJobBoardFiltersToApi(filters, params) {
  const linkOperator = filters?.[0] ?? 'and';
  const items = [];
  for (let i = 1; i < filters?.length; i += 3) {
    items.push({f: filters[i], o: filters[i + 1], v: filters[i + 2]});
  }
  if (params?.archived) {
    items.push({f: 'archived', o: 'archived', v: true});
  }
  if (params?.voided) {
    items.push({f: 'voided', o: 'voided', v: true});
  }
  return {linkOperator, items};
}
