import {SalesDoc} from './SalesDoc';

export class CartItemMap {
  salesStore;
  mapCartToStore;
  mapStoreToCart;

  constructor(salesStore) {
    this.salesStore = salesStore;
    this.mapCartToStore = {};
    this.mapStoreToCart = {};
  }

  getCartId(id) {
    return this.mapStoreToCart[id];
  }

  getStoreId(id) {
    return this.mapCartToStore[id];
  }

  addMappings(that) {
    this.mapCartToStore = {...this.mapCartToStore, ...that.mapCartToStore};
  }

  initGroupMap(groupId, fromCart, fromMap) {
    if (fromCart && fromMap) {
      const storeGroupId = fromMap.mapCartToStore[groupId];
      this.mapCartToStore[groupId] = storeGroupId;
      this.mapStoreToCart[storeGroupId] = groupId;

      fromCart.getItemsInGroup(groupId).forEach((item) => {
        const storeVariantId = fromMap.mapCartToStore[item.variantId];
        this.mapCartToStore[item.variantId] = storeVariantId;
        this.mapStoreToCart[storeVariantId] = item.variantId;
      });

      this.salesStore.getPresentationRowIdsInGroup(storeGroupId).forEach((rowVariantId) => {
        if (!this.mapStoreToCart[rowVariantId]) {
          const newVariantId = SalesDoc.newVariantId();
          this.mapCartToStore[newVariantId] = rowVariantId;
          this.mapStoreToCart[rowVariantId] = newVariantId;
        }
      });
    } else {
      const newGroupId = SalesDoc.newGroupId();
      this.mapCartToStore[newGroupId] = groupId;
      this.mapStoreToCart[groupId] = newGroupId;

      this.salesStore.getPresentationRowIdsInGroup(groupId).forEach((rowVariantId) => {
        const newVariantId = SalesDoc.newVariantId();
        this.mapCartToStore[newVariantId] = rowVariantId;
        this.mapStoreToCart[rowVariantId] = newVariantId;
      });
    }
  }

  addItemsToCart(cart, items) {
    if (!Array.isArray(items)) {
      items = [items];
    }
    const decorationIds = {};
    return cart.addItems(items.map((item) => {
      let itemId = SalesDoc.newItemId();
      if (item.isDecoration()) {
        decorationIds[item.itemId] = itemId;
      } else if (item.isDecorationSetupCost() && decorationIds[item.getDecorationItemId()]) {
        itemId = SalesDoc.makeDecorationSetupCostId(decorationIds[item.getDecorationItemId()]);
      }
      return item.copyWith({
        itemId,
        groupId: this.mapStoreToCart[item.groupId],
        variantId: this.mapStoreToCart[item.variantId],
        quantity: item.isAdditionalCost() ? 1 : item.quantity,
        unitPriceOverride: true,
        unitPriceLocked: true
      });
    })).recalculate().notify();
  }
}
