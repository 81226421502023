import {InputLabel, MenuItem, Select} from '@material-ui/core';
import React, {useContext} from 'react';
import {useDispatch} from 'react-redux';
import {SET_REPORT_DATA} from '../../actions/reporting';
import {ReportContext} from './Reporting';
import {permissionCheck, SubscriptionTypes} from '../../componentsHoops/AccessControl';

export const ReportSelect = () => {
    const dispatch = useDispatch();
    const {reportType, setReportType} = useContext(ReportContext);

    const handleReportChange = (e) => {
        setReportType(e.target.value);
        dispatch({type: SET_REPORT_DATA, payload: {}});
    };

    return (
        <>
            <InputLabel htmlFor='age-native-simple'>Report Type</InputLabel>
            <Select
                {...{
                    fullWidth: true,
                    margin: 'dense',
                    size: 'small'
                }}
                value={reportType}
                onChange={handleReportChange}
            >
                <MenuItem value='sales'>Sales</MenuItem>
                <MenuItem value='grossMargin'>Gross Margin</MenuItem>
                <MenuItem value='vendor/sales'>Sales Per Vendor</MenuItem>
                {permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) &&
                    <MenuItem value='fullypromoted/royalty'>Royalty</MenuItem>
                }
                {permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted], allowedSubscriptionManager: true}) &&
                    <MenuItem value='fullypromoted/summary'>Royalty Summary</MenuItem>
                }
            </Select>
        </>
    );
};

export default ReportSelect;
