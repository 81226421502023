
let applied = false;
export function applyPolyFills() {
  if (applied) {
    return;
  }
  applied = true;

  if (!Array.prototype.toSorted) {
    Array.prototype.toSorted = function (sortFn) {
      return [...this].sort(sortFn);
    };
  }
}

applyPolyFills();
