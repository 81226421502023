// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import {makeStyles, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
import {getUpcomingInvoice} from '../../actions/billing';
import {useSelector} from 'react-redux';
import {HoopsButton} from '../HoopsButton';
import {PermissionBasedContent, SubscriptionTypes} from '../../componentsHoops/AccessControl';
import {StandardDialog} from '../modal/StandardDialog';
import {toFloat} from '../shared/numberUtils';
import moment from 'moment';
import {gql, useMutation} from '@apollo/client';
// import {GQL_UPDATE_COMPANY} from '../../queries/companies';
import {parseCompany} from '../../servicesOld/CompanyService';
import {useHistory} from 'react-router';
import {useSyncCompanySubscription} from '../../hooks/api';
import {useMountEffect} from '../../hooks';

const useStyles = makeStyles((theme) => ({
  paper: {padding: theme.spacing(2)},
  price: {fontWeight: 500},
  userCount: {marginTop: theme.spacing(3)}
}));

const GQL_UPDATE_COMPANY = gql`
    mutation updateCompany($_id: String!, $data: CompanyInput!){
      updateCompany(_id: $_id, data: $data){
        subscriptionType
        }
    }
`;

export const UpcomingInvoice = () => {
  const classes = useStyles();
  const history = useHistory();

  const upcomingInvoiceSelector = (state) => state.billingReducer.upcomingInvoice;
  const upcomingInvoice = useSelector(upcomingInvoiceSelector);
  const {ACTIVE: ACTIVE_USERS, INVITED: INVITED_USERS} = upcomingInvoice?.company.numberOfUsers;

  const companySelector = (state) => state.companyReducer.company;
  const company = useSelector(companySelector);

  const {['sync-stripe']: syncStripeApi} = useSyncCompanySubscription();

  const [open, setOpen] = useState(false);

  const cancel = () => {
    setOpen(false);
  };

  useMountEffect(() => {
    syncStripeApi();
  });

  const [updateCompanyPlan, {loading}] = useMutation(GQL_UPDATE_COMPANY, {
    onCompleted: (result) => {
      if (result) {
        history.go(0);
      }
    },
  });

  const setPlan = async (newPlan) => {
    await updateCompanyPlan({variables: {_id: company._id, data: parseCompany({...company, subscriptionType: newPlan})}});
    setOpen(false);
  };

  const openDialog = () => {
    setOpen(true);
  };

  getUpcomingInvoice();

  const getUpgradeCost = () => {
    const {ACTIVE: ACTIVE_USERS} = upcomingInvoice.company.numberOfUsers;
    const additionalUserNumber = ACTIVE_USERS - 2;
    return additionalUserNumber < 0 ? 99 : 99 + (additionalUserNumber * 29);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant={'h4'} gutterBottom>Your Plan</Typography>
            <Grid container spacing={2}>

              {/* Upgrade Subscription Modal*/}
              <PermissionBasedContent allowedSubscriptions={[SubscriptionTypes.proofs]}>
                <Grid item xs={12}>
                  <Typography variant={'h5'}>{'You\'re on the Proofs plan'}</Typography>
                  <HoopsButton purpose={'add'} onClick={() => openDialog()}>Updgrade to the complete plan</HoopsButton>
                </Grid>
                <StandardDialog
                  open={open}
                  handleClose={cancel}
                  title={'Upgrade Plan'}
                  actions={
                    <>
                      <HoopsButton purpose='cancel' onClick={cancel}>Cancel</HoopsButton>
                      <HoopsButton purpose='save' loading={loading} onClick={() => setPlan(SubscriptionTypes.full)}>Confirm Upgrade</HoopsButton>
                    </>
                  }
                >
                  <Typography variant={'h5'} gutterBottom>You currently have {ACTIVE_USERS} users.</Typography>
                  {/* fix */}
                  <Typography variant={'h5'} gutterBottom>Your new bill will be <strong>${getUpgradeCost()}</strong> USD/month</Typography>
                  <Typography color={'primary'} gutterBottom>
                    <RouterLink component={Link} to={'/settings/users'} >Manage Users</RouterLink>
                  </Typography>
                </StandardDialog>
              </PermissionBasedContent>

              {/* Downgrade Subscription Modal */}
              <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
                <Grid item xs={12}>
                  <Typography variant={'h5'}>{'You\'re on the Complete plan'}</Typography>
                  <HoopsButton purpose={'primary'} onClick={() => openDialog()}>Downgrade to the Proofs plan</HoopsButton>
                </Grid>
                <StandardDialog
                  open={open}
                  handleClose={cancel}
                  title={'Downgrade Plan'}
                  actions={
                    <>
                      <HoopsButton purpose='cancel' onClick={cancel}>Cancel</HoopsButton>
                      <HoopsButton purpose='save' loading={loading} onClick={() => setPlan(SubscriptionTypes.proofs)}>Confirm Downgrade</HoopsButton>
                    </>
                  }
                >
                  <Typography variant={'h5'} gutterBottom>Your new bill will be <strong>$79</strong> USD/month</Typography>
                  {/* fix */}
                  <Typography variant={'body1'} gutterBottom>
                    You{'\''}ll be losing great features including:
                    <ul>
                      <li>Catalog</li>
                      <li>Quotes</li>
                      <li>Purchase Orders</li>
                      <li>Scheduling</li>
                      <li>Automations</li>
                      <li>Unlimited customizations to your JobBoard</li>
                      <li>& more</li>
                    </ul>
                  </Typography>
                </StandardDialog>
              </PermissionBasedContent>

              <Grid item xs={6}>
                <Typography variant={'h4'} className={`${classes.price} ${classes.firstRow}`}>
                  ${toFloat(upcomingInvoice.amount_due / 100)}
                </Typography>

                <Typography variant={'body1'}>
                  /month
                </Typography>

                <Typography color={'textSecondary'} className={classes.userCount} variant={'body1'}>
                  {ACTIVE_USERS} Active User
                </Typography>
                <Typography color={'textSecondary'} variant={'body2'}>
                  {INVITED_USERS} Pending Invite
                </Typography>

                <Typography color={'primary'} >
                  <RouterLink component={Link} to={'/settings/users'}>Manage Users</RouterLink>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.firstRow} color={'textSecondary'} variant={'body1'}>Next Payment</Typography>
                <Typography variant={'body1'}>{moment(upcomingInvoice.created, 'X').format('D MMMM YYYY')}</Typography>
              </Grid>
            </Grid>

          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
