import {TextField} from '@mui/material';
import React, {ChangeEvent} from 'react';
import {Status} from './StatusPicker';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {ColorPicker} from './ColorPicker';
import {HoopsIconButton} from '../../../../componentsOld/HoopsIconButton';
import {HoopsSingleSortableList} from './HoopsSingleSortableList';
import {getBrightness} from '../../../../utils';

interface StatusEditingProps {
  move: (dragIndex: number, hoverIndex: number) => void,
  index: number,
  statusState: Status,
  onChange: (statusState: Status) => void,
  onDelete: () => void
}

export function StatusListEditor({statusState, onChange, onDelete, index, move}: StatusEditingProps) {
  const onChangeColor = (color: string) => {
    onChange({...statusState, color});
  };
  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({...statusState, statusLabel: e.currentTarget.value, originalLabel: statusState.originalLabel || statusState.statusLabel});
  };
  const brightness = statusState.color ? getBrightness(statusState.color) : 255;
  const isAlmostWhite = brightness > getBrightness.ALMOST_WHITE;
  return <HoopsSingleSortableList move={move} index={index} id={statusState.statusLabel || ''}>
    {({handlerRef}) => <div className={'status-picker-list-editor'}>
      <div ref={handlerRef} style={{display: 'flex'}} className={'drag-handle'}>
        <DragIndicatorIcon/>
      </div>
      <div style={{padding: '0 4px'}}>
        <ColorPicker
          button={<div
            className={'color-button'}
            style={{background: statusState.color, boxShadow: isAlmostWhite ? '0 0 0 1px rgba(0, 0, 0, .1)' : 'inherit'}}/>}
          color={statusState.color}
          onChange={onChangeColor}/>
      </div>
      <div style={{padding: '0 4px'}}>
        <TextField
          error={!statusState.statusLabel}
          value={statusState.statusLabel}
          size={'small'}
          onChange={onChangeText}
        />
      </div>
      <div className={'delete-button'}>
        <HoopsIconButton purpose='delete' onClick={() => onDelete()} style={{padding: 4}} />
      </div>
    </div>}
  </HoopsSingleSortableList>;
}
