import React, {useCallback, useLayoutEffect, useMemo, useRef, useState} from 'react';
import AddIcon from '@material-ui/icons/AddCircle';
import CheckedIcon from '@mui/icons-material/CheckCircle';
import UncheckedIcon from '@mui/icons-material/Brightness1';
import DeleteIcon from '@mui/icons-material/Cancel';
import {HoopsPropTypes} from '../utils';
import {Button, TextInput, ToolTip} from '../Basic';
import {hoopsTheme, registerGlobalStyle} from '../../theme';
import classNames from 'classnames';
import {Chip} from './Chip';
import {byLocaleCaseInsensitive} from '../../utils';

registerGlobalStyle('.chip-list', (theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  '&.single-row': {
    maxHeight: theme.spacing(3),
    overflow: 'hidden',
    '&>.chip:first-child': {maxWidth: `calc(100% - ${theme.spacing(4)})`},
  },
  '&.selectable .chip': {
    cursor: 'pointer',
    '&:not(.selected)': {backgroundColor: theme.colors.palette.greyLighter},
  },
  '.chip': {
    svg: {cursor: 'pointer'},
    '&.editable p': {cursor: 'text'},
  },
  '.text-input': {
    height: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  '.button': {
    '*': {alignSelf: 'center'},
    '.button-text': {lineHeight: 'normal'},
  },
  '.extra-chips': {
    maxWidth: theme.spacing(3),
    width: theme.spacing(3),
    justifyContent: 'center',
    paddingInline: 0,
    p: {
      fontSize: theme.fontSize(11),
      lineHeight: 1,
    },
  },
}));

export function ChipList({
  className,
  addButtonText,
  allowAddRemove,
  allowRemoveAny,
  allowRenameAny,
  chips,
  color,
  resizeContainer,
  selected,
  singleRow,
  sortFunction,
  unsorted,
  onChange,
  onChangeSelected,
  onAddChip,
  onClick,
  onMouseDown,
  onRenameChip,
  onRemoveChip,
}) {
  const [editingChip, setEditingChip] = useState('');
  const [addingChip, setAddingChip] = useState(false);
  const [editingValue, setEditingValue] = useState('');
  const listRef = useRef(null);
  const [maxVisible, setMaxVisible] = useState(-1);
  const [tooltipFirstChip, setTooltipFirstChip] = useState(false);
  const widthRef = useRef(0);

  allowAddRemove = allowAddRemove ?? !!addButtonText;

  const chipList = useMemo(() => {
    const cl = chips ? [...chips] : [];
    selected?.forEach((chip) => {
      if (!cl.includes(chip)) {
        cl.push(chip);
      }
    });
    if (!unsorted) {
      return sortFunction ? sortFunction(cl) : cl.sort(byLocaleCaseInsensitive);
    }
    return cl;
  }, [chips, selected, sortFunction, unsorted]);

  // This effect will run after every render to check whether any chips are overflowing. Once
  // we determine that some chips are overflowing we won't check again unless the chip list
  // changes.
  useLayoutEffect(() => {
    if (singleRow && listRef.current && chipList.length > 0) {
      if (resizeContainer?.offsetWidth !== widthRef.current) {
        widthRef.current = resizeContainer?.offsetWidth;
        setMaxVisible(-1);
      } else if (maxVisible < 0) {
        const container = listRef.current;
        const children = [...container.children];
        const firstOverflow = children.findIndex((el) => el.offsetTop > container.offsetTop);
        let newMaxVisible;
        if (firstOverflow < 0) {
          newMaxVisible = chipList.length;
        } else if (firstOverflow <= 1) {
          newMaxVisible = 1;
        } else if (children[firstOverflow - 1].getBoundingClientRect().right + hoopsTheme.spacingPx(4) > container.getBoundingClientRect().right) {
          newMaxVisible = firstOverflow - 1;
        } else {
          newMaxVisible = firstOverflow;
        }

        let newTooltipFirstChip = newMaxVisible === 1 && children[0].firstChild.scrollWidth > children[0].firstChild.offsetWidth;

        if (newMaxVisible !== maxVisible || newTooltipFirstChip !== tooltipFirstChip) {
          setMaxVisible(newMaxVisible);
          setTooltipFirstChip(newTooltipFirstChip);
        }
      }
    }
  }, [chipList, maxVisible, singleRow, resizeContainer?.offsetWidth, tooltipFirstChip]);

  const handleClick = useCallback((e) => {
    const chip = e.target.closest('.chip');
    const value = chip?.getAttribute('name');
    if (e.target.closest('svg:last-child')) {
      const pos = chipList.indexOf(value);
      if (pos >= 0) {
        onChange?.(chipList.toSpliced(pos, 1));
        if (onChangeSelected) {
          const selectedPos = selected?.indexOf(value);
          if (selectedPos >= 0) {
            onChangeSelected(selected.toSpliced(pos, 1));
          }
        }
      }
      onRemoveChip?.(value);
    } else if (chip?.classList.contains('editable') && e.target.closest('p') && value) {
      setEditingChip(value);
      setEditingValue(value);
    } else if (onChangeSelected) {
      const pos = selected?.indexOf(value);
      if (pos >= 0) {
        onChangeSelected(selected.toSpliced(pos, 1));
      } else {
        onChangeSelected([...selected ?? [], value]);
      }
    }
    onClick?.(e);
  }, [chipList, onChange, onChangeSelected, onClick, onRemoveChip, selected]);

  const handleFinishEditing = useCallback((applyChanges, continueEditing) => {
    if (applyChanges && editingValue) {
      if (chipList.includes(editingValue)) {
        return; // If the value is already in the list, just ignore and keep it focused
      }
      if (addingChip) {
        if (onChange) {
          const newChips = [...chips ?? [], editingValue];
          onChange(sortFunction ? sortFunction(newChips) : newChips.sort(byLocaleCaseInsensitive));
        }
        if (onChangeSelected) {
          const newSelected = [...selected ?? [], editingValue];
          onChangeSelected(sortFunction ? sortFunction(newSelected) : newSelected.sort(byLocaleCaseInsensitive));
        }
        onAddChip?.(editingValue);
      } else {
        if (onChange) {
          const newChips = chips?.map((chip) => chip === editingChip ? editingValue : chip) ?? [];
          onChange(sortFunction ? sortFunction(newChips) : newChips.sort(byLocaleCaseInsensitive));
        }
        if (onChangeSelected) {
          const newSelected = selected?.map((chip) => chip === editingChip ? editingValue : chip) ?? [];
          onChangeSelected(sortFunction ? sortFunction(newSelected) : newSelected.sort(byLocaleCaseInsensitive));
        }
        onRenameChip?.(editingChip, editingValue);
      }
    }
    setEditingChip('');
    setAddingChip(!!(continueEditing && editingValue));
    setEditingValue('');
  }, [addingChip, chipList, chips, editingChip, editingValue, onAddChip, onChange, onChangeSelected, onRenameChip, selected, sortFunction]);

  const handleAddChip = useCallback(() => {
    handleFinishEditing(true);
    setEditingChip('');
    setAddingChip(true);
    setEditingValue('');
  }, [handleFinishEditing]);

  const handleEditBlur = useCallback(() => {
    handleFinishEditing(true);
  }, [handleFinishEditing]);

  const handleEditChange = useCallback((e) => {
    setEditingValue(e.target.value);
  }, []);

  const handleEditKeyDown = useCallback((e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.stopPropagation();
      e.preventDefault();
      handleFinishEditing(true, true);
    } else if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
      handleFinishEditing(false);
    }
  }, [handleFinishEditing]);

  return (
    <div
      className={classNames([className, 'chip-list', allowAddRemove && 'editable', onChangeSelected && 'selectable', singleRow && 'single-row'])}
      ref={listRef}
    >
      {chipList.filter((_, index) => maxVisible < 0 || index < maxVisible).map((chip, index) => {
        const isSelected = onChangeSelected && selected && selected.includes(chip);
        const isFixedChip = chips?.includes(chip);
        if (editingChip === chip) {
          return (
            <TextInput value={editingValue} onKeyDown={handleEditKeyDown} onBlur={handleEditBlur} onChange={handleEditChange} autoFocus selectOnFocus autoSize/>
          );
        }
        return (
          <ToolTip key={chip} tip={index === 0 && tooltipFirstChip && chip} noContainer>
            <Chip
              className={[isSelected && 'selected', allowAddRemove && (!isFixedChip || allowRenameAny) && 'editable']}
              color={color}
              name={chip}
              prefix={onChangeSelected && isFixedChip && (isSelected ? CheckedIcon : UncheckedIcon)}
              text={chip}
              suffix={(allowRemoveAny || (!isFixedChip && (allowAddRemove || onChangeSelected))) && DeleteIcon}
              onClick={handleClick}
              onMouseDown={onMouseDown}
            />
          </ToolTip>
        );}
      )}
      {singleRow && maxVisible >= 0 && maxVisible < chipList.length &&
        <ToolTip tip={
          <div>
            {chipList.filter((_, index) => index >= maxVisible).map((chip, index) => (
              <span key={index}>{chip}<br /></span>
            ))}
          </div>
        }>
          <Chip className={'extra-chips'} text={`+${chipList.length - maxVisible}`}/>
        </ToolTip>
      }
      {addingChip &&
        <TextInput value={editingValue} onKeyDown={handleEditKeyDown} onBlur={handleEditBlur} onChange={handleEditChange} autoFocus selectOnFocus autoSize/>
      }
      {allowAddRemove &&
        <Button actionPrimary prefix={AddIcon} text={addButtonText ?? 'Add'} onClick={handleAddChip}/>
      }
    </div>
  );
}

ChipList.propTypes = {
  className: HoopsPropTypes.className,
  addButtonText: HoopsPropTypes.string,
  allowAddRemove: HoopsPropTypes.bool,
  allowRemoveAny: HoopsPropTypes.bool, // usually chips in chips can't be removed
  allowRenameAny: HoopsPropTypes.bool, // usually chips in chips can't be renamed
  chips: HoopsPropTypes.arrayOfString,
  color: HoopsPropTypes.string,
  resizeContainer: HoopsPropTypes.any,
  selected: HoopsPropTypes.arrayOfString,
  singleRow: HoopsPropTypes.bool,
  sortFunction: HoopsPropTypes.func,
  unsorted: HoopsPropTypes.bool,
  onAddChip: HoopsPropTypes.func,
  onChange: HoopsPropTypes.func,
  onChangeSelected: HoopsPropTypes.func,
  onClick: HoopsPropTypes.func,
  onMouseDown: HoopsPropTypes.func,
  onRenameChip: HoopsPropTypes.func,
  onRemoveChip: HoopsPropTypes.func,
};
