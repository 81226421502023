import {Description} from '@mui/icons-material';
import {Table, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import * as React from 'react';
import CardContainer from '../../../../componentsOld/common/CardContainer';
import theme from '../../../../ui/theme';
import {HoopsButton} from '../../../../componentsLib';
import {StandardDialog} from '../../../../componentsOld/modal/StandardDialog';
import {HoopsTextField} from '../../../../componentsOld/HoopsTextField';
import {useGetJob, useUpdateJob} from '../../../../hooks/api';
import {decimalPlaces, toFloat} from '../../../../componentsOld/shared/numberUtils';
import {useSelector} from 'react-redux';
import HoopsQueryTableCellDateTime from '../../../../componentsOld/HoopsQuery/HoopsQueryTableCellDateTime';
import DeleteIcon from '@material-ui/icons/Delete';
import {HoopsIconButton} from '../../../../componentsOld/HoopsIconButton';
import {useLazyQuery} from '@apollo/client';
import {GQL_GET_QUOTE} from '../../../../queries/quotes';

const creditStyles = {
  deletedRowStyle: {
    color: theme.colors.grey.subtle,
    height: 48,
    p: '0 30px',
    '& .MuiTypography-colorTextSecondary': {color: theme.colors.grey.subtle,}
  },
};

export const JobCredits = ({
  job = {},
  loading = false,
}) => {
  const userSelector = (state) => state.authReducer.userData;
  const user = useSelector(userSelector);
  const currencySymbolSelector = (state) => state?.authReducer.userData.company.currencySymbol;
  const currencySymbol = useSelector(currencySymbolSelector);
  const {data, isLoading, refetch} = useGetJob(job?._id);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [newCredit, setNewCredit] = React.useState({});
  const [updating, setUpdating] = React.useState(false);
  const {mutateAsync: update} = useUpdateJob();
  const [getQuote, {data: quoteData, loading: quoteLoading}] = useLazyQuery(GQL_GET_QUOTE);

  const credits = React.useMemo(() => (data?.credits) || job?.credits || [], [data?.credits, job?.credits]);

  const openDialog = () => {
    getQuote({variables: {_id: job?.quoteId}});
    setNewCredit({});
    setDialogOpen(true);
  };

  const handleSave = async () => {
    setNewCredit((prev) => {
      const cloneCredit = {...prev};
      const fullCreditData = {
        ...cloneCredit,
        number: credits.length + 1,
        createdById: user._id,
        createdAt: Date.now(),
      };
      onSubmit([...credits, fullCreditData]);
      return (fullCreditData);
    });
    setDialogOpen(false);
  };

  const handleDelete = async (_id) => {
    const cloneArray = [...credits];
    const deletedCreditIndex = cloneArray.findIndex((el) => el._id === _id);
    cloneArray[deletedCreditIndex].deletedAt = Date.now();
    onSubmit(cloneArray);
  };

  const onSubmit = React.useCallback(async (updatedCredits) => {
    setUpdating(true);
    await update({id: job._id, data: {credits: updatedCredits}});
    await refetch();
    setUpdating(false);
  }, [job?._id, update, refetch]);

  return (
    <>
      <CardContainer
        data-intercom-target={'job-details-product-panel'}
        title='Credits'
        icon={<Description />}
        iconBackgroundColor={theme.palette.primary.main}
        loading={loading}
        buttonComponent={
          [
            <HoopsButton key={3} purpose='ok' onClick={openDialog}>Add Credit</HoopsButton>
          ]
        }
      >
        <Table size={'small'} >
          <TableHead>
            <TableRow>
              <TableCell sx={{width: '10%', whiteSpace: 'nowrap'}} align='center'>Credit Number</TableCell>
              <TableCell sx={{whiteSpace: 'nowrap', width: '20%'}}>Description</TableCell>
              <TableCell sx={{whiteSpace: 'nowrap'}} align='right'>Credit Amount</TableCell>
              <TableCell sx={{whiteSpace: 'nowrap'}} >Created By</TableCell>
              <TableCell sx={{whiteSpace: 'nowrap'}} >Date Created</TableCell>
              <TableCell sx={{whiteSpace: 'nowrap'}} >Deleted Date</TableCell>
              <TableCell sx={{whiteSpace: 'nowrap'}} align='center'>Action</TableCell>
            </TableRow>
          </TableHead>
          {credits.map((credit, index) => (
            <TableRow key={index} sx={credit.deletedAt && creditStyles.deletedRowStyle}>
              <TableCell align='center'>{credit.number}</TableCell>
              <TableCell>{credit.description}</TableCell>
              <TableCell align='right'>{`${currencySymbol} ${decimalPlaces(credit.creditAmount, 2)}`}</TableCell>
              <TableCell>{`${credit.createdBy?.firstName} ${credit.createdBy?.lastName}`}</TableCell>
              <TableCell>{<HoopsQueryTableCellDateTime value={credit.createdAt} sx={credit.deletedAt && creditStyles.deletedTextStyle} />}</TableCell>
              <TableCell>{<HoopsQueryTableCellDateTime value={credit.deletedAt} sx={credit.deletedAt && creditStyles.deletedTextStyle} />}</TableCell>
              <TableCell align='center'>
                <HoopsIconButton disabled={credit.deletedAt} onClick={() => handleDelete(credit._id)}><DeleteIcon /></HoopsIconButton>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </CardContainer>

      {/* Add Credit Dialog */}
      <StandardDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title={'Add Credit'}
        actions={
          <>
            <HoopsButton purpose='primary' onClick={() => setDialogOpen(false)}>Cancel</HoopsButton>
            <HoopsButton disabled={!newCredit} loading={isLoading || updating} purpose='save' onClick={handleSave}>Save</HoopsButton>
          </>
        }
      >
        {job?.invoice?._id && quoteData && !quoteLoading && <Typography variant='h5' sx={{pt: 2, pb: 2}}>{`Invoice Amount: ${currencySymbol} ${decimalPlaces(quoteData?.quote?.subTotal, 2)} ex GST`}</Typography> }
        <HoopsTextField label='Credit Description' style={{paddingBottom: 16}}
          onChange={(e) => setNewCredit((prev) => ({...prev, description: e.target.value}))}
        />
        <HoopsTextField label='Credit Amount' type='number' style={{paddingBottom: 16}}
          onChange={(e) => setNewCredit((prev) => ({...prev, creditAmount: toFloat(e.target.value)}))}
        />
      </StandardDialog>
    </>
  );
};
