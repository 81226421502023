import React, {useEffect} from 'react';
import {Row} from '../../componentsLib/Layout';
import {BodyText} from '../../componentsLib/Text';
import {registerGlobalStyle} from '../../theme';
import {GUEST_BASENAME} from '../../containers/routes/GuestRoutes';
import {useErrorReport} from '../../hooks/api';
import {useDeepObjectMemo} from '../../hooks';
import {mapStackTrace} from 'sourcemapped-stacktrace';

registerGlobalStyle('.error-boundary', (theme) => ({
  width: '100%',
  height: '75%',
  '.text-body': {
    color: theme.colors.text.main,
    background: theme.colors.background.warning,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
  }
}));

export function ReportErrorBoundary({error, message}) {
  const guest = window.location.pathname.startsWith(GUEST_BASENAME);
  const {create: reportErrorApi} = useErrorReport(guest ? 'guest' : 'tenant');

  let report;
  if (error.request || error.response) {
    message = message || <><b>Sorry! Unable to load the requested document.</b> <br />Please contact us and we can advise how to access the document.</>;
      report = {
        url: error.config?.url ?? error.request.responseURL,
        message: error.message,
        status: error.response?.status,
      };
  } else {
    message = message || 'Something has gone wrong loading this page.';
      report = {
        url: window.location.href,
        message: error.message,
        stack: error.stack,
      };
  }

  report = useDeepObjectMemo(report);
  useEffect(() => {
    try {
      mapStackTrace(report.stack || '', (stack) => {
        reportErrorApi({error: {...report, stack: stack.join('\n')}}).then().catch();
      });
    } catch {
      reportErrorApi({error: {...report, stack: report.stack}}).then().catch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return (
    <Row className={['error-boundary', guest ? 'guest-error-boundary' : 'tenant-error-boundary']} alignCenter justifyCenter>
      {guest &&
        <BodyText text={message}/>
      }
      {!guest &&
        <BodyText text={message}/>
      }
    </Row>
  );
}
