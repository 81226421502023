import React, {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux';
import {Form, getFormValues, initialize, reduxForm, reset, change, Field} from 'redux-form';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {AddressOneLine} from '../shared/formHelpers/AddressOneLine';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PaymentIcon from '@material-ui/icons/Payment';
import {closeInvoiceModal} from '../../actions/modals';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InvoiceTable from './InvoiceTable';
import DialogActions from '@material-ui/core/DialogActions';
import HelpArticle from '../helpArticle';
import {InvoiceService} from '../../servicesOld/InvoiceService';
import {snackError, snackSuccess} from '../../actions/action-types';
import {Divider, MenuItem, Switch} from '@material-ui/core';
import {getLocalStorageItem, putLocalStorageItem} from '../../utils';
import {HoopsSelectField} from '../HoopsSelectField';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';
import {stripHTML} from '../../utils/stripHtml';
import {Button} from '../../componentsLib/Basic';

export const INVOICE_FORM = 'invoiceForm';

const useStyles = makeStyles((theme) => ({
  primaryColor: {backgroundColor: theme.palette.primary.main},
  greyText: {
    color: theme.colors.grey.main,
    '& b': {
      color: theme.colors.grey.dark,
      fontWeight: 400,
    },
  },
  header: {
    position: 'relative',
    backgroundColor: theme.colors.grey.ultraLight,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  flex: {flex: 1},
  button: {paddingBottom: theme.spacing(2),},
  breakWordOverflow: {wordBreak: 'break-all'},
  itemDetailSettings: {
    height: 38,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  errorText: {color: theme.colors.red,},
}));

const INVOICE_SHOW_PRODUCTS_DETAILS_LOCALSTORAGE_KEY = 'invoice_showProductsDetail';
const INVOICE_SHOW_DECORATIONS_DETAILS_LOCALSTORAGE_KEY = 'invoice_showDecorationssDetail';
const INVOICE_SHOW_ADDITIONAL_ITEMS_DETAILS_LOCALSTORAGE_KEY = 'invoice_showAdditionalItemsDetail';

let InvoiceModal = ({handleSubmit}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const invoiceSelector = (state) => state.modalReducer.draftInvoiceModal.context;
  const invoice = useSelector(invoiceSelector);
  const company = useSelector((state) => state.companyReducer.company);
  const [loading, setLoading] = useState(false);

  // Check for locally stored invoice show detail settings
  const [showProducts, setShowProducts] = useState(() => {
    const storedValue = getLocalStorageItem(INVOICE_SHOW_PRODUCTS_DETAILS_LOCALSTORAGE_KEY);
    if (typeof storedValue === 'boolean') {
      return storedValue;
    }
    return false;
  });
  const [showDecorations, setShowDecorations] = useState(() => {
    const storedValue = getLocalStorageItem(INVOICE_SHOW_DECORATIONS_DETAILS_LOCALSTORAGE_KEY);
    if (typeof storedValue === 'boolean') {
      return storedValue;
    }
    return false;
  });
  const [showAdditionalCosts, setShowAdditionalCosts] = useState(() => {
    const storedValue = getLocalStorageItem(INVOICE_SHOW_ADDITIONAL_ITEMS_DETAILS_LOCALSTORAGE_KEY);
    if (typeof storedValue === 'boolean') {
      return storedValue;
    }
    return false;
  });

  useEffect(() => {
    dispatch(initialize(INVOICE_FORM, {...invoice, contactId: invoice?.contactId ?? 'CUSTOMER'}));
  }, [dispatch, invoice]);

  const modalSelector = (state) => state.modalReducer.draftInvoiceModal;
  const {isOpen, isDraft} = useSelector(modalSelector);

  const valuesSelector = (state) => getFormValues(INVOICE_FORM)(state);
  const values = useSelector(valuesSelector);

  const addresses = {
    billTo: invoice?.billTo ?? invoice?.customer?.addresses?.find((address) => address.label === 'BILLING'),
    shipTo: invoice?.shipTo ?? invoice?.customer?.addresses?.find((address) => address.label === 'SHIPPING'),
  };
  if (company?.accountingPlatform?.platform === 'QUICKBOOKS') {
    // Only QuickBooks supports addresses on invoices, so only show the overrides for QBO
    addresses.billTo = invoice?.billTo ?? addresses.billTo;
    addresses.shipTo = invoice?.shipTo ?? addresses.shipTo;
  }

  const saveInvoice = async () => {
    setLoading(true);
    // For QuickBooks, we set the account to be the same for every row as the first row.
    const updatedInvoice = {
      ...values,
      contactId: values.contactId !== 'CUSTOMER' ? values.contactId : null,
      ...(company?.accountingPlatform?.platform !== 'QUICKBOOKS' || !invoice.items?.length ? {} : {
        items: values.items.map((item) => ({
          ...item,
          accountingRevenueAccount: values.items[0].accountingRevenueAccount,
        }))
      }),
    };
    const invoiceService = new InvoiceService(updatedInvoice);
    invoiceService.save(isDraft)
      .then(() => {
        handleClose();
        dispatch(snackSuccess('Invoice Saved'));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        dispatch(snackError('Error: Please check the form and try again.'));
        setLoading(false);
      });
  };

  const constructDescription = useCallback(() => {
    invoice?.items?.forEach((item, idx) => {
      if (!item.salesDocItemId) {
        const quantity = item.priceElement?.variations[0]?.quantity || item.priceElement?.decorationCosts[0]?.quantity || item.jobItemVariant?.variants[0]?.quantity || item.jobItemVariant?.decorations[0]?.quantity;
        const originalDescription = item.description.split(`${quantity} x`);
        let descriptionString = `${stripHTML(originalDescription[0]).replace(/,\s*$/, '').replace(/.,\s*$/, '').replace(/ .\s*$/, '').replace(/ ,\s*$/, ',')}, `;
        if (item.priceElement) {
          if (showProducts) { descriptionString += item.priceElement?.variations.map((el, index) => `\n${el.quantity} x ${['productName', 'position', 'size', 'colour'].map((key) => el[key]).filter(Boolean).join(' - ')}${(item.priceElement?.variations?.length === index + 1) ? ',' : ''}`); }
          if (showDecorations) { descriptionString += item.priceElement?.decorationCosts.map((el, index) => `\n${el.quantity} x ${['description', 'position', 'size', 'colour'].map((key) => el[key]).filter(Boolean).join(' - ')}${(item.priceElement?.decorationCosts?.length === index + 1) ? ',' : ''}`); }
          if (showAdditionalCosts) { descriptionString += item.priceElement?.additionalCosts.map((el) => `\n1 x ${el.name && !el.ignoredByJob ? el.name.replace(',', '') : ''}, `); }
        }
        if (item.jobItemVariant) {
          if (showProducts) { descriptionString += item.jobItemVariant?.variants.map((el, index) => `\n${el.quantity} x ${['productName', 'size', 'colour'].map((key) => el[key]).filter(Boolean).join(' - ')}${(item.jobItemVariant?.variations?.length === index + 1) ? ',' : ''}`); }
          if (showDecorations) { descriptionString += item.jobItemVariant?.decorations.map((el, index) => `\n${el.quantity} x ${['description', 'position', 'size', 'colour'].map((key) => el[key]).filter(Boolean).join(' - ')}${(item.jobItemVariant?.decorations?.length === index + 1) ? ',' : ''}`); }
          if (showAdditionalCosts) { descriptionString += item.jobItemVariant?.additionalItems.map((el) => `\n1 x ${el.description.replace(',', '') ?? ''}, `); }
        }
        dispatch(change('invoiceForm', `items[${idx}].description`, `${descriptionString.replace(/,\s*$/, '').replace(/ .\s*$/, '')}`));
      }
    });
  }, [showProducts, showDecorations, showAdditionalCosts, dispatch, invoice?.items]);

  useEffect(() => {
    constructDescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showProducts, showDecorations, showAdditionalCosts, invoice]);

  const handleClose = () => {
    dispatch(closeInvoiceModal());
    dispatch(reset(INVOICE_FORM));
    // store detail settings on close
    putLocalStorageItem(INVOICE_SHOW_PRODUCTS_DETAILS_LOCALSTORAGE_KEY, showProducts);
    putLocalStorageItem(INVOICE_SHOW_DECORATIONS_DETAILS_LOCALSTORAGE_KEY, showDecorations);
    putLocalStorageItem(INVOICE_SHOW_ADDITIONAL_ITEMS_DETAILS_LOCALSTORAGE_KEY, showAdditionalCosts);
    dispatch(hoopsQuerySetRefetch('quote', true));
  };

  const customerId = invoice?.customer?._id;
  const contact = invoice?.customer?.contacts?.find(({_id}) => _id === values.contactId);
  const email = values?.contactId === 'CUSTOMER' ? (invoice?.customer?.email ?? invoice?.customer?.contacts?.[0]?.email) : contact?.email ?? '';
  const phone = values?.contactId === 'CUSTOMER' ? (invoice?.customer?.phone ?? invoice?.customer?.contacts?.[0]?.phone) : contact?.phone ?? '';
  const contactName = values?.contactId === 'CUSTOMER' ? invoice?.customer?.name : `${invoice?.customer?.name} | ${contact?.fullName ?? ''}`;
  const isNewSalesDoc = invoice?.items?.some((item) => item.salesDocItemId);

  return (
      <Dialog open={isOpen} fullScreen>
        <Form autoComplete='off' onSubmit={handleSubmit(saveInvoice)}>
          <Grid container className={classes.header}>
            <Toolbar className={classes.flex}>
              <Grid container justifyContent='space-between'>
                <Grid item xs={9}>
                  <Grid
                    container
                    spacing={4}
                    alignItems='flex-start'
                  >
                    <Grid item>
                      <Avatar className={classes.primaryColor}>
                        <PaymentIcon />
                      </Avatar>
                    </Grid>
                    <Grid item xs={10}>
                      {customerId &&
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <Typography className={classes.greyText}>Company</Typography>
                            <Typography variant='body2' className={classes.breakWordOverflow}>{invoice.customer.name}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.greyText}>Billing Address</Typography>
                            <AddressOneLine addresses={[addresses.billTo]} addressType={'BILLING'} />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.greyText}>Shipping Address</Typography>
                            <AddressOneLine addresses={[addresses.shipTo]} addressType={'SHIPPING'} />
                          </Grid>
                          {invoice?.customer?.contacts?.length > 1 ?
                            <Grid item xs={4}>
                              <Typography className={classes.greyText}>Contact</Typography>
                              <Field
                                name={'contactId'}
                                component={HoopsSelectField}
                                // style={{top: '1px', maxHeight: '35px'}}
                                MenuProps={{style: {maxHeight: 500}}}
                              >
                                {[null, ...invoice.customer.contacts].map((cont) => (
                                  <MenuItem key={cont?._id ?? 'CUSTOMER'} value={cont?._id ?? 'CUSTOMER'}>
                                    {cont?.fullName ?? 'Company'}
                                  </MenuItem>
                                ))}
                              </Field>
                            </Grid>
                            :
                            <Grid item xs={4}>
                              <Typography className={classes.greyText}>Contact</Typography>
                              <Typography variant='body2' className={classes.breakWordOverflow}>{invoice.customer.name}</Typography>
                            </Grid>
                          }
                          <Grid item xs={8}>
                            {company?.accountingPlatform?.platform === 'XERO' &&
                              <Typography variant='body2' className={classes.greyText}>
                                Hoops will synchronise contact information with Xero by
                                <b> (1)</b> Looking for the contact <b>{contactName}</b>. If it does not exist, or has been archived,
                                <b> (2)</b> Looking for a unique contact with the email address <b>{email}</b>. If one cannot be found,
                                <b> (3)</b> Creating the contact <b>{contactName}</b>.
                                If the contact has been archived in Xero and there is no unique alternative with the correct
                                email address, the invoice will not be sent to Xero, and an error will be reported.
                                Once Hoops creates a contact in Xero, Hoops will keep the contact synchronised with the Hoops contact
                                information. If a contact is archived or matched by email address, the information from Hoops will no
                                longer be synchronised with Xero.
                              </Typography>
                            }
                            {company?.accountingPlatform?.platform === 'QUICKBOOKS' &&
                              <Typography variant='body2' className={classes.greyText}>
                                Hoops will synchronise contact information with QuickBooks by
                                <b> (1)</b> Looking for the contact <b>{contactName}</b>. If it does not exist,
                                <b> (2)</b> Looking for a unique contact with the email address <b>{email}</b>. If one cannot be found,
                                <b> (3)</b> Creating the contact <b>{contactName}</b>.
                                Once Hoops creates a contact in QuickBooks, Hoops will keep the contact synchronised with the Hoops contact
                                information. If a contact is archived or matched by email address, the information from Hoops will no
                                longer be synchronised with QuickBooks.
                              </Typography>
                            }
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.greyText}>Email</Typography>
                            <Typography variant='body2' className={classes.breakWordOverflow}>{email}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className={classes.greyText}>Contact Number</Typography>
                            <Typography variant='body2' className={classes.breakWordOverflow}>{phone}</Typography>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid container>
                    <Grid xs={1}>
                      <Divider orientation='vertical' light variant='fullWidth' className={classes.vertDivider} />
                    </Grid>
                    {!isNewSalesDoc &&
                      <Grid item xs={11}>
                        <Typography variant='body1'>Show item detail</Typography>
                        <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
                          <Grid item xs={12} className={classes.itemDetailSettings}>
                            <Typography variant='body2' className={classes.greyText} display='inline'>Show Products</Typography>
                            <Switch
                              checked={showProducts}
                              color='primary'
                              onChange={(e, checked) => setShowProducts(checked)}
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.itemDetailSettings}>
                            <Typography variant='body2' className={classes.greyText} display='inline'>Show Decorations</Typography>
                            <Switch
                              checked={showDecorations}
                              color='primary'
                              onChange={(e, checked) => setShowDecorations(checked)}
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.itemDetailSettings}>
                            <Typography variant='body2' className={classes.greyText} display='inline'>Show Additional Costs</Typography>
                            <Switch
                              checked={showAdditionalCosts}
                              color='primary'
                              onChange={(e, checked) => setShowAdditionalCosts(checked)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  </Grid>

                </Grid>
                <Grid item>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
          <DialogContent>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item>
                <h4>{`Invoice ${invoice.invoiceNumber ? '#' : ''}${invoice.invoiceNumber ?? ''}`}</h4>
                {invoice.errorMessage &&
                  <Grid container>
                    <Typography>Last Error: </Typography>&nbsp;
                    <Typography className={classes.errorText}>{invoice.errorMessage}</Typography>
                  </Grid>
                }
              </Grid>
              <Grid item>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Invoice #</TableCell>
                      <TableCell align='right'>
                        {(invoice.invoiceNumber ? <span>{invoice.invoiceNumber}</span> : <span>DRAFT</span>)}
                      </TableCell>
                    </TableRow>
                    <TableRow align='right'>
                      <TableCell>Date</TableCell>
                      <TableCell>
                        {invoice.createdAt
                          ? moment(invoice.createdAt, 'x').format('DD MMM. YYYY')
                          : moment().format('DD MMM. YYYY')}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <Grid container>
              <InvoiceTable />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Button navNegative text={'Cancel'} onClick={handleClose}/>
              </Grid>
              <Grid item>
                <HelpArticle link='' />
              </Grid>
              <Grid className={classes.button} item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button navPositive text={'Save'} type='submit' loading={loading}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </Dialog>
  );
};

InvoiceModal = reduxForm({
  form: INVOICE_FORM,
  enableReinitialize: true,
})(InvoiceModal);

export default InvoiceModal;
