import React, {createElement} from 'react';
import {Route} from 'react-router';
import {ErrorBoundary} from './ErrorBoundary';

export function ErrorBoundaryRoute({component, render, children, ...props}) {
  if (component) {
    props.render = (renderProps) => (<ErrorBoundary>{createElement(component, renderProps, children)}</ErrorBoundary>);
  } else if (render) {
    props.render = (renderProps) => (<ErrorBoundary>{createElement(render, renderProps, children)}</ErrorBoundary>);
  } else if (children) {
    props.render = (renderProps) => createElement(ErrorBoundary, renderProps, children);
  }
  return (
    <Route {...props} />
  );
}

ErrorBoundaryRoute.propTypes = {...(Route.propTypes ?? {})};
