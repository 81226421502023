import React, {createElement, isValidElement} from 'react';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.material-symbols-outlined', () => ({
  '&.filled': {fontVariationSettings: '\'FILL\' 1'},
  '&.outlined': {fontVariationSettings: '\'FILL\' 0'}
}));

export function Decorator({className, decorator, children, outlined}) {
  children = children ?? decorator;

  if (!children) {
    return null;
  }

  if (isValidElement(children)) {
    if (className){
      return <div className={classNames(className)}>{children}</div>;
    }
    return children;
  }

  if (typeof children === 'string') {
    return <span className={classNames(className, 'decorator', 'material-symbols-outlined notranslate', outlined ? 'outlined' : 'filled')}>{children}</span>;
  }

  if (!className) {
    return createElement(children);
  }

  return <div className={classNames(className)}>{createElement(children)}</div>;
}

Decorator.propTypes = {
  className: HoopsPropTypes.className,
  decorator: HoopsPropTypes.decorator,
  children: HoopsPropTypes.decorator,
  outlined: HoopsPropTypes.bool,
};
