import {LOGIN_TOKEN_KEY} from '../constants/api';
import {client} from './GraphQLService';
import {GQL_GET_ME} from '../queries/users';
import {GQL_AUTH_LOGIN, GQL_AUTH_LOGOUT, GQL_AUTH_RESET_PASSWORD} from '../queries/auth';

export class AuthService {
  static async login(username, password) {
    const response = await client.mutate({
      mutation: GQL_AUTH_LOGIN,
      variables: {
        username,
        password
      }
    });

    localStorage.setItem(LOGIN_TOKEN_KEY, response.data.login.token);
    return response.data.login;
  }

  static async logout() {
    await client.mutate({mutation: GQL_AUTH_LOGOUT,});

    localStorage.removeItem(LOGIN_TOKEN_KEY);
  }

  static getMe() {
    return client.query({
      query: GQL_GET_ME,
      //fetchPolicy should be "cache first" but
      //to cache first works we need to clear the cache storage when logout
      //but to this we need to unify all apollo clients
      //function createGraphQLClient should be called only once in all front end, all client reference to the same apollo instance, instead to create several instances. should be called only once in all front end, all client reference to the same apollo instance, instead to create several instansces.
      fetchPolicy: 'no-cache'
    })
      .then(({data: {user}}) => {
        if (!user) {
          throw new Error('Not logged in.');
        }
        console.log('Client build ', process.env.REACT_APP_BUILD);
        console.log('Server build ', user.build);
        return user;
      });
  }

  static resetPassword(email) {
    return client.mutate({
      mutation: GQL_AUTH_RESET_PASSWORD,
      variables: {email}
    });
  }
}
