import React from 'react';
import {BrowserRouter, withRouter} from 'react-router-dom';
import {ErrorBoundaryRoute} from '../../componentsHoops/Errors';
import SingleGuestQuoteView from '../../componentsOld/quotes/SingleQuote/SingleGuestQuoteView';
import ProofPreview from '../../componentsOld/jobs/Guest/ProofPreview';
import GuestViewPurchaseOrder from '../../componentsOld/purchase-orders/SinglePurchaseOrder/GuestViewPurchaseOrder/GuestViewPurchaseOrder';
import {SalesDocGuestPage} from '../../pages/SalesDoc';

export const GUEST_BASENAME = '/public';

const _GuestRoutes = () => (
      <>
        <BrowserRouter basename={GUEST_BASENAME}>
          <>
            <ErrorBoundaryRoute path='/quotes/:quoteId/view' exact component={SingleGuestQuoteView}/>
            <ErrorBoundaryRoute path='/quotes/:quoteId/viewGroup' exact render={(props) => <SingleGuestQuoteView {...props} renderSiblings={true} />} />
            <ErrorBoundaryRoute path='/jobs/:jobId/proofs' exact component={ProofPreview}/>
            <ErrorBoundaryRoute path='/purchase-orders/:orderId/view-purchase-order' exact component={GuestViewPurchaseOrder}/>
            <ErrorBoundaryRoute path='/sales/:salesDocId/view' exact component={SalesDocGuestPage}/>
            <ErrorBoundaryRoute path='/sales/:salesDocId/pdf' exact render={() => <SalesDocGuestPage hideActionButtons={true} printPdf={true} />}/>
          </>
        </BrowserRouter>
      </>
    );

const GuestRoutes = withRouter(_GuestRoutes);
export default GuestRoutes;
