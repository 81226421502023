// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Box, Grid, IconButton, ListItemIcon, makeStyles, Menu, MenuItem, Tab, Tabs, Typography} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, useHistory} from 'react-router';
import {Link, useParams} from 'react-router-dom';
import {getCustomer, setFormCustomer} from '../../actions/action-types';
import {openCustomerModal} from '../../actions/modals';
import Files from '../../modules/file/Files';
import {permissionCheck, roles, SubscriptionTypes} from '../../componentsHoops/AccessControl';
import CustomerProofs from '../../pages/customer/customerProofs/CustomerProofs';
import ActionsButton from '../common/ActionsButton';
import CustomerViewDetail from './CustomerViewDetail';
import CustomerViewTasks from './CustomerViewTasks';
import theme from '../../ui/theme';
import {JobBoardPage} from '../../pages/Job';
import {PageContainer} from '../../componentsLib/Layout';
import {SalesBoardPage} from '../../pages/SalesDoc';
import {CommentsEntityViewer} from '../../componentsHoops/Comments/CommentsEntityViewer';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabHeight = '48px';
const useStyles = makeStyles((theme) => ({
  primaryHeading: {
    paddingRight: theme.spacing(2),
    color: theme.palette.primary.main
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,

  },
  tabPanel: {padding: '0px'},
}));

export const CustomerViewPage = () => {
  const classes = useStyles();
  const customerSelector = (state) => state.customerReducer.customer;
  const customer = useSelector(customerSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const {_id} = useParams();
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const {admin, standard} = roles;

  useEffect(() => {
    dispatch(getCustomer(_id));
  }, [dispatch, _id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openEditModal = () => {
    dispatch(setFormCustomer(_id, () => {
      dispatch(openCustomerModal({
        callback: () => {
          dispatch(getCustomer(_id));
        }
      }));
    }));
  };

  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={4}>
        <Grid item>
          <Grid container spacing={2} direction='row' justifyContent='center' alignItems='center'>
            <Grid item>
              <Link to={'/customers'}
                style={{textDecoration: 'none'}}>
                <IconButton><Icon>arrow_back</Icon></IconButton>
              </Link>
            </Grid>
            <Grid item>
              <h3><span className={classes.primaryHeading}>Customer Profile </span>{customer.name}</h3>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ActionsButton onClick={handleClick} />
          <Menu
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={openEditModal}>
              <ListItemIcon>
                <Icon>edit</Icon>
              </ListItemIcon>
              Edit
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Grid container spacing={4} direction={'row'}>
        <Grid item xs={12}>
          <Tabs
            onChange={handleChange}
            indicatorColor='primary'
            value={value}
            textColor='primary'
            classes={{
              root: classes.tabsRoot,
              wrapper: classes.tabsWrapper
            }}>
            <Tab label='Details' value='details' {...a11yProps(0)} />
            {permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs], allowedRoles: [admin, standard]}) &&
              <Tab label='SalesDocs' value='salesDocs' {...a11yProps(1)} />
            }
            <Tab label='Jobs' value='jobs' {...a11yProps(2)} />
            <Tab label='Proofs' value='proofs' {...a11yProps(3)} />
            {permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs]}) &&
              <Tab label='Files' value='files' {...a11yProps(4)} />}
            <Tab label='Comments' value='comments' {...a11yProps(5)} />
            {permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs]}) &&
              <Tab label='Tasks' value='tasks' {...a11yProps(6)} />}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {/* Tab Panel Components*/}
          <Route path='/customers/:_id/details' exact component={() => <CustomerViewDetail customer={customer} />} />
          {permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs], allowedRoles: [admin, standard]}) &&
            <Route path='/customers/:customerId/salesDocs' exact component={() => <CustomerSalessBoard />} />
          }
          <Route path='/customers/:customerId/jobs' exact component={() => <CustomerJobsBoard customerId={customer._id} />} />
          <Route path='/customers/:_id/proofs' exact component={() => <CustomerProofs canDelete={true} isJobView={false} customerId={customer._id} isWorkable={true} showSendBtn={false} showUsePreviousProofsBtn={false} />} />
          {permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs]}) &&
            <Route path='/customers/:_id/files' exact component={() => <Files isProof={false} canDelete={true} customerId={customer._id} isWorkable={true} showCustomerBtn={false} showUploadFileBtn={true} />} />
          }
          <Route path='/customers/:_id/comments' exact component={() =>
            <CommentsEntityViewer entity={'customer'} entityId={customer._id} />
          } />
          {permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs]}) &&
            <Route path='/customers/:_id/tasks' exact component={() => <CustomerViewTasks customer={customer} />} />
          }
        </Grid>
      </Grid>

    </>
  );
};

export default CustomerViewPage;

function CustomerSalessBoard() {
  return (
    <PageContainer style={{
      paddingTop: 10,
      margin: '-24px 0 -16px -24px',
      width: 'calc(100% + 48px)',
      height: 'calc(100vh - 145px)',
      minHeight: 'calc(100% - 145px)',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.colors.white,
      position: 'relative'
    }}>
      <SalesBoardPage />
    </PageContainer>
  );
}

function CustomerJobsBoard() {
  return (
    <PageContainer style={{
      paddingTop: 10,
      margin: '-24px 0 -16px -24px',
      width: 'calc(100% + 48px)',
      height: 'calc(100vh - 145px)',
      minHeight: 'calc(100% - 145px)',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.colors.white,
      position: 'relative'
    }}>
      <JobBoardPage />
    </PageContainer>
  );
}
