import React, {useCallback} from 'react';
import {AttentionConfirmDialog} from '../../componentsLib/Popovers';
import {registerGlobalStyle} from '../../theme';
import {useUpdateUser} from '../../hooks/api';
import {useUser} from '../../hooks';
import {useHeaderContext} from './HeaderContext';

registerGlobalStyle('.session-change-dialog', (theme) => ({
  '.actions.row': {justifyContent: 'center'},
  'p.text-body.text': {
    boxSizing: 'content-box',
    fontSize: theme.fontSize(14),
    maxWidth: 348,
  },
  'button': {textWrap: 'nowrap'},
}));

export function MultiSessionNotification() {
  const {sessionId, setSessionId} = useHeaderContext();
  const {user} = useUser();
  const {update: updateUser, isSaving} = useUpdateUser();

  const keepThisSession = useCallback(async () => {
    await updateUser({id: user._id, user: {activeSession: null}});
    setSessionId(0);
  }, [setSessionId, updateUser, user?._id]);

  return (
    <>
      {user?._id && sessionId > 0 &&
        <AttentionConfirmDialog
          className={'session-change-dialog'}
          title={'Duplicate Session Detected'}
          content={'Your user account is active on multiple devices. If you choose to work on this device, the other devices will be deactivated.'}
          warningIcon
          cancelText={null}
          okText={'Work on this device'}
          loading={isSaving}
          onOk={keepThisSession}
        />
      }
    </>
  );
}
