import {
  Button,
  Dialog,
  DialogActions, DialogContent, Grid, Icon
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {change} from 'redux-form';
import {closePurchaseOrderFileUploadModal} from '../../../../actions/modals';
import {Files} from '../../../../modules/file/Files';

/**
 * Okay this is a mess. due to time constraints we are going to do a blend of our new pattern
 * and the existing pattern
 */
export const UploadModal = () => {
  const dispatch = useDispatch();

  const activePurchaseOrderSelector = (state) => state.purchaseOrdersReducer.activePurchaseOrder;
  const modalSelector = (state) => state.modalReducer.purchaseOrderFileUploadModal;

  const activePurchaseOrder = useSelector(activePurchaseOrderSelector);
  const {context, isOpen} = useSelector(modalSelector);

  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    setSelectedFiles(context.initialValues.fileIds);
  }, [context]);

  const getEntityIds = [];

  if (activePurchaseOrder._id) {
    getEntityIds.push(activePurchaseOrder._id);
  }

  if (activePurchaseOrder.linkedJobIds) {
    getEntityIds.push(...activePurchaseOrder.linkedJobIds);
  }

  const handleSubmit = () => {
    dispatch(
      change(
        'purchaseOrderForm',
        `${context.item}.files`,
        selectedFiles.map((_id) => ({_id}))
      )
    );
    handleClose();
  };

  const handleClose = () => {
    dispatch(closePurchaseOrderFileUploadModal());
  };

  const handleFilesSelect = (fileIds) => {
    setSelectedFiles(fileIds);
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth={'lg'}
      fullWidth={true}
      onClose={handleClose}
    >
      <DialogContent>
        <Files
          getEntityIds={getEntityIds}
          setEntityIds={[activePurchaseOrder._id]}
          canCreateTask={false}
          canDelete={false}
          isWorkable={true}
          filePreviewOptions={{
            thumbWidth: '100px',
            thumbHeight: '100px',
            tooltipMaxWidth: '650px'
          }}
          selectable={true}
          selected={selectedFiles}
          onSelect={handleFilesSelect}
          showAddButton={!!activePurchaseOrder._id}
          showCustomerBtn={false}
          showUploadFileBtn={!!activePurchaseOrder._id}
          showTitle={true}
          entityTitle='Purchase Order'
          isProof={null}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Button onClick={handleClose}>
              <Icon>close</Icon>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={handleSubmit}
                  type='submit'>Associate Files</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

};

export default UploadModal;
