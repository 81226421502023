import React from 'react';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';
import {ToolTip} from './ToolTip';
import classNames from 'classnames';

registerGlobalStyle('.lock-decorator', (theme) => ({
  position: 'absolute',
  left: theme.spacing(.5),
  display: 'flex',
  alignSelf: 'center',
  backgroundColor: 'inherit',
  zIndex: 1,
  opacity: 0,
  cursor: 'pointer',
  pointerEvents: 'unset',
  '.lock-icon': {
    fontSize: '1rem',
    backgroundColor: 'inherit',
    '&.locked::before, &.unlocked::before': {backgroundColor: 'inherit'},
    '&.locked::before': {content: '"lock"', fontVariationSettings: '\'FILL\' 1'},
    '&.unlocked::before': {content: '"lock_open"', fontVariationSettings: '\'FILL\' 0'},
    '&:hover': {
      color: theme.colors.palette.blue,
      '&.locked::before': {content: '"lock_open"', fontVariationSettings: '\'FILL\' 0'},
      '&.unlocked::before': {content: '"lock"', fontVariationSettings: '\'FILL\' 1'}
    },
  },
  ':is(.text-input:hover .lock-decorator, &:focus-within .lock-decorator)': {
    opacity: 1,
    transition: theme.transitions.in.all,
  },
}));

export const LockDecorator = ({className, locked, lockTip, unlockTip, onChange}) => (
  <ToolTip className={classNames([className, 'lock-decorator'])} tip={locked ? unlockTip : lockTip} left >
    <div className={classNames(['lock-icon', 'material-symbols-outlined', locked ? 'locked' : 'unlocked'])} onClick={(event) => onChange(event, !locked)} />
  </ToolTip>
);

LockDecorator.propTypes = {
  className: HoopsPropTypes.className,
  onChange: HoopsPropTypes.func,
  locked: HoopsPropTypes.bool,
  lockTip: HoopsPropTypes.string,
  unlockTip: HoopsPropTypes.string,
};
