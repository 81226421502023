import {Grid, makeStyles} from '@material-ui/core';
import React, {useState} from 'react';
import {PermissionBasedContent, SubscriptionTypes} from '../../componentsHoops/AccessControl';
import {OrgSettingsField} from './OrgSettingsField';

import SettingsPaper from './SettingsPaper';

const useStyles = makeStyles((theme) => ({
  header: {marginBottom: theme.spacing(2)},
  paperPadding: {padding: theme.spacing(5)},
  subTitle: {
    color: theme.colors.grey.main,
    fontSize: '16px',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1.5)
  },
  bottomMargin: {marginBottom: theme.spacing(1.5)},
  editIcon: {
    fontSize: '30px',
    cursor: 'pointer'
  },
  editField: {paddingTop: theme.spacing(5)},
  termsContainer: {
    maxHeight: 400,
    overflowY: 'auto',
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

export const CompanyEntityNumbers = ({
  company,
  loading,
  saveSettings = async () => null
}) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [companyUpdate, setCompanyUpdate] = useState(company);
  const [disabled, setDisabled] = useState(false);

  const toggleEdit = () => {
    setCompanyUpdate(company);
    setEditing((prev) => !prev);
  };

  const handleCompanyEdit = (params) => {
    setCompanyUpdate((prev) => ({...prev, ...params}));
  };

  const handleSave = async () => {
    await saveSettings(companyUpdate);
    setEditing(false);
  };

  return (<SettingsPaper title={'Starting Numbers'}
    subTitle={'Set the starting numbers for your account'}
    editing={editing}
    disabled={disabled}
    onEdit={toggleEdit}
    onSave={handleSave}
    onCancel={toggleEdit}
    loading={loading}
    gridContainerProps={{spacing: 3}}>

    <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
      <Grid item xs={12} className={editing ? classes.editField : null}>
        <OrgSettingsField
          onChange={handleCompanyEdit}
          company={companyUpdate}
          minValue={company.quoteNumber - 1}
          setDisabled={setDisabled}
          editing={editing}
          fieldName={'Quotes'}
          fieldReference={'quoteNumber'}
        />
      </Grid>
    </PermissionBasedContent>

    <Grid item xs={12} className={editing ? classes.editField : null}>
      <OrgSettingsField
        onChange={handleCompanyEdit}
        company={companyUpdate}
        minValue={company.jobNumber - 1}
        setDisabled={setDisabled}
        editing={editing}
        fieldName={'Jobs'}
        fieldReference={'jobNumber'}
      />
    </Grid>

    <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
      <Grid item xs={12} className={editing ? classes.editField : null}>
        <OrgSettingsField
          onChange={handleCompanyEdit}
          company={companyUpdate}
          minValue={company.purchaseOrderNumber - 1}
          setDisabled={setDisabled}
          editing={editing}
          fieldName={'Purchase Orders'}
          fieldReference={'purchaseOrderNumber'}
        />
      </Grid>
    </PermissionBasedContent>

  </SettingsPaper>);
};
