import {Button, MuiThemeProvider} from '@material-ui/core';
import {Grid} from '@mui/material';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {reset} from 'redux-form';
import {clearQuoteForm, SET_QUOTE} from '../../actions/quote';
import green from '../../ui/theme/green';
import {EntityListHero} from '../EntityList/EntityListHero';
import QuoteManyTable from './QuoteManyTable';
import {NewQuoteBuilderBannerProvider, SalesBoardBanner, ShowSalesBoardBannerButton} from '../../pages/SalesDoc/Components';

const sx = {
  position: 'absolute',
  width: 'unset',
  height: 'unset',
  left: 24,
  right: 24,
  bottom: 12,
  top: 24,
};

function Quotes() {
  const dispatch = useDispatch();
  const history = useHistory();

  const openCreateQuote = () => {
    dispatch(clearQuoteForm());
    dispatch(reset('quoteForm'));
    dispatch({type: SET_QUOTE, payload: {}});

    history.push('/quotes/new');
  };

  useEffect(() => {
    document.title = 'Quotes | Hoops';
  });

  return (
    <Grid container direction='column' wrap='nowrap' sx={sx}>
      <NewQuoteBuilderBannerProvider>
        <SalesBoardBanner/>
        <EntityListHero
          title='Quotes'
          subtitle='Prepare &amp; send beautiful online quotes in less than 1-minute. '
          helpArticleUrl='http://help.hoopscrm.com/en/articles/4632984-creating-quotes'
          videoId='oJjG9FUEMUo'
        >
          <Grid container direction='row' justifyContent='center' alignItems='center' gap={2}>
            <ShowSalesBoardBannerButton/>
            <MuiThemeProvider theme={green}>
              <Button
                data-intercom-target={'quote-create'}
                onClick={openCreateQuote}
                color={'primary'}
                variant={'contained'}>New Quote</Button>
            </MuiThemeProvider>
          </Grid>
        </EntityListHero>
        <Grid container justifyContent={'space-between'} alignItems={'center'} sx={{overflow: 'hidden', flexGrow: 1}}>
          <Grid item xs={12} data-intercom-target={'quote-table'} sx={{flexShrink: 1, height: '100%', display: 'flex', flexDirection: 'column'}}>
            <QuoteManyTable openCreateQuote={openCreateQuote} />
          </Grid>
        </Grid>
      </NewQuoteBuilderBannerProvider>
    </Grid>
  );
}

export default Quotes;
