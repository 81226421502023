import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import {client} from '../../servicesOld/GraphQLService';

const HeaderContext = createContext(null);
export const useHeaderContext = () => useContext(HeaderContext);

export function HeaderContextProvider({children}) {
  const [sessionId, setSessionId] = useState(0);
  const [minReleaseDate, setMinReleaseDate] = useState('');

  const handleSessionChanged = useCallback(() => setSessionId((prev) => prev + 1), []);

  const context = useMemo(() => {
    // This is rather hackish, but there is no other way to get a hook into the graphql system
    client.onSessionChanged = handleSessionChanged;
    return {
      minReleaseDate,
      sessionId,
      onSessionChanged: handleSessionChanged,
      setMinReleaseDate,
      setSessionId,
    };
  }, [handleSessionChanged, minReleaseDate, sessionId]);

  return (
    <HeaderContext.Provider value={context}>
      {children}
    </HeaderContext.Provider>
  );
}
