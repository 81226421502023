import React from 'react';
import {CaptionText} from '../../../componentsLib/Text';
import {Button, ButtonTheme, Switch, TextInput, ToolTip} from '../../../componentsLib/Basic';
import {usePageContext} from '../../../componentsLib/Layout';
import {ColorPicker} from '../../../componentsLib/Pickers';
import {HoopsPropTypes} from '../../../componentsLib';

export const UserActionButtonSettings = ({name, disabled, disabledSwitch, toolTip, children, ...buttonProps}) => {
  const {salesDoc} = usePageContext();

  return (
    <>
      {/* Header Row (5 grid column row) */}
      <div />
      <CaptionText text={'Enable'} />
      <CaptionText text={'Color'} />
      {salesDoc.template[`${name}Text`] &&
        <CaptionText text={'Button Text'} />
      }
      {!salesDoc.template[`${name}Text`] &&
        <div />
      }
      <div />

      {/* Controls Row (5 grid column row) */}
      <ButtonTheme color={salesDoc.template[`${name}Color`]}>
        <Button navMain text={salesDoc.template[`${name}Text`]} caps={false} disabled={disabled || salesDoc.template[`${name}Enabled`] === false} {...buttonProps} />
      </ButtonTheme>
      <ToolTip focusable tip={toolTip}>
        <Switch checked={salesDoc.template[`${name}Enabled`] ?? true} disabled={disabled || salesDoc.template[`${name}Enabled`] == null || disabledSwitch} onChange={(value) => salesDoc.setTemplateButtonProperty(name, 'enabled', value)} />
      </ToolTip>
      <ColorPicker color={salesDoc.template[`${name}Color`]} onChange={(value) => salesDoc.setTemplateButtonProperty(name, 'color', value)} disabled={disabled || salesDoc.template[`${name}Enabled`] === false} />
      {salesDoc.template[`${name}Text`] &&
        <TextInput value={salesDoc.template[`${name}Text`]} onChange={(value) => salesDoc.setTemplateButtonProperty(name, 'text', value)} disabled={disabled || salesDoc.template[`${name}Enabled`] === false} />
      }
      {!salesDoc.template[`${name}Text`] &&
        <div />
      }
      <div>{children}</div>
    </>
  );
};

UserActionButtonSettings.propTypes = {
  name: HoopsPropTypes.string,
  disabled: HoopsPropTypes.bool,
  disabledSwitch: HoopsPropTypes.bool,
  buttonProps: HoopsPropTypes.object,
  toolTip: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
