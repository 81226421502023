import React from 'react';
import {registerGlobalStyle} from '../../theme';
import {Row, RowRight} from '../Layout';
import {HeadingText} from '../Text';
import {HoopsPropTypes} from '../utils';

registerGlobalStyle('.page-header', (theme) => ({
  backgroundColor: theme.colors.background.white,
  padding: theme.spacing(1.5, 2, 1.375),
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.appBar,
  alignItems: 'center',
  columnGap: theme.spacing(2),
  h4: {width: 'unset',},
  '&.border': {borderBottom: `1px solid ${theme.colors.border.light}`,},
}));

export function PageHeader({title, border, children}) {
  return (
    <Row className={['page-header', border && 'border']}>
      {title &&
        <>
          <HeadingText x24>
            {title}
          </HeadingText>
          <RowRight>
            {children}
          </RowRight>
        </>
      }
      {!title &&
        children
      }
    </Row>
  );
}

PageHeader.propTypes = {
  border: HoopsPropTypes.bool,
  title: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
