import React, {useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {Delete as DeleteIcon} from '@mui/icons-material';
import {Fields, Page, Row} from '../../../componentsLib/Layout';
import {BodyText, CaptionText, HeadingText} from '../../../componentsLib/Text';
import {registerGlobalStyle} from '../../../theme';
import {Button, ToolTip} from '../../../componentsLib/Basic';
import {ModelessPopover, Placement, PopupItem, SlidingDrawer, useModelessPopoverState} from '../../../componentsLib/Popovers';
import {useGetSalesDocTypeNames, useListSalesDocs} from '../../../hooks/api';
import {SalesDoc} from '../Models/SalesDoc';
import {useHistory} from 'react-router-dom';
import {useViewState, ViewTabs, useViewColumns} from '../../../componentsHoops/Views';
import {FeatureFlag, FeatureFlags} from '../../../componentsHoops/AccessControl';
import {useSalesDocsState} from '../State/useSalesDocsState';
import {ActionMenuCell, ACCOUNTING_STATUS, AccountingStatusCell, LinkCell, PowerGrid, PowerGridComponents} from '../../../componentsLib/PowerGrid';
import {asCurrencyStringCommaSeparated, formatDateTimeRelative} from '../../../utils';
import {AutoSaveService} from '../../../services';
import {PageHeader} from '../../../componentsLib/Banners';
import {NewQuoteBuilderBannerProvider, SalesBoardBanner, SalesDocActionMenu, SalesDocGridFilterPanel, ShowSalesBoardBannerButton} from '../Components';
import {SalesDocPage} from './SalesDocPage';
import {groupBy, uniq} from 'lodash';
import {JobPage} from '../../Job/Pages/JobPage';

registerGlobalStyle('.popup-menu ul li.salesboard-menu-item', (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: 0,
  'button.icon': {padding: theme.spacing(.75, .75, .75, 1.25)},
  'svg': {
    transition: theme.transitions.out.all,
    '&:hover': {
      color: theme.colors.text.highlight,
      transition: theme.transitions.in.all,
    },
    fontSize: '1.25rem',
  },
  '.text.text-caption': {fontSize: '.75rem'},
  '.text.text-body': {fontSize: '.875rem'},
  '&.template-type-header': {
    fontStyle: 'italic',
    paddingBottom: 0,
  },
  '&.template-type': {
    justifyContent: 'start',
    alignItems: 'center',
    '.text.text-caption': {fontSize: '.875rem'},
  },
}));

const staticColumnInfo = {
  'number': {
    width: 164,
    renderCell: (props) => <LinkCell to={`/sales/${props.row.number}`} {...props} />,
    cellClassName: 'bold',
  },
  'jobNumber': {
    filters: ['equals', 'isEmpty', 'isNotEmpty'],
    sort: -1,
    align: 'center',
    editable: false,
    renderCell: (props) => <LinkCell to={`/sales/job/${props.row.jobId}`} {...props} />,
    cellClassName: 'bold',
  },
  'salesStoreNumber': {
    filters: ['equals', 'isEmpty', 'isNotEmpty'],
    width: 164,
    sort: -1,
    align: 'center',
    editable: false,
    renderCell: (props) => <LinkCell to={`/sales/${props.row.salesStoreNumber}`} value={props.row.salesStoreNumber} />,
    valueGetter: ({row}) => row.salesStoreNumber,
    cellClassName: 'bold',
  },
  'docTypeName': {
    filters: ['isAnyOf'],
    editable: false,
    type: 'singleSelect',
    valueGetter: ({row: {docTypeName, documentType} = {}} = {}) => documentType === SalesDoc.Type.CLASSIC_QUOTE ? 'Classic Quote' : docTypeName,
  },
  'subTotal': {
    innerClassName: 'currency-prefix',
    valueGetter: ({value}) => asCurrencyStringCommaSeparated(value),
    editable: false,
    filters: ['isNotZero', 'isZero', '=', '>=', '>', '<=', '<'],
    type: 'number',
    align: 'right',
  },
  'invoice.status': {
    filters: ['isAnyOf', 'isEmpty', 'isNotEmpty'], type: 'singleSelect',
    valueOptions: Object.entries(ACCOUNTING_STATUS).map(([key, {label}]) => ({value: key, label})),
    renderCell: (props) => <AccountingStatusCell invoice={props.row.invoice} {...props} />,
  },
  'DECORATION': {valueGetter: ({row}) => uniq(row.summary?.decorations?.map(({category}) => category) ?? [])},
  'VENDOR': {valueGetter: ({row}) => uniq(row.summary?.vendors?.map(({name}) => name) ?? [])},
  'actions': {
    renderCell: (props) => (
      <ActionMenuCell>
        <SalesDocActionMenu salesDoc={props.row} updateField={props.colDef.context.updateField} refetchSalesDocs={props.colDef.context.refetch} />
      </ActionMenuCell>
    ),
  }
};

const SalesDocGridComponents = {
  ...PowerGridComponents,
  FilterPanel: SalesDocGridFilterPanel,
};

export function EntityBoardPage() {
  const history = useHistory();
  const {jobId, salesDocNumber, salesDocId, customerId} = useParams();
  const viewState = useViewState('salesdocs', {homeViewOnly: !!customerId});
  const query = customerId ? {customerId: customerId} : {};
  const {salesDocs, total, isLoading: isSalesDocsLoading, refetch: refetchSalesDocs, updateField} = useSalesDocsState({...viewState.apiState, query});
  const {data: {docTypeNames}, isLoading: isDocTypeNamesLoading} = useGetSalesDocTypeNames();
  const extraColumnInfo = useMemo(() => ({
    ...staticColumnInfo,
    docTypeName: {
      ...staticColumnInfo.docTypeName,
      valueOptions: Object.entries(docTypeNames ?? {}).map(([label, value]) => ({label, value})),
    },
    salesStoreNumber: {
      ...staticColumnInfo.salesStoreNumber,
      hide: !FeatureFlags.SalesStores.enabled(),
    },
  }), [docTypeNames]);
  const viewColumns = useViewColumns(viewState, {extraColumnInfo, updateField, refetch: refetchSalesDocs});

  const handleCloseDrawer = useCallback(() => {
    history.push('/sales');
  }, [history]);

  const context = useMemo(() => ({viewState}), [viewState]);

  return (
    <Page testId={'salesboard-page'} className={'salesboard-page'} context={context} title={'Sales | Hoops'} paper>
      {customerId == null &&
        <>
          <SalesBoardTitleBar/>
          <ViewTabs viewState={viewState} />
        </>
      }

      <PowerGrid
        columnState={viewColumns}
        components={SalesDocGridComponents}
        density={viewState.currentView.density}
        loading={viewState.isLoading || isSalesDocsLoading || isDocTypeNamesLoading}
        page={viewState.page}
        pageSize={viewState.pageSize}
        rows={salesDocs}
        rowsTotal={total}
        search={viewState.search}
        sort={viewState.currentView.sort}
        filters={viewState.currentView.filters}
        viewChanged={viewState.currentView.changed}
        viewReadOnly={viewState.currentView.readonly}

        onChangeDensity={viewState.setDensity}
        onChangeFilters={viewState.setFilters}
        onChangePage={viewState.setPage}
        onChangePageSize={viewState.setPageSize}
        onChangeSearch={viewState.setSearch}
        onChangeSort={viewState.setSort}
        onSaveView={viewState.saveView}
      />

      <SlidingDrawer className={'salesboard-drawer salesdoc-drawer'} open={salesDocNumber != null || salesDocId != null} onClose={handleCloseDrawer}>
        {(salesDocNumber != null || salesDocId != null) &&
          <SalesDocPage updateField={updateField} refetchSalesDocs={refetchSalesDocs} />
        }
      </SlidingDrawer>

      <SlidingDrawer className={'salesboard-drawer job-drawer'} open={jobId != null} onClose={handleCloseDrawer}>
        {jobId != null &&
          <JobPage job={{_id: jobId}} updateJobBoard={refetchSalesDocs} />
        }
      </SlidingDrawer>
    </Page>
  );
}

function SalesBoardTitleBar() {
  return (
    <NewQuoteBuilderBannerProvider>
      <SalesBoardBanner />
      <PageHeader>
        <HeadingText x32>Sales<span>Board</span></HeadingText>
        <DraftMenu />
        <ShowSalesBoardBannerButton />
        <SalesDocTemplateMenu />
      </PageHeader>
    </NewQuoteBuilderBannerProvider>
  );
}

function SalesDocTemplateMenu() {
  const history = useHistory();
  const {data: {salesDocs: templates}, isLoading} =
    useListSalesDocs({query: {documentType: {$in: [SalesDoc.Type.TEMPLATE, SalesDoc.Type.PRESENTATION_TEMPLATE, SalesDoc.Type.SALESSTORE_TEMPLATE]}}});

  const templatesGroupedByType = groupBy(templates ?? [], ({documentType}) => documentType);

  const handleNewSalesDoc = useCallback((template) => {
    if (template) {
      history.push(`/sales/new/${template._id ?? template}`);
    } else {
      history.push('/sales/new');
    }
  }, [history]);

  return (
    <Button navMain menu loading={isLoading} text={'ADD NEW'}>
      {templatesGroupedByType.template?.length > 0 &&
        <TemplateMenuItems title={'SalesDoc Templates'} templates={templatesGroupedByType.template} onClick={handleNewSalesDoc} />
      }
      {templatesGroupedByType.presentationTemplate?.length > 0 &&
        <TemplateMenuItems title={'SalesPresentation Templates'} templates={templatesGroupedByType.presentationTemplate} onClick={handleNewSalesDoc} />
      }
      {templatesGroupedByType.salesStoreTemplate?.length > 0 &&
        <FeatureFlag allow={FeatureFlags.SalesStores}>
          <TemplateMenuItems title={'SalesStore Templates'} templates={templatesGroupedByType.salesStoreTemplate} onClick={handleNewSalesDoc} />
        </FeatureFlag>
      }
      <PopupItem onClick={() => handleNewSalesDoc('quote')} className={'salesboard-menu-item'}>
        <BodyText>Manage SalesDoc Templates</BodyText>
      </PopupItem>
      <PopupItem onClick={() => handleNewSalesDoc('presentation')} className={'salesboard-menu-item'}>
        <BodyText>Manage SalesPresentation Templates</BodyText>
      </PopupItem>
      <FeatureFlag allow={FeatureFlags.SalesStores}>
        <PopupItem onClick={() => handleNewSalesDoc('salesStore')} className={'salesboard-menu-item'}>
          <BodyText>Manage SalesStore Templates</BodyText>
        </PopupItem>
      </FeatureFlag>
    </Button>
  );
}

function TemplateMenuItems({title, templates, onClick}) {
  return (
    <>
      <PopupItem className={'salesboard-menu-item template-type-header'} disabled>
        <CaptionText>{title}</CaptionText>
      </PopupItem>
      {
        templates?.sort((a, b) => a.docTypeName.localeCompare(b.docTypeName) || a.docTemplateName.localeCompare(b.docTemplateName)).map((template) => (
          <PopupItem key={template.docTemplateName} className={'salesboard-menu-item template-type'} onClick={() => onClick(template)}>
            <BodyText>{template.docTypeName}<CaptionText>{`  |  ${template.docTemplateName}`}</CaptionText></BodyText>
          </PopupItem>
        ))
      }
      <PopupItem divider />
    </>
  );
}

function DraftMenu() {
  const history = useHistory();
  const [autoSaveRecords, setAutoSaveRecords] = useState(AutoSaveService.listRecords({type: 'salesdoc'}));
  const [deletingDraft, setDeletingDraft] = useState();
  const {open, anchorEl, anchorElRef, closePopover, togglePopover} = useModelessPopoverState();

  const handleEditDraft = useCallback((key) => {
    if (key) {
      history.push(`/sales/draft/${key}`);
    } else {
      history.push('/sales/new');
    }
  }, [history]);

  const handleShowConfirmDelete = useCallback((e, draft) => {
    e.stopPropagation();
    setDeletingDraft(draft);
    anchorElRef.current = e.target;
    togglePopover();
  }, [anchorElRef, togglePopover]);

  const handleDelete = useCallback((e) => {
    e.stopPropagation();
    anchorElRef.current = null;
    closePopover();
    if (deletingDraft) {
      AutoSaveService.removeRecord(deletingDraft);
    }
    setAutoSaveRecords(AutoSaveService.listRecords({type: 'salesdoc'}));
  }, [anchorElRef, closePopover, deletingDraft]);

  return (autoSaveRecords.length !== 0 &&
    <ToolTip
      tip={
        <span>
          We&apos;re now saving your work as you go. You&apos;ll <br />
          find any unsaved changes to sales documents <br />
          here. We&apos;ll keep up to eight drafts, and then <br />
          remove the oldest as new ones are created. <br />
        </span>
      }
      placement={Placement.Left}
    >
      <Button
        actionPrimary
        noWrap
        text={'You have unsaved drafts'}
        menu
      >
        {autoSaveRecords.map((record) => (
          <PopupItem className={'salesboard-menu-item draft'} key={record.key} value={record.key} onClick={() => handleEditDraft(record.key)}>
            <Fields leftLabel noGap>
              {record.number &&
                <>
                  <CaptionText>Template:</CaptionText>
                  <BodyText>{`${record.typeName} #${record.number}`}</BodyText>
                </>
              }
              {!record.number &&
                <>
                  <CaptionText>Template:</CaptionText>
                  <BodyText>{`${record.typeName} | ${record.templateName}`}</BodyText>
                </>
              }
              <CaptionText>Last edited:</CaptionText>
              <BodyText>{formatDateTimeRelative(new Date(record.createdAt))}</BodyText>
              {record.customer &&
                <>
                  <CaptionText>Customer:</CaptionText>
                  <BodyText>{`${record.customer} | ${record.contact}`}</BodyText>
                </>
              }
            </Fields>
            <Button prefix={DeleteIcon} onClick={(e) => handleShowConfirmDelete(e, record.key)} />
          </PopupItem>
        ))}
        <ModelessPopover dialog anchorEl={anchorEl} open={open} placement={Placement.BottomRight} onClose={closePopover}>
          Are you sure you want to delete this draft?.<br />
          This cannot be undone.
          <Row>
            <Button navNegative onClick={closePopover}>Cancel</Button>
            <Button navPositive onClick={handleDelete}>Delete</Button>
          </Row>
        </ModelessPopover>
      </Button>
    </ToolTip>
  );
}
