import React from 'react';
import {SalesDocToolbox} from './SalesDocToolboxDrawer';
import {CompanySection, CustomerViewSection, DocumentDetailsSection, PresentationBlockSection} from './ToolboxSections';
import {usePageContext} from '../../../componentsLib/Layout';

export function PresentationToolbox() {
  const {salesDoc} = usePageContext();

  return (
    <>
      {salesDoc?.template &&
        <SalesDocToolbox heading={'SalesPresentation'} noBack>
          <CustomerViewSection />
          <CompanySection />
          <DocumentDetailsSection />
          <PresentationBlockSection
            section={'header'}
            forceShowBlock={!salesDoc.showTitleBlock && !salesDoc.showFooterBlock}
            image={salesDoc.headerImage}
            text={salesDoc.headerText}
            showBlock={salesDoc.showHeaderBlock}
            setShowBlock={salesDoc.setShowHeaderBlock}
            onImageChange={salesDoc.setHeaderBlockImage}
            onTextChange={salesDoc.setHeaderBlockText}
            resetValue={salesDoc.template.headerText}
          />
          <PresentationBlockSection
            section={'title'}
            forceShowBlock={!salesDoc.showHeaderBlock && !salesDoc.showFooterBlock}
            image={salesDoc.titleImage}
            text={salesDoc.titleText}
            showBlock={salesDoc.showTitleBlock}
            setShowBlock={salesDoc.setShowTitleBlock}
            onImageChange={salesDoc.setTitleBlockImage}
            onTextChange={salesDoc.setTitleBlockText}
            resetValue={salesDoc.template.titleText}
          />
          <PresentationBlockSection
            section={'footer'}
            forceShowBlock={!salesDoc.showHeaderBlock && !salesDoc.showTitleBlock}
            image={salesDoc.footerImage}
            text={salesDoc.footerText}
            showBlock={salesDoc.showFooterBlock}
            setShowBlock={salesDoc.setShowFooterBlock}
            onImageChange={salesDoc.setFooterBlockImage}
            onTextChange={salesDoc.setFooterBlockText}
            resetValue={salesDoc.template.footerText}
          />
        </SalesDocToolbox>
      }
    </>
  );
}
