import {Divider, Grid, Link, Typography} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import React, {useState} from 'react';
import {get} from 'lodash';
import ExpandButton from '../../componentsOld/shared/ExpandButton';
import {JobItemTable} from '../../componentsOld/shared/Job/JobItemTable';
import JobProofsTable from '../../componentsOld/shared/Job/JobProofsTable';
import SchedulePreviewDetails from './SchedulePreviewDetails';
import {CommentsEntityViewer} from '../../componentsHoops/Comments';

const SchedulePreview = ({
    schedule,
    proofs = []
}) => {
    const [expandedSections, setExpandedSections] = useState({
        items: false,
        proofs: false,
        comments: false
    });

    const pdfLinkJobSheet = `${process.env.REACT_APP_BASE_URL}/jobs/${get(schedule, 'job._id')}/pdf`;
    const pdfLinkProofSheet = `${pdfLinkJobSheet}?withProofs=1`;

    const handleExpand = (name, state) => {
        setExpandedSections((prev) => ({
            ...prev,
            [name]: state
        }));
    };

    if (!schedule || !schedule.job) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SchedulePreviewDetails schedule={schedule} />
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <Grid container alignItems='center' justifyContent='space-between' spacing={3}>
                    <Grid item>
                        <Typography variant='subtitle1'>
                            Items / Products / Decorations
                        </Typography>
                        </Grid>
                    <Grid item>
                        <ExpandButton onChange={(state) => handleExpand('items', state)} isExpanded={expandedSections.items} />
                    </Grid>
                    {expandedSections.items ? <Grid item xs={12}>
                        {schedule.job.items.map((jobItem) => <JobItemTable value={schedule.jobChildMappingIds} key={jobItem._id} jobItem={jobItem} disabled={true} />)}
                    </Grid> : null}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid item>
                        <Typography variant='subtitle1'>Proofs</Typography>
                    </Grid>
                    <Grid item>
                        <ExpandButton onChange={(state) => handleExpand('proofs', state)} isExpanded={expandedSections.proofs} />
                    </Grid>
                    {expandedSections.proofs ? <Grid item xs={12}>
                        <JobProofsTable proofs={proofs} />
                        <br />
                        <br />
                        <Grid container spacing={3}>
                            <Grid item>
                                <Link
                                    underline={'none'}
                                    color={'primary'}
                                    target='_blank'
                                    rel='noopener'
                                    href={pdfLinkJobSheet}
                                >
                                    <Grid container direction='row' alignItems='center'>
                                        View Job Sheet&nbsp;<LaunchIcon size='small' style={{fontSize: '18px'}} />
                                    </Grid>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    underline={'none'}
                                    color={'primary'}
                                    target='_blank'
                                    rel='noopener'
                                    href={pdfLinkProofSheet}
                                >
                                    <Grid container direction='row' alignItems='center'>
                                        View Proof Sheet&nbsp;<LaunchIcon size='small' style={{fontSize: '18px'}} />
                                    </Grid>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid> : null}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid item>
                        <Typography variant='subtitle1'>
                            Comments
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ExpandButton onChange={(state) => handleExpand('comments', state)} isExpanded={expandedSections.comments} />
                    </Grid>
                    {expandedSections.comments ? <Grid item xs={12}>
                        <CommentsEntityViewer entity={'job'} entityId={schedule.job._id} entityNumber={schedule.job.number}/>
                    </Grid> : null}
                </Grid>
            </Grid>
        </Grid >
    );
};

export default SchedulePreview;
