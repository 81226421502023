import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import {colors} from '../../../theme';
import {Fields} from '../../Layout';
import {asCurrencyStringCommaSeparated, formatDateShort} from '../../../utils';
import {CellContainer} from './CellContainer';
import {useCallback, useState} from 'react';
import {Chip} from '../../Chips';
import {InvoiceService} from '../../../servicesOld/InvoiceService';
import {awaitTimeout} from '../../../utils/awaitTimeout';
import {useFetchJob} from '../../../hooks/api';
import {ToolTip} from '../../Basic';

export const ACCOUNTING_STATUS = {
  CREATING: {
    color: colors.palette.orange,
    label: 'Sending to Accounts',
    message: 'Your invoice data is being sent to your Accounting software.',
  },
  OVERDUE: {
    color: colors.palette.red,
    label: 'Overdue'
  },
  ERROR: {
    color: colors.palette.red,
    label: 'Error Sending to Accounts'
  },
  PAID_IN_FULL: {
    color: colors.palette.green,
    label: 'Paid'
  },
  PARTIALLY_PAID: {
    color: colors.palette.purple,
    label: 'Partially Paid'
  },
  CURRENT: {
    color: colors.palette.blue,
    label: 'Waiting Payment'
  }
};

export function AccountingStatusCell({invoice, colDef, id}) {
  const info = ACCOUNTING_STATUS[invoice?.status] ?? ACCOUNTING_STATUS.ERROR;

  return ((invoice ?? null) &&
    <CellContainer>
      {!invoice.errorMessage &&
        <ToolTip tip={
          <Fields leftLabel noRowGap>
            <div>Invoice Number</div>
            <div>{invoice.invoiceNumber ?? '-'}</div>
            <div>Due Date</div>
            <div>{formatDateShort(invoice.dueDate)}</div>
            <div>Total Amount</div>
            <div className={'currency-prefix'}>{asCurrencyStringCommaSeparated(invoice.totalAmount)}</div>
            <div>Amount Owing</div>
            <div className={'currency-prefix'}>{asCurrencyStringCommaSeparated(invoice.balance)}</div>
          </Fields>
        }>
          <Chip text={info.label} color={info.color} icon={InfoIcon}/>
        </ToolTip>
      }
      {invoice.errorMessage &&
        <ErrorChip invoice={invoice} updateField={colDef.context.updateField} jobId={id}/>
      }
    </CellContainer>
  );
}

function ErrorChip({invoice, updateField, jobId}) {
  const {fetch: fetchJobApi} = useFetchJob();
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setLoading(true);
    try {
      let newInvoice = await InvoiceService.getInvoice(invoice._id);
      await new InvoiceService(newInvoice).save();
      await awaitTimeout({seconds: 10});
      const job = await fetchJobApi(jobId);
      if (job?.digest.invoice) {
        updateField({id: jobId, field: 'digest.invoice', value: job.digest.invoice});
      }
    } finally {
      setLoading(false);
    }
  }, [fetchJobApi, invoice._id, jobId, updateField]);

  return (
    <>
      <ToolTip tip={!loading &&
        <div>
          An error occurred syncing the invoice. Click to retry.
          {invoice.errorMessage &&
            <>
              <hr />
              <div>{invoice.errorMessage}</div>
            </>
          }
        </div>
      }>
        {!loading &&
          <Chip text={'Error Syncing'} color={colors.palette.red} icon={ErrorIcon} onClick={handleClick} button/>
        }
        {loading &&
          <Chip text={'Syncing'} color={colors.palette.red} loading={true}/>
        }
      </ToolTip>
    </>
  );
}
