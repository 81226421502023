import React, {useCallback, useEffect, useState} from 'react';
import {Button, ImageThumbnail} from '../../componentsLib/Basic';
import {
  AutoCompleteContainer,
  AutoCompleteEndButtons,
  AutoCompleteList,
  AutoCompleteListItem,
  AutoCompletePopover,
  AutoCompleteSearchBar,
  AutoCompleteTextInput
} from '../../componentsLib/Popovers';
import {HoopsPropTypes} from '../../componentsLib';
import {useListProducts} from '../../hooks/api';
import {Typography} from '@material-ui/core';
import {Column, Row} from '../../componentsLib/Layout';

export function ProductAutoComplete({className, disabled, product: initProduct, search: initSearch, onChange, onAddNewProduct, onSearchChange, placeholder, label}) {
  const [currentProduct, setProduct] = useState(initProduct);
  const [search, setSearch] = useState(initSearch);
  const {data: {products}, isLoading} = useListProducts({search: search ?? ''});

  useEffect(() => {
    setProduct(initProduct);
  }, [initProduct]);

  useEffect(() => {
    setSearch(initSearch);
  }, [initSearch]);

  const handleChange = useCallback((_id) => {
    const newProduct = products.find((prod) => prod._id === _id);
    if (newProduct) {
      setProduct(newProduct);
      if (newProduct !== currentProduct) {
        onChange?.(newProduct);
      }
    }
  }, [currentProduct, onChange, products]);

  const handleSearchChange = useCallback((e) => {
    const newSearch = e.target.value;
    if (newSearch !== search) {
      onSearchChange?.(newSearch);
      setSearch(newSearch);
    }
  }, [onSearchChange, search]);

  return (
    <AutoCompleteContainer
      className={[className, 'hoops-catalog-autocomplete']}
      disabled={disabled}
      label={label}
      value={currentProduct?._id}
      search={search}
      onChange={handleChange}
      onSearchChange={handleSearchChange}
      placeholder={placeholder}
      loading={isLoading}
    >
      <AutoCompleteTextInput value={currentProduct && `${currentProduct.code} - ${currentProduct.title}`}/>
      <AutoCompletePopover className={'hoops-catalog-autocomplete-popover'}>
        <AutoCompleteSearchBar search={search}/>
        <AutoCompleteList>
          {products?.map((product) => (
            <AutoCompleteListItem key={product._id} value={product._id}>
              <Row gap>
                <ImageThumbnail imageUrl={product.primaryImage} size={40} hoverZoom/>
                <Column className={'product-details'}>
                  <Typography className={'title'}>
                    {product.title}
                  </Typography>
                  <Typography className={'details'}>
                    <span>Code: </span>{product.code}
                    <span> | Vendor: </span>{product.vendorName}
                    <span> | Category: </span>{product.category}
                    <span> | MOQ: </span>{product.moq}
                  </Typography>
                </Column>
              </Row>
            </AutoCompleteListItem>
          ))}
        </AutoCompleteList>
        {onAddNewProduct &&
          <AutoCompleteEndButtons>
            <Button navPrimary onClick={onAddNewProduct}>Add New Product</Button>
          </AutoCompleteEndButtons>
        }
      </AutoCompletePopover>
    </AutoCompleteContainer>
  );
}

ProductAutoComplete.propTypes = {
  className: HoopsPropTypes.className,
  disabled: HoopsPropTypes.bool,
  label: HoopsPropTypes.string,
  search: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  product: HoopsPropTypes.object,
  onSearchChange: HoopsPropTypes.func,
  onAddNewProduct: HoopsPropTypes.func,
  placeholder: HoopsPropTypes.string,
};
