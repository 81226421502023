import {Button, Container, Grid, makeStyles, Tab, Tabs, Typography} from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Switch, useHistory, useLocation, useParams} from 'react-router';
import {getTask, setModal, updateTask} from '../../actions/tasks';
import EntityViewHeader from '../../componentsOld/Entity/EntityViewHeader';
import StatusChanger from '../../componentsOld/Statuses/StatusChanger';
import {colors} from '../../ui/theme';
import Files from '../file/Files';
import TaskPageDetails from './TaskPageDetails';
import {DueDate, isOverdue} from './utils';
import {CommentsEntityViewer} from '../../componentsHoops/Comments';

const useStyles = makeStyles((theme) => ({
  tabsOuterContainer: {borderBottom: `1px solid ${theme.palette.secondary.main}`},
  tabs: {marginTop: '16px'},
  tabLink: {textDecoration: 'none'},
  activeTabContainer: {padding: `${theme.spacing(3)}px 0px`},
  bottomBuffer: {marginBottom: theme.spacing(3)}
}));

export const TaskPage = () => {
  const {entityId} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const entitySelector = (state) => state.taskReducer.active;
  const entity = useSelector(entitySelector);
  const classes = useStyles();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('details');

  const handleEditAction = () => {
    dispatch(getTask(entity._id));
    dispatch(setModal(true));
  };

  const handleUpdate = (task) => {
    dispatch(updateTask(task._id, task));
  };

  const handleTabChange = (event, newValue) => {
    history.push(newValue);
  };

  useEffect(() => {
    dispatch(getTask(entityId));
  }, [dispatch, entityId]);

  useEffect(() => {
    const tab = location.pathname.split('/').pop();
    setActiveTab(tab);
  }, [location]);

  return (
      <Container maxWidth='lg'>

        <EntityViewHeader
          titleComponent={
            <Grid container spacing={1} direction='column'>
              <Grid item>
                <Typography variant='h5' color='primary' display='inline'>
                  Task
                </Typography>&nbsp;
                <Typography variant={'h5'} display='inline'>
                  {entity.title}
                </Typography>
              </Grid>
              <Grid item>
                    <Grid container spacing={1} alignItems={'center'}>
                      <Grid item>
                        <AccessTime fontSize={'small'} style={{color: isOverdue(entity.dueAt) ? colors.red : null}} />
                      </Grid>
                      <Grid item>
                        <Typography color={'primary'} variant={'body1'} style={{color: isOverdue(entity.dueAt) ? colors.red : null}}>
                          <DueDate task={entity}/>
                        </Typography>
                      </Grid>
                    </Grid>
              </Grid>
            </Grid>
          }

          actionsComponent={
            <>
              <StatusChanger entityType='task' entityMapping='status' value={entity.status} onChange={(newStatus) => handleUpdate({...entity, status: newStatus.value})} />
              &nbsp;&nbsp;&nbsp;
              <Button variant='outlined' color='primary' onClick={handleEditAction}>EDIT</Button>
            </>

          }
        />

        {/* TABS */}
        <Grid container className={classes.tabsOuterContainer}>
          <Tabs
            className={classes.tabs}
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
          >
            <Tab label='Detail' value='details' />
            <Tab label='Comments' value='comments' />
            <Tab label='Files' value='files' />
          </Tabs>
        </Grid>

        {/* Content */}
        <Grid container className={classes.activeTabContainer}>
          <Grid item xs={true}>
            <Switch>
              <Route path={['/tasks/:entityId/details', '/app/tasks/:entityId/details']} exact component={() => <TaskPageDetails task={entity} />} />
              <Route path={['/tasks/:entityId/comments', '/app/tasks/:entityId/comments']} exact component={() =>
                <CommentsEntityViewer entity={'task'} entityId={entity._id} entityTitle={entity.title} />
              } />
              <Route path={['/tasks/:entityId/files', '/app/tasks/:entityId/files']} exact component={() => <Files getEntityIds={[entity._id]} setEntityIds={[entity._id]} canCreateTask={false} isWorkable={true} showCustomerBtn={entity.customerId} showUploadFileBtn={true} entityTitle='Task'/>} />
            </Switch>
          </Grid>
        </Grid>
      </Container>
  );
};

export default TaskPage;
