import React, {forwardRef} from 'react';
import {colors, registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {BodyText} from '../Text';
import classNames from 'classnames';
import {BusySpinner, Decorator} from '../Basic';

registerGlobalStyle('.indicator-chip', (theme) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  '&.chip-pad': {padding: theme.spacing(0, 2)},
  '&.button-chip': {cursor: 'pointer'},
  backgroundColor: 'var(--chip-color)',
  borderRadius: theme.spacing(1.5),
  height: theme.spacing(3),
  overflow: 'hidden',
  '.text': {
    fontSize: '.8125rem',
    color: theme.colors.text.contrast,
    whiteSpace: 'nowrap',
    letterSpacing: '-0.25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&>span': {
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
    left: theme.spacing(-.625),
    paddingTop: theme.spacing(.375),
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: '.625rem',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    color: theme.colors.text.contrast,
    backgroundColor: theme.colors.alpha.dark,
    '&:empty': {backgroundColor: theme.colors.text.contrast},
  },
  '.material-symbols-outlined': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: '50%',
    fontSize: '1.25rem',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    color: theme.colors.text.contrast,
    '&:first-child': {left: theme.spacing(-.625)},
    '&:last-child': {left: theme.spacing(.625)},
  },
  '&>svg': {
    position: 'relative',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    color: theme.colors.text.contrast,
    '&:first-child': {left: theme.spacing(-.625)},
    '&:last-child': {left: theme.spacing(.625)},
  },
  '.busy-spinner': {
    position: 'relative',
    left: theme.spacing(-.625),
    width: theme.spacing(2.25),
    height: theme.spacing(2),
    '*': {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    svg: {color: theme.colors.text.contrast,}
  },
}));

export const Chip = forwardRef(
function Chip({className, button, color = colors.palette.green, empty, icon, loading, name, pad, prefix, suffix, text, onClick, children, ...props}, ref) {
  prefix = icon ?? prefix;

  return (
    <div
      ref={ref}
      className={classNames([className, 'indicator-chip chip', pad && 'chip-pad', button && 'button-chip'])}
      style={{'--chip-color': color}}
      onClick={onClick}
      name={name}
      {...props}
    >
      {loading &&
        <BusySpinner/>
      }
      {prefix && !loading &&
        <Decorator>{prefix}</Decorator>
      }
      {empty &&
        <span/>
      }
      {text &&
        <BodyText text={text}/>
      }
      {children}
      {suffix &&
        <Decorator>{suffix}</Decorator>
      }
    </div>
  );
});

Chip.propTypes = {
  className: HoopsPropTypes.className,
  button: HoopsPropTypes.bool,
  color: HoopsPropTypes.string,
  empty: HoopsPropTypes.bool,
  icon: HoopsPropTypes.decorator,
  loading: HoopsPropTypes.bool,
  name: HoopsPropTypes.string,
  pad: HoopsPropTypes.bool,
  prefix: HoopsPropTypes.decorator,
  suffix: HoopsPropTypes.decorator,
  text: HoopsPropTypes.string,
  onClick: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
