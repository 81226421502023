import React from 'react';
import {BrowserRouter, withRouter} from 'react-router-dom';
import {ErrorBoundaryRoute} from '../../componentsHoops/Errors';
import {SalesDocGuestPage} from '../../pages/SalesDoc';

export const HostedRoutes = withRouter(function HostedRoutes() {
  return (
    <>
      <BrowserRouter basename={'/'}>
        <ErrorBoundaryRoute path='/*' component={SalesDocGuestPage} />
      </BrowserRouter>
    </>
  );
});
