import {SalesDocSummary} from './SalesDocSummary';
import {SalesDocItem} from './SalesDoc';
import {asCurrencyRateRounded, asCurrencyRateString, asCurrencyRounded, asNumber, asRounded, asRoundedString} from '../../../utils';

export class PriceCalculator {
  items;
  summary;
  salesDoc;
  isPresentation;

  calculate(salesDoc) {
    this.salesDoc = salesDoc;
    this.isPresentation = salesDoc.isPresentation();

    // Make a copy of the items and the items array, then the calculation functions can safely mutate the items
    this.items = salesDoc.items.map((item) => new SalesDocItem(item));

    this.summary = new SalesDocSummary();
    this.summary.calculateQuantities(this.items);

    // Now, recalculate the pricing for non-variants
    this.items.forEach((item) => {
      if (item.type !== SalesDocItem.Type.VARIANT && !item.isPlaceholder()) {
        if (item.type === SalesDocItem.Type.DECORATION) {
          this.#calculateDecorationPricing(item);
        } else if (item.percentage != null) {
          // This is a percentage based additional cost, mark it as 0, so we can calculate everything else first
          item.unitPrice = 0;
        } else if (item.isAdditionalCost() && !item.isSurchargeOrDiscount()) {
          this.#calculateAdditionalCostPricing(item);
        }
      }
    });

    // Calculate the buy and sell price for variants
    this.items.forEach((item) => {
      if (item.type === SalesDocItem.Type.VARIANT) {
        this.#calculateVariantBuySellPricing(item);
        delete item.averageSellPrice;
      }
    });

    // Calculate the average sell price for any variants using averaging
    this.items.forEach((item) => {
      if (item.type === SalesDocItem.Type.VARIANT && item.priceMode === SalesDocItem.PriceMode.AVERAGE && item.averageSellPrice == null) {
        this.#calculateVariantAverageSellPrice(item);
      }
    });

    // Now, recalculate the pricing for variants - have to do this after the others because they may have rollups
    this.items.forEach((item) => {
      if (item.type === SalesDocItem.Type.VARIANT) {
        this.#calculateVariantUnitPricing(item);
      }
    });

    if (this.items.some((item) => item.isSurchargeOrDiscount())) {
      // To calculate the percentages we have to generate a summary to get the totals without the discounts and surcharges
      const summary = new SalesDocSummary(this.items.filter((item) => !item.isSurchargeOrDiscount()));

      // Then we can calculate the percentage based costs
      let hasRollups = false;
      this.items.forEach((item) => {
        if (item.isSurchargeOrDiscount()) {
          this.#calculatePercentageBasedAdditionalCost(item, summary);
          if (item.rollupSellPrice) {
            hasRollups = true;
          }
        }
      });

      // Then we can rollup the additional costs
      if (hasRollups) {
        this.items.forEach((item) => {
          if (item.type === SalesDocItem.Type.VARIANT) {
            this.#calculateVariantUnitPricing(item);
          }
        });
      }
    }

    this.summary.calculateSummary(this.items);

    return {items: this.items, summary: this.summary};
  }

  #calculateVariantBuySellPricing(item) {
    // Determine the quantity for price break purposes. By default, we use the total number of the product
    // across the entire sales doc, but it can also be linked to the group or to nothing.
    if (!item.productId || item.linkedTo === SalesDocItem.LinkedTo.NONE) {
      item.linkedQuantity = item.quantity;
    } else if (item.linkedTo === SalesDocItem.LinkedTo.GROUP) {
      item.linkedQuantity = this.summary.quantities[item.groupId][item.productId];
    } else {
      item.linkedQuantity = this.summary.quantities[item.productId];
    }

    // Determine the default buy price. It's from the correct break, or an average of all matching price breaks.
    let defaultBuyPrice;
    if (item.product) {
      const variants = this.isPresentation
        ? item.product.variants?.filter((v) => (!item.colors?.length || item.colors.includes(v.color)) && (!item.sizes?.length || item.sizes.includes(v.size)))
        : item.product.variants?.filter((v) => (item.color == null || item.color === v.color) && (item.size == null || item.size === v.size));
      const calc = variants.filter(Boolean).reduce((acc, v) => {
        const pbs = v.priceBreaks ?? item.product.defaultPriceBreaks;
        if (pbs) {
          const price = pbs.findLast((pb) => item.linkedQuantity >= pb.quantity)?.price ?? pbs[0]?.price ?? 0;
          return {
            minQuantity: acc.minQuantity == null ? pbs[0]?.quantity ?? 1 : Math.min(acc.minQuantity, pbs[0]?.quantity ?? 1),
            price: acc.price + price,
            minPrice: acc.minPrice == null ? price : Math.min(acc.minPrice, price),
            maxPrice: acc.maxPrice == null ? price : Math.max(acc.maxPrice, price),
            count: acc.count + 1,
          };
        }
        return acc;
      }, {minQuantity: null, price: 0, count: 0, minPrice: null, maxPrice: null});
      if (calc.count > 0) {
        defaultBuyPrice = asRounded(calc.price / calc.count, this.salesDoc.getRounding());
        item.minQuantity = calc.minQuantity;
        item.isAveragedPrice = calc.minPrice !== calc.maxPrice;
      } else if (item.product.defaultPriceBreaks) {
        defaultBuyPrice = item.product.defaultPriceBreaks.findLast((pb) => item.linkedQuantity >= pb.quantity)?.price ?? item.product.defaultPriceBreaks[0]?.price ?? 0;
        item.minQuantity = item.product.defaultPriceBreaks[0]?.quantity;
        item.isAveragedPrice = false;
      }
    }
    this.#updateBuySellPrice(item, defaultBuyPrice, item.linkedQuantity);
  }

  #calculateVariantAverageSellPrice({groupId, productId}) {
    const sameItems = this.items.filter((item) => item.priceMode === SalesDocItem.PriceMode.AVERAGE && item.groupId === groupId && item.productId === productId);
    let count = 0;
    let price = 0;
    sameItems.forEach((sameItem) => {
      count += sameItem.quantity;
      price += sameItem.quantity * sameItem.sellPrice;
    });
    const averageSellPrice = asRounded(price / (count || 1), this.salesDoc.getRounding());
    sameItems.forEach((sameItem) => {
      sameItem.averageSellPrice = averageSellPrice;
    });
  }

  #calculateVariantUnitPricing(item) {
    let sellPrice = item.averageSellPrice ?? item.sellPrice;

    // Calculate the unit price based on the sell price, plus any addons that are rolled up into it
    const [rolledUpCost, rolledUpUnitPrice] = this.items
      .filter((that) => that.groupId === item.groupId && (that.isDecoration() || that.isAdditionalCost()))
      .reduce((rollups, that) => {
        // Check if the price of that item should be rolled up into this item
        let rollup = that.rollupSellPrice;
        if (rollup) {
          if (this.isPresentation && that.quantity !== item.quantity) {
            rollup = false;
          } else if (that.isDecoration() || that.isDecorationSetupCost()) {
            if (that.productId != null && that.productId !== item.productId) {
              rollup = false;
            }
          } else if (that.applyAtCheckout) {
            rollup = false;
          }
        }
        if (rollup) {
          rollups[0] += (that.buyPricePerUnit ?? that.buyPrice) + that.additionalCost;
          rollups[1] += that.unitPrice;
        }
        return rollups;
      }, [0, 0]);

    item.rolledUpCost = rolledUpCost;
    item.rolledUpUnitPrice = rolledUpUnitPrice;

    this.#updateUnitPriceAndSubTotal(item, sellPrice + rolledUpUnitPrice);
  }

  #calculateDecorationPricing(item) {
    // Decorations don't have their own quantity - it comes from the group or product, unless there is no variant, or it's a presentation
    const prevQuantity = item.quantity;
    if (!this.isPresentation) {
      if (item.productId) {
        item.quantity = this.summary.quantities[item.groupId][item.productId] ?? 0;
      } else if (this.summary.quantities[item.groupId].quantity > 0) {
        item.quantity = this.summary.quantities[item.groupId].quantity;
      } else {
        if (item.quantity == null) {
          item.quantity = 1;
        }
      }
    }
    if (prevQuantity !== item.quantity && !item.markupOverride) {
      item.markup = null;
    }
    item.linkedQuantity = item.quantity;

    // Determine the default price from the price breaks
    let defaultBuyPrice;
    const priceBreaks = item.priceBreaks ?? item.decoration?.priceBreaks;
    if (priceBreaks) {
      defaultBuyPrice = asCurrencyRateRounded(priceBreaks.findLast((pb) => item.quantity >= pb.quantity)?.price ?? priceBreaks[0]?.price ?? 0);
    }

    this.#updateBuySellPrice(item, defaultBuyPrice, item.quantity);

    this.#updateUnitPriceAndSubTotal(item, item.sellPrice);
  }

  #calculateAdditionalCostPricing(item) {
    let rollupSellPrice = item.rollupSellPrice;
    if (!this.salesDoc.groupHasProductVariants(item.groupId)) {
      rollupSellPrice = false;
    }

    // For additional costs the unit price depends on whether the price is rolled up. If it is not rolled up
    // the quantity is 1 and the unit price is the price; If it is rolled up, the quantity is the quantity
    // of all the variants in the group (or the quantity of the linked/unlinked products if it is a decoration
    // additional cost), and the unit price is divided by that; If it is in a presentation, the quantity of
    // the column is used.
    let quantity;
    if (!rollupSellPrice) {
      quantity = 1;
    } else if (this.isPresentation) {
      quantity = item.quantity;
    } else if (item.productId) {
      quantity = this.summary.quantities[item.groupId][item.productId] ?? 0;
    } else if (item.isDecorationSetupCost()) {
      quantity = this.summary.quantities[item.groupId].unlinked;
    } else {
      quantity = this.summary.getGroupQuantity(item.groupId);
    }
    if (quantity < 1 || quantity == null) {
      quantity = 1;
    }

    // Determine the default price from the decoration setup cost or the price breaks
    let defaultBuyPrice;
    if (item.decoration) {
      defaultBuyPrice = asCurrencyRateRounded(item.decoration.setupPrice);
    } else if (item.priceBreaks) {
      defaultBuyPrice = asCurrencyRateRounded(item.priceBreaks.findLast((pb) => quantity >= pb.quantity)?.price ?? 0);
    }

    this.#updateBuySellPrice(item, defaultBuyPrice, 1);
    item.totalCost = item.buyPrice;

    item.buyPricePerUnit = asCurrencyRateRounded(item.buyPrice / quantity);
    const unitPrice = asRounded(item.sellPrice / quantity, this.salesDoc.getRounding());

    this.#updateUnitPriceAndSubTotal(item, unitPrice, quantity);
  }

  #calculatePercentageBasedAdditionalCost(item, summary) {
    const groupOnly = this.items.some(({groupId, itemId, type, percentage}) =>
      itemId !== item.itemId && groupId === item.groupId && (type !== SalesDocItem.Type.ADDITIONAL_COST || percentage != null));
    const basis = groupOnly ? summary.getGroupSummary(item.groupId).totalRevenue : summary.getDocumentSummary().totalRevenue;

    let rollupSellPrice = item.rollupSellPrice;
    if (!groupOnly || !this.salesDoc.groupHasProductVariants(item.groupId)) {
      rollupSellPrice = false;
    }

    let quantity;
    if (!rollupSellPrice) {
      quantity = 1;
    } else if (this.isPresentation) {
      quantity = item.quantity;
    } else {
      quantity = summary.getGroupQuantity(item.groupId);
    }
    if (quantity < 1 || quantity == null) {
      quantity = 1;
    }

    item.sellPrice = asRounded(basis * item.percentage / 100, this.salesDoc.getRounding());
    item.buyPrice = item.percentage > 0 ? item.sellPrice : 0;
    item.markup = 0;
    item.totalCost = item.buyPrice;
    item.buyPricePerUnit = asCurrencyRateRounded(item.buyPrice / quantity);
    item.unitPrice = asRounded(item.sellPrice / quantity, this.salesDoc.getRounding());
    item.subTotal = asCurrencyRounded(item.unitPrice * quantity);
  }

  #updateBuySellPrice(item, defaultBuyPrice, effectiveQuantity) {
    // When converting from an old quote, it is unknown whether the buy price has been overridden, so we
    // determine that now. Basically, if the buyPrice is different to the price break, it's been overridden.
    if (item.buyPriceOverride == null) {
      item.buyPriceOverride = defaultBuyPrice != null && asCurrencyRateString(defaultBuyPrice) !== asCurrencyRateString(item.buyPrice);
    }

    // Store the new buyPrice, unless it is overridden
    if ((!item.buyPriceOverride && defaultBuyPrice) || item.buyPrice == null) {
      if (!item.markupOverride && asCurrencyRateString(defaultBuyPrice) !== asCurrencyRateString(item.buyPrice)) {
        item.markup = null;
      }
      item.buyPrice = defaultBuyPrice ?? 0;
    }
    item.totalCost = asCurrencyRounded((item.buyPrice + item.additionalCost) * item.quantity);

    // Find the correct default markup
    if (item.markup == null) {
      const markup = this.salesDoc.markups?.findMarkupValue({
        type: SalesDocItem.MarkupTypes[item.type],
        vendorId: item.vendorId,
        brand: item.product?.brand ?? null,
        category: item.product?.category ?? item.decoration?.category ?? null,
        subCategory: item.product?.subCategory ?? null,
        quantity: effectiveQuantity,
        price: item.buyPrice,
      });
      if (markup != null) {
        item.markup = markup;
      } else {
        item.markup = 0;
      }
    }

    // Calculate the new sell price: basically, buyPrice + additionalCost with the markup on top
    const sellPrice = asRounded((asNumber(item.buyPrice) + asNumber(item.additionalCost)) * (1 + asNumber(item.markup) / 100.0), this.salesDoc.getRounding());
    if (asRoundedString(sellPrice, this.salesDoc.getRounding()) !== asRoundedString(item.sellPrice, this.salesDoc.getRounding())) {
      if (item.sellPrice != null) {
        item.unitPriceOverride = false;
      }
      item.sellPrice = sellPrice;
    }
  }

  #updateUnitPriceAndSubTotal(item, unitPrice, effectiveQuantity) {
    unitPrice = asRounded(unitPrice, this.salesDoc.getRounding());

    if (item.unitPriceCalc == null) {
      // If the calculated unit price has not been set, we will store it now (it's not part of the model)
      item.unitPriceCalc = unitPrice;
      if (item.unitPriceOverride == null) {
        item.unitPriceOverride = asRoundedString(unitPrice, this.salesDoc.getRounding()) !== asRoundedString(item.unitPrice, this.salesDoc.getRounding());
      }
    } else if (asRoundedString(unitPrice, this.salesDoc.getRounding()) !== asRoundedString(item.unitPriceCalc, this.salesDoc.getRounding())) {
      // If the calculated unit price has changed, we will store it, and we disable the unit price override
      item.unitPriceOverride = false;
      item.unitPriceCalc = unitPrice;
    }

    // If the unit price is not being overridden or is unset and is not locked we will use the new value
    if ((!item.unitPriceLocked && !item.unitPriceOverride) || item.unitPrice == null) {
      item.unitPrice = unitPrice;
    }
    item.subTotal = asCurrencyRounded(item.unitPrice * (effectiveQuantity ?? item.quantity));
  }
}
