import {AccessTime} from '@mui/icons-material';
import {Grid, Skeleton, Typography} from '@mui/material';
import * as React from 'react';
import theme from '../../../ui/theme';
import {formatDateLong} from '../../../utils';
import {JobActionButton} from './JobActionButton';
import {JobsPageActionMenu} from './JobPageActionMenu';

export const JobHeading = ({
  job = null,
  updateJobBoard,
}) => {
  const customer = job?.customer;
  const contact = job?.contact;
  const customerName = `${customer?.name || ''} ${customer?.deletedAt ? '(Deleted)' : ''}`;
  const deadlineAt = new Date(job?.deadlineAt);

  return (
    <>

      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item xs={12}>

          <Grid container spacing={2} justifyContent='space-between'>
            <Grid item xs={8}>

              <Grid container spacing={1}>
                <Grid item data-intercom-target={'job-number'}>
                  <Typography variant='h5' color='primary'>
                    {
                      !job
                        ? <Skeleton sx={{width: 150}} />
                        : `Job ${job?.number}`
                    }</Typography>
                </Grid>
                <Grid item data-intercom-target={'job-title'}>
                  <Typography variant={'h5'}>
                    {
                      !job
                        ? <Skeleton sx={{width: 350}} />
                        : job?.title
                    }
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems={'center'}>
                <Grid item data-intercom-target={'job-customer'}>
                  <Typography variant={'body1'} color='primary'>
                    {
                      !job
                        ? <Skeleton sx={{width: 250}} />
                        : `${customerName || ''} ${contact?.fullName ? ' | ' + contact?.fullName : ''}`
                    }
                  </Typography>
                </Grid>
                {job?.deadlineAt &&
                  <>
                    <Grid item sx={{display: 'flex'}}>
                      <AccessTime fontSize={'small'} sx={{color: theme.colors.red, mr: 1}} />
                      <Typography color={'primary'} variant={'body1'} noWrap sx={{color: theme.colors.red}}>
                        Deadline: {formatDateLong(deadlineAt)}
                      </Typography>
                    </Grid>
                  </>
                }

                {job?.jobOwner &&
                  <Grid item xs={12}>
                    <Typography variant={'body1'}>
                      {
                        !job
                          ? <Skeleton sx={{width: 200}} />
                          : `Job Owner: ${job?.jobOwner?.fullName}`
                      }
                    </Typography>
                  </Grid>
                }
              </Grid>

            </Grid>
            <Grid item>
              {
                (!job)
                  ? <Skeleton sx={{width: 116, height: 52}} />
                  : <JobActionButton job={job} MenuComponent={JobsPageActionMenu} updateData={updateJobBoard} />
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br/>
    </>
  );
};
