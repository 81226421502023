import {CustomerService} from '../../servicesOld/CustomerService';
import {snackSuccess, snackError} from './snackbar';
import {hoopsQuerySetRefetch} from '../hoopsQuery';

export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_FORM_CUSTOMER = 'SET_FORM_CUSTOMER';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';

export const SET_CUSTOMERS_LOADING = 'SET_CUSTOMERS_LOADING';

function setCustomers(payload) {
  return {
    type: SET_CUSTOMERS,
    payload,
  };
}

export function setCustomer(payload) {
  return {
    type: SET_CUSTOMER,
    payload,
  };
}

export function clearCustomer(payload) {
  return {
    type: CLEAR_CUSTOMER,
    payload,
  };
}

export function getCustomers(payload = {}, cb) {
  return function (dispatch) {
    dispatch({
      type: SET_CUSTOMERS_LOADING,
      payload: true,
    });
    return CustomerService.getCustomers(payload)
      .then((customerPagination) => {
        dispatch(setCustomers(customerPagination.items));
        dispatch({type: SET_CUSTOMERS_LOADING, payload: false});

        if (cb) {
          cb(customerPagination);
        }
      });
  };
}

export function getCustomer(id) {
  return function (dispatch) {
    CustomerService.getCustomer(id)
      .then((customer) => {
        dispatch(setCustomer(customer));
      });
  };
}

export function setFormCustomer(id, cb) {
  return function (dispatch) {
    CustomerService.getCustomer(id)
      .then((customer) => {
        dispatch({
          type: SET_FORM_CUSTOMER,
          payload: customer
        });
        if (cb) {
          cb(null, customer);
        }
      });
  };
}

export function setFormCustomerFields(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_FORM_CUSTOMER,
      payload
    });
  };
}

export function deleteCustomer(customer) {
  return function (dispatch) {
    const customerService = new CustomerService(customer);
    return customerService.delete(customer._id)
      .then(() => {
        dispatch(getCustomers());
        dispatch(hoopsQuerySetRefetch('customer', true));
      });
  };
}

export function saveCustomer(customer, cb) {
  return function (dispatch) {
    const customerService = new CustomerService(customer);
    return customerService.save()
      .then((data) => {
        dispatch(snackSuccess('Customer Saved'));
        dispatch(getCustomers());
        dispatch(setCustomer(data));
        if (cb) {
          cb(data);
        }
      })
      .catch(() => {
        dispatch(snackError('Something went wrong saving your customer.'));
      });
  };
}
