import {Grid} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import EntityListHero from '../../../componentsOld/EntityList/EntityListHero';
import {CompanyDetails} from '../../../componentsOld/companies/CompanyDetails';
import {CompanyEntityNumbers} from '../../../componentsOld/companies/CompanyEntityNumbers';
import {CompanyFinancial} from '../../../componentsOld/companies/CompanyFinancial';
import {useSnackbar} from '../../../hooks';
import {useMutation, useQuery} from '@apollo/client';
import {parseCompany} from '../../../servicesOld/CompanyService';
import {GET_COMPANY, GQL_UPDATE_COMPANY} from '../../../queries/companies';
import {CompanyIndustryTypes} from '../../../componentsOld/companies/CompanyIndustryTypes';
import {CompanyLeadSources} from '../../../componentsOld/companies/CompanyLeadSources';
import {CompanyRoyaltySettings} from '../../../componentsOld/companies/CompanyRoyaltySettings';
import {permissionCheck, SubscriptionTypes} from '../../../componentsHoops/AccessControl';
import store from '../../../store';
import {getCompany, setCompany} from '../../../actions/action-types/company';

const styles = {'.MuiPaper-root': {height: '100%',},};

export function OrganisationSettings() {
  const [companyValues, setCompanyValues] = useState(null);
  const user = store.getState()?.authReducer?.userData;
  const dispatch = useDispatch();

  const {loading: initLoad} = useQuery(GET_COMPANY, {
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result.company) {
        setCompanyValues(result.company);
        dispatch(setCompany(result.company));
      }
    },
  });

  const [updateCompany, {loading}] = useMutation(GQL_UPDATE_COMPANY, {
    onCompleted: (result) => {
      if (result.companyUpdateById.record) {
        setCompanyValues(result.companyUpdateById.record);
        showSnackbarSuccess('Company settings saved');
        dispatch(getCompany());
      }
    },
    onError: (err) => dispatch(showSnackbarError(err.message))
  });

  const {showSnackbarError, showSnackbarSuccess} = useSnackbar();

  const saveCompany = (settings) => {
    updateCompany({variables: {_id: companyValues?._id, record: parseCompany({...companyValues, ...settings})}});
  };

  return (
    <Grid container sx={styles}>
      <EntityListHero
        title='Organisation Settings'
        subtitle='Customise &amp; manage your organisational information to match your brand.'
        helpArticleUrl='http://help.hoopscrm.com/en/articles/4636479-your-company-settings'
        videoId='6hLqa3jHTMM'
      ></EntityListHero>

      {companyValues &&
        <Grid container spacing={3} alignItems={'stretch'} justifyContent={'space-between'}>
        <Grid item xs={12}>
          <CompanyDetails company={companyValues} saveSettings={saveCompany} loading={loading} />
        </Grid>
        <Grid item xs={12}>
          <CompanyFinancial company={companyValues} saveSettings={saveCompany} loading={loading || initLoad} />
        </Grid>
        <Grid item xs={12}>
          <CompanyEntityNumbers company={companyValues} saveSettings={saveCompany} loading={loading || initLoad} />
        </Grid>
        {(permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) && user?.type === 'admin') &&
          <>
            <Grid item xs={6}>
              <CompanyIndustryTypes company={companyValues} saveSettings={saveCompany} loading={loading || initLoad} />
            </Grid>
            <Grid item xs={6}>
              <CompanyLeadSources company={companyValues} saveSettings={saveCompany} loading={loading || initLoad} />
            </Grid>
          </>
        }
        {(permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) && user?.type === 'admin') &&
          <Grid item xs={6}>
            <CompanyRoyaltySettings company={companyValues} saveSettings={saveCompany} loading={loading || initLoad} />
          </Grid>
        }
      </Grid>
      }
    </Grid>
  );
}
