import {useGridApiContext} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import React from 'react';

export const GridCsvExportButton = () => {
  const apiRef = useGridApiContext();
  return (
    <Button onClick={apiRef.current.exportDataAsCsv} startIcon={<CloudDownloadIcon/>}>
      {apiRef.current.getLocaleText('toolbarExportCSV')}
    </Button>
  );
};
