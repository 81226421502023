import {makeStyles, Dialog} from '@material-ui/core';
import {
  AssignmentTurnedIn as TasksIcon,
  Style as CatalogIcon,
  Person as CustomerIcon,
  DateRange as ScheduleIcon,
  BarChart as ReportsIcon,
  Article as SalesBoardIcon,
  ViewWeek as JobBoardIcon,
  Description as PurchaseOrdersIcon,
  HelpCenter as HelpCenterIcon
} from '@mui/icons-material';
import {Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip} from '@mui/material';
import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {PermissionBasedContent, permissionCheck, roles, SubscriptionTypes} from '../componentsHoops/AccessControl';
import {palette, colors} from '../ui/theme';
import Logo from './Logo';
import ProfileDropdown from './ProfileDropdown';
import SettingsMenu from './settings/SettingsMenu';
import Youtube from 'react-youtube';
import {HoopsButton} from './HoopsButton';
import {useLocation} from 'react-router';
import {isUsingSalesBoard} from '../pages/SalesDoc/Components';

const useStyles = makeStyles((theme) => ({
  darkTooltip: {
    backgroundColor: theme.palette.secondary.dark,
    fontSize: '1em',
    padding: '12px'
  },
  navLink: {
    textDecoration: 'none',
    outline: 'none',
    color: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '18px 26px',
    whiteSpace: 'nowrap',
    '& .MuiList-padding': {paddingTop: 4,},
    '&:before': {
      content: '\'\'',
      position: 'absolute',
      height: '100%',
      width: 0,
      backgroundColor: theme.palette.primary.dark,
      top: 0,
      left: 0,
      bottom: 0,
      transition: '0.2s width ease'
    },
    '&.active': {
      backgroundColor: theme.palette.secondary.light,
      fontWeight: 500,
      '&:before': {width: 3}
    }
  },
  helpCenterLink: {
    textDecoration: 'none',
    outline: 'none',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '18px 26px',
    whiteSpace: 'nowrap',
    '& .MuiList-padding': {paddingTop: 4,},
    '&:before': {
      content: '\'\'',
      position: 'absolute',
      height: '100%',
      width: 0,
      backgroundColor: theme.palette.primary.dark,
      top: 0,
      left: 0,
      bottom: 0,
      transition: '0.2s width ease'
    },
    '&.active': {
      backgroundColor: theme.palette.secondary.light,
      fontWeight: 500,
      '&:before': {width: 3}
    },
  },
  helpCenterIcon: {
    '& .MuiSvgIcon-root': {color: theme.palette.primary.main,},
    color: theme.palette.primary.main,
    alignItems: 'center'
  },
  listItemIcon: {
    marginTop: 4,
    marginBottom: 4,
    marginRight: 6
  },
  hoopsLogo: {
    transition: 'padding .3s',
    padding: '16px 0 16px 0',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  hoopsLogoCollapsed: {
    transition: 'padding .3s',
    padding: '0 0 4px 19px',
  },
  badgeContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    height: '20px',
    width: '90px',
    borderRadius: '10px',
    color: theme.colors.white,
    fontSize: '12px',
  },
  submenu: {
    paddingTop: 8,
    paddingBottom: 8,
    '& .MuiListItem-root': {
      paddingTop: 0,
      paddingBottom: 0
    },
    '& .MuiListItemText-root': {
      paddingTop: 8,
      paddingBottom: 8
    },
    '& .MuiListItem-gutters': {paddingRight: 0}
  },
  nested: {
    paddingLeft: 36,
    overflow: 'hidden',
    '& .MuiTypography-root': {
      marginLeft: 10,
      paddingLeft: 36,
    },
  },
  nestedListItemIcon: {
    borderLeft: '1px solid',
    borderColor: theme.colors.grey.light,
    height: '-webkit-fill-available',
    paddingRight: 30,
    position: 'absolute'
  },
  navSettings: {
    display: 'flex',
    justifyContent: 'space-between',
    direction: 'ltr',
    alignItems: 'end',
    width: 'inherit',
  },
  dialogContainer: {
    width: '854px',
    height: '480px',
    overflow: 'hidden',
    maxWidth: 'none',
  }
}));

export const AppSidenav = ({
  collapsed = false,
  navVariant = 'pernanent'
}) => {
  const location = useLocation();
  const classes = useStyles(collapsed);
  const {primary} = palette;
  const {grey} = colors;
  const {green} = colors;
  const [isDialogOpen, setIsDialogOpen] = useState();

  return (
    <>

      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid item className={(collapsed) ? classes.hoopsLogoCollapsed : classes.hoopsLogo}>
          <Logo fill={[grey.logo, primary.main, green, primary.contrastText]} collapsed={collapsed} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <ProfileDropdown collapsed={collapsed} navVariant={navVariant} />
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Grid item xs={12}>
          <List component={'nav'} >

            <ListItem data-intercom-target={'tasks-button'} style={{padding: 0}}>
              <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
                <Tooltip title='Tasks' placement='right' arrow disableHoverListener={!collapsed}>
                  <NavLink id={'nav-tasks'} to={'/tasks'} className={classes.navLink} style={{padding: collapsed ? '18px 28px 18px 20px' : '18px 26px',}}>
                    <ListItemIcon className={classes.listItemIcon}><TasksIcon /></ListItemIcon>
                    <ListItemText primary='Tasks' secondaryTypographyProps={{className: classes.badgeContainer}} />
                  </NavLink>
                </Tooltip>
              </PermissionBasedContent>
            </ListItem>

            <ListItem data-intercom-target={'catalog-button'} style={{padding: 0}}>
              <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
                <Tooltip title='Catalog' placement='right' arrow disableHoverListener={!collapsed}>
                  <NavLink id={'nav-catalog'} to={'/catalog/products'} className={classes.navLink} style={{padding: collapsed ? '18px 28px 18px 20px' : '18px 26px',}}>
                    <ListItemIcon className={classes.listItemIcon}><CatalogIcon /></ListItemIcon>
                    <ListItemText primary='Catalog' secondaryTypographyProps={{className: classes.badgeContainer}} />
                  </NavLink>
                </Tooltip>
              </PermissionBasedContent>
            </ListItem>

            <ListItem data-intercom-target={'customers-button'} style={{padding: 0}}>
              <PermissionBasedContent>
                <Tooltip title='Customers' placement='right' arrow disableHoverListener={!collapsed}>
                  <NavLink id={'nav-customers'} to={'/customers'} className={classes.navLink} style={{padding: collapsed ? '18px 28px 18px 20px' : '18px 26px',}}>
                    <ListItemIcon className={classes.listItemIcon}><CustomerIcon /></ListItemIcon>
                    <ListItemText primary='Customers' secondaryTypographyProps={{className: classes.badgeContainer}} />
                  </NavLink>
                </Tooltip>
              </PermissionBasedContent>
            </ListItem>

            <ListItem data-intercom-target={'sales-button'} style={{padding: 0}}>
              <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
                <Tooltip title='SalesBoard' placement='right' arrow disableHoverListener={!collapsed}>
                  <NavLink
                    id={'nav-salesboard'}
                    to={isUsingSalesBoard() ? '/sales' : '/quotes'}
                    className={classes.navLink}
                    style={{padding: collapsed ? '18px 28px 18px 20px' : '18px 26px',}}
                    isActive={() => location.pathname.startsWith('/sales') || location.pathname.startsWith('/quotes')}
                  >
                    <ListItemIcon className={classes.listItemIcon}><SalesBoardIcon /></ListItemIcon>
                    <ListItemText primary='SalesBoard' secondaryTypographyProps={{className: classes.badgeContainer}} />
                  </NavLink>
                </Tooltip>
              </PermissionBasedContent>
            </ListItem>

            <ListItem data-intercom-target={'jobs-button'} style={{padding: 0}}>
              <PermissionBasedContent>
                <Tooltip title='JobBoard' placement='right' arrow disableHoverListener={!collapsed}>
                  <NavLink id={'nav-jobboard'} to={'/jobs'} className={classes.navLink} style={{padding: collapsed ? '18px 28px 18px 20px' : '18px 26px',}}>
                    <ListItemIcon className={classes.listItemIcon}><JobBoardIcon /></ListItemIcon>
                    <ListItemText primary='JobBoard' secondaryTypographyProps={{className: classes.badgeContainer}} />
                  </NavLink>
                </Tooltip>
              </PermissionBasedContent>
            </ListItem>

            <ListItem data-intercom-target={'schedule-button'} style={{padding: 0}}>
              <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
                <Tooltip title='Schedule' placement='right' arrow disableHoverListener={!collapsed}>
                  <NavLink id={'nav-schedule'} to={'/schedule'} className={classes.navLink} style={{padding: collapsed ? '18px 28px 18px 20px' : '18px 26px',}}>
                    <ListItemIcon className={classes.listItemIcon}><ScheduleIcon /></ListItemIcon>
                    <ListItemText primary='Schedule' secondaryTypographyProps={{className: classes.badgeContainer}} />
                  </NavLink>
                </Tooltip>
              </PermissionBasedContent>
            </ListItem>

            <ListItem data-intercom-target={'purchase-orders-button'} style={{padding: 0}}>
              <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
                <Tooltip title='Purchase Orders' placement='right' arrow disableHoverListener={!collapsed}>
                  <NavLink id={'nav-purchase-orders'} to={'/purchase-orders'} className={classes.navLink} style={{padding: collapsed ? '18px 28px 18px 20px' : '18px 26px',}}>
                    <ListItemIcon className={classes.listItemIcon}><PurchaseOrdersIcon /></ListItemIcon>
                    <ListItemText primary='Purchase Orders' secondaryTypographyProps={{className: classes.badgeContainer}} />
                  </NavLink>
                </Tooltip>
              </PermissionBasedContent>
            </ListItem>

            <ListItem data-intercom-target={'reporting-button'} style={{padding: 0}}>
              <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]} allowedRoles={[roles.admin]}>
                <Tooltip title='Reporting' placement='right' arrow disableHoverListener={!collapsed}>
                  <NavLink id={'nav-reporting'} to={'/reporting'} className={classes.navLink} style={{padding: collapsed ? '18px 28px 18px 20px' : '18px 26px',}}>
                    <ListItemIcon className={classes.listItemIcon}><ReportsIcon /></ListItemIcon>
                    <ListItemText primary='Reporting' secondaryTypographyProps={{className: classes.badgeContainer}} />
                  </NavLink>
                </Tooltip>
              </PermissionBasedContent>
            </ListItem>

            <ListItem data-intercom-target={'settings-button'} style={{padding: 0}}>
              <PermissionBasedContent allowedRoles={[roles.admin]}>
                <SettingsMenu collapsed={collapsed} />
              </PermissionBasedContent>
            </ListItem>

          </List>
        </Grid>
        <Grid xs={12}>
          {permissionCheck({allowedSubscriptions: [SubscriptionTypes.proofs]}) &&
            <>
              <HoopsButton purpose='ok' sx={{display: 'flex', m: 'auto', mt: 3}} onClick={() => setIsDialogOpen(true)}>Learn how this works</HoopsButton>
              <Dialog open={isDialogOpen} fullScreen={false} classes={{paper: classes.dialogContainer}} onClose={() => setIsDialogOpen(false)}>
                <Youtube opts={{width: '854px', height: '480px'}} videoId='SxXssLfey2s' />
              </Dialog>
            </>
          }
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'} alignItems={'flex-end'} style={{height: '-webkit-fill-available'}}>
        <Grid item xs={12}>
          <ListItem data-intercom-target={'learning-centre-button'} style={{padding: 0}}>
            <PermissionBasedContent allowedRoles={[roles.admin]} disallowedSubscriptions={[SubscriptionTypes.proofs]}>
              <Tooltip title='Learning Centre' placement='right' arrow disableHoverListener={!collapsed}>
                <a href={'https://brad-s-school-7c6e.thinkific.com/users/sign_up'} className={classes.helpCenterLink} style={{padding: collapsed ? '18px 28px 18px 20px' : '18px 26px',}}>
                  <ListItemIcon className={classes.helpCenterIcon}><HelpCenterIcon /></ListItemIcon>
                  <ListItemText primary='Learning Centre ' secondaryTypographyProps={{className: classes.badgeContainer}} />
                </a>
              </Tooltip>
            </PermissionBasedContent>
          </ListItem>
        </Grid>
      </Grid>
    </>
  );
};

export default AppSidenav;
