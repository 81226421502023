import React, {useCallback} from 'react';
import {CommentsViewer} from './CommentsViewer';
import {useListComments, useSaveComment} from '../../hooks/api';
import {HoopsPropTypes} from '../../componentsLib';

export function CommentsEntityViewer({entity, entityId, entityNumber, entityTitle}) {
  const {data: {comments}, isLoading, refetch} = useListComments(entityId, {entity});
  const {save: saveCommentApi} = useSaveComment();

  const handleSaveComment = useCallback(async (newComment) => {
    await saveCommentApi({id: entityId, comment: newComment, entityType: entity, entityNumber, entityTitle});
    await refetch();
  }, [entity, entityId, entityNumber, entityTitle, refetch, saveCommentApi]);

  return (
    <CommentsViewer comments={comments} isLoading={isLoading} onAddComment={handleSaveComment}/>
  );
}

CommentsEntityViewer.propTypes = {
  entity: HoopsPropTypes.string,
  entityId: HoopsPropTypes.stringOrNumber,
  entityNumber: HoopsPropTypes.stringOrNumber,
  entityTitle: HoopsPropTypes.string,
};
