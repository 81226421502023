import React from 'react';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import classNames from 'classnames';
import {Row} from '../Layout';
import {ModalDialog} from './ModalDialog';
import {BusySpinner} from '../Basic';

registerGlobalStyle('.loading-dialog', (theme) => ({'.modal-dialog-inner': {padding: theme.spacing(3)},}));

export function LoadingDialog({
  className,
  onClose,
}) {
  return (open &&
    <ModalDialog
      className={classNames([className, 'loading-dialog'])}
      okText={''}
      cancelText={''}
      title={''}
      onClose={onClose}
    >
      <Row justifyCenter alignCenter className={'loading-row'}>
        <BusySpinner large center message={'Loading'} />
      </Row>
    </ModalDialog>
  );
}

LoadingDialog.propTypes = {
  className: HoopsPropTypes.className,
  onClose: HoopsPropTypes.func,
};
