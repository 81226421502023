import React from 'react';
import {BodyText} from '../Text';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.text-wysiwyg', () => ({
  'strong, b': {fontWeight: 700},
  '&.max-lines': {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 'var(--max-lines)',
    lineClamp: 'var(--max-lines)'
  }
}));

export function WysiwygText({className, maxLines = null, text}) {
  return (
    <BodyText className={[className, 'text-wysiwyg', maxLines && 'max-lines']} style={{'--max-lines': maxLines}}>
      <span dangerouslySetInnerHTML={{__html: text}}/>
    </BodyText>
  );
}

WysiwygText.propTypes = {
  className: HoopsPropTypes.className,
  text: HoopsPropTypes.string,
  maxLines: HoopsPropTypes.number,
};
