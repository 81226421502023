import {Grid, MenuItem, Typography, makeStyles} from '@material-ui/core';
import React, {useState} from 'react';
import {CURRENCIES} from '../../constants/currencies';
import {PermissionBasedContent, SubscriptionTypes} from '../../componentsHoops/AccessControl';
import EditorField from './editor';
import SettingsPaper from './SettingsPaper';
import {HoopsTextField} from '../HoopsTextField';
import HoopsSelectField from '../HoopsSelectField';

const useStyles = makeStyles((theme) => ({
  header: {marginBottom: theme.spacing(2)},
  paperPadding: {padding: theme.spacing(5)},
  subTitle: {
    color: theme.colors.grey.main,
    fontSize: '16px',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1.5)
  },
  bottomMargin: {marginBottom: theme.spacing(1.5)},
  editIcon: {
    fontSize: '30px',
    cursor: 'pointer'
  },
  editField: {paddingTop: theme.spacing(5)},
  termsContainer: {
    maxHeight: 400,
    overflowY: 'auto',
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

export const CompanyFinancial = ({
  company,
  loading,
  saveSettings = () => null
}) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [companyUpdate, setCompanyUpdate] = useState(company);

  const toggleEdit = () => {
    setEditing((prev) => !prev);
  };

  const handleCompanyEdit = (params) => {
    setCompanyUpdate((prev) => ({...prev, ...params}));
  };

  const handleSave = async () => {
    saveSettings(companyUpdate);
    setEditing(false);
  };

  return (
    <SettingsPaper title={'Financial'}
      editing={editing}
      onEdit={toggleEdit}
      onSave={handleSave}
      onCancel={toggleEdit}
      loading={loading}
      gridContainerProps={{spacing: 3}}
    >
      <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
        <Grid item xs={6} className={editing ? classes.editField : null}>
          <Typography variant={'body2'} color={'textSecondary'}>Currency Symbol</Typography>
          {editing ?
            <HoopsSelectField
              fullWidth
              label={'Currency Symbol'}
              name={'currencySymbol'}
              type={'text'}
              onChange={(e) => handleCompanyEdit({currencySymbol: e.target.value})}
              defaultValue={company?.currencySymbol}
              >
              {CURRENCIES.map((currency, i) => (
                <MenuItem key={i} value={currency}>{currency}</MenuItem>
              ))}
            </HoopsSelectField> : <Typography className={classes.bottomMargin}>{company?.currencySymbol}</Typography>
          }
        </Grid>
        <Grid item xs={12} className={editing ? classes.editField : null}>
          <Typography variant={'body2'} color={'textSecondary'}>Default Quote Terms & Conditions</Typography>
          {(editing ? <EditorField
            name={'quoteTerms'}
            input={{
              value: company?.quoteTerms ?? '',
              onChange: (value) => handleCompanyEdit({quoteTerms: value})
            }}
          /> :
            <div className={classes.termsContainer} dangerouslySetInnerHTML={{__html: company?.quoteTerms}} />
          )}
        </Grid>
        <Grid item xs={12} className={editing ? classes.editField : null}>
          <Typography variant={'body2'} color={'textSecondary'}>Invoice Terms & Conditions</Typography>
          {(editing ? <HoopsTextField
            name={'invoiceTerms'}
            multiline
            rows={4}
            defaultValue={company?.invoiceTerms}
            onChange={(e) => handleCompanyEdit({invoiceTerms: e.target.value})}
          /> :
            <>
              <Typography style={{whiteSpace: 'pre'}}>{company?.invoiceTerms}</Typography>
            </>
          )}
        </Grid>
      </PermissionBasedContent>
      <Grid item xs={12} className={editing ? classes.editField : null}>
        <Typography variant={'body2'} color={'textSecondary'}>Proof Terms & Conditions</Typography>
        {(editing ? <EditorField
          name={'proofTerms'}
          input={{
            value: company?.quoteTerms ?? '',
            onChange: (value) => handleCompanyEdit({proofTerms: value})
          }}
        /> : <div className={classes.termsContainer} dangerouslySetInnerHTML={{__html: company?.proofTerms}} />)}
      </Grid>
    </SettingsPaper>

  );
};
