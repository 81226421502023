import React from 'react';
import {Avatar} from '@mui/material';
import {AttachMoney, FlashOn, Person} from '@mui/icons-material';
import {getInitials, isLightColor} from '../../utils';
import {colors, registerGlobalStyle} from '../../theme';
import {ToolTip} from './ToolTip';

registerGlobalStyle('.user-avatar', () => ({
  '&.user-avatar': { // Need to be more specific than MUI
    overflow: 'visible',
    backgroundColor: 'var(--avatar-color)',
    color: 'var(--avatar-text)',
  },
}));

export function UserAvatar({name, initials, color}) {
  return (
    <>
      {(name || initials) &&
        <ToolTip tip={name} disabled={!name}>
          <Avatar className={'user-avatar'} style={getColors(name, color)}>
            {getIcon(name, initials)}
          </Avatar>
        </ToolTip>
      }
      {!(name || initials) &&
        <Avatar className={'user-avatar empty-avatar'} style={getColors(name, color)}/>
      }
    </>
  );
}

function getIcon(name, initials) {
  switch (name) {
    case 'Automation':
      return <FlashOn/>;

    case 'Customer':
      return <Person/>;

    case 'Accounts':
      return <AttachMoney/>;

    default:
      return initials ?? getInitials(name);
  }
}

function getColors(name, color) {
  if (color == null) {
    switch (name) {
      case 'Automation':
        color = colors.background.grey.dark;
        break;

      case 'Customer':
        color = colors.palette.blue;
        break;

      case 'Accounts':
        color = colors.background.contrastDark;
        break;

      default:
        color = stringToColor(name);
        break;
    }
  }

  return {'--avatar-color': color, '--avatar-text': isLightColor(color) ? colors.text.main : colors.palette.white};
}

function stringToColor(string) {
  if (!string) {
    return colors.palette.greyLight;
  }

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
