import React, {useCallback, useState} from 'react';
import {Column, Field, Row, usePageContext} from '../../../componentsLib/Layout';
import {useSaveSalesDoc} from '../../../hooks/api';
import {SalesDoc} from '../Models/SalesDoc';
import {PopupItem, ToolboxSection} from '../../../componentsLib/Popovers';
import {BodyText, CaptionText} from '../../../componentsLib/Text';
import {Button, Switch, TextInput, WysiwygEditor, WysiwygProvider, WysiwygSubstitution} from '../../../componentsLib/Basic';
import {PermissionBasedContent, permissionCheck, SubscriptionTypes} from '../../../componentsHoops/AccessControl';
import {Select, SelectItem} from '../../../componentsLib/Pickers';
import {WarningIcon} from '../../../componentsLib/Icons';
import {SalesDocToolbox} from './SalesDocToolboxDrawer';
import {registerGlobalStyle} from '../../../theme';
import {
  AdditionalCostSettingsSection,
  ChooseTemplateSection,
  ConvertSalesDocSection,
  CustomerActionsSection,
  DecorationSettingsSection,
  DiscountSettingsSection,
  PresentationBlockSection,
  ProductSettingsSection,
  SalesDocStatusSection,
  SurchargeSettingsSection,
} from './ToolboxSections';
import {valueFromEvent} from '../../../utils';
import {additionalCostSubstitutions, decorationSubstitutions, productSubstitutions} from '../../../models/TemplateSubstitutions';
import {TaxSelect} from '../../../componentsHoops/AutoComplete';

registerGlobalStyle('.settings-toolboxes', (theme) => ({
  '.template-name-description': {
    '.column': {flex: '0 0 50%',},
    '.template-description-field': {
      paddingTop: theme.spacing(1),
      '.text-input': {height: '4rem',},
    },
  },
  '&.choose-template-toolbox': {'.template-buttons': {justifyContent: 'space-between',},},
  '&.convert-to-salesdoc-toolbox': {
    '.choose-template-section': {'.text-heading': {paddingBottom: theme.spacing(1.5)}},
    '.template-buttons': {justifyContent: 'end'}
  },
  '&.template-settings-toolbox': {'.pricing-settings': {'.basic-field': {width: '50%'},}},
  '.template-description-section': {'&> .text-input + .wysiwyg-substitution': {marginTop: theme.spacing(1)},},
}));

export function ChooseTemplateToolbox() {
  const {createSalesDoc, salesDoc: _salesDoc, setSelection} = usePageContext();
  const [selectedTemplate, setSelectedTemplate] = useState();

  const handleAddNew = useCallback(() => {
    const defaultTitle = _salesDoc.documentType === SalesDoc.Type.NEW_QUOTE ? 'Quote' : 'Presentation';
    createSalesDoc({
      _id: null,
      docTemplateName: 'New Template',
      documentType: SalesDoc.Type.getTemplateTypeFromDocumentType(_salesDoc.documentType),
      docTypeName: permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) ? defaultTitle : ''
    });
    setSelection((prev) => ({...prev, showSettings: true}));
  }, [_salesDoc?.documentType, createSalesDoc, setSelection]);

  const handleUseSelected = useCallback(() => {
    createSalesDoc(selectedTemplate);
  }, [createSalesDoc, selectedTemplate]);

  return (
    <SalesDocToolbox className={'settings-toolboxes choose-template-toolbox'} heading={'Choose a Template'} noSettings noBack>
      <ChooseTemplateSection selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
      <ToolboxSection stickyFooter>
        <Row className={'template-buttons'}>
          <Button navPrimary onClick={handleAddNew}>Create a New Template</Button>
          <Button navMain onClick={handleUseSelected} disabled={!selectedTemplate}>Use Template</Button>
        </Row>
      </ToolboxSection>
    </SalesDocToolbox>
  );
}

export function ConvertToSalesDocToolbox() {
  const {createSalesDoc} = usePageContext();
  const [selectedTemplate, setSelectedTemplate] = useState();

  const handleUseSelected = useCallback(() => {
    createSalesDoc(selectedTemplate);
  }, [createSalesDoc, selectedTemplate]);

  return (
    <SalesDocToolbox className={'settings-toolboxes convert-to-salesdoc-toolbox'} heading={'Convert to SalesDoc'} noSettings noBack>
      <ChooseTemplateSection selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} heading={'Select a SalesDoc Template'} />
      <ConvertSalesDocSection />
      <ToolboxSection stickyFooter>
        <Row className={'template-buttons'} >
          <Button navMain onClick={handleUseSelected} disabled={!selectedTemplate}>{`Convert To ${selectedTemplate?.docTemplateName ?? 'SalesDoc'}`}</Button>
        </Row>
      </ToolboxSection>
    </SalesDocToolbox>
  );
}

export function SettingsToolbox() {
  const {company, salesDoc, setSelection} = usePageContext();
  const {save: saveSalesDocApi, isSaving: salesDocSaving} = useSaveSalesDoc();
  const [saveAs, setSaveAs] = useState(false);

  const setRounding = useCallback((e) => {
    salesDoc.setRounding(Number.parseInt(valueFromEvent(e)));
  }, [salesDoc]);

  const _handleSave = useCallback(async (id) => {
    const res = await saveSalesDocApi({id, salesDoc: salesDoc.forTemplate()}, {
      successMessage: `Saved document template "${salesDoc.docTemplateName}"`,
      errorMessage: ({message}) => {
        if (message?.includes?.('duplicate key error')) {
          return `Error saving template "${salesDoc.docTemplateName}": the name must be unique`;
        }
        return null;
      }
    });
    if (res) {
      salesDoc.setDocTemplateId(res.salesDoc._id);
      setSelection((prev) => ({...prev, showSettings: true}));
    }
  }, [salesDoc, saveSalesDocApi, setSelection]);

  const handleSave = useCallback(async () => {
    await _handleSave(salesDoc.docTemplateId);
  }, [_handleSave, salesDoc.docTemplateId]);

  const handleSaveNew = useCallback(async () => {
    await _handleSave(null);
  }, [_handleSave]);

  const handleSaveAs = useCallback(() => {
    setSaveAs(true);
  }, []);

  const handleSaveAsBack = useCallback(() => {
    setSaveAs(false);
  }, []);

  const handleExitSettings = useCallback(() => {
    if (salesDoc.docTemplateId == null) {
      salesDoc.setDocTemplateName(null);
    } else {
      setSelection((prev) => ({...prev, showSettings: false}));
    }
  }, [salesDoc, setSelection]);

  const allowedDecimals = company?.accountingPlatform?.platform === 'XERO' ? [2, 3, 4] : [2, 3, 4, 5, 6];

  if (saveAs) {
    return (
      <SalesDocToolbox className={'settings-toolboxes template-settings-toolbox'} heading={'Save a New Template'} noSettings onBack={handleSaveAsBack}>
        <SettingsToolboxNameAndDescriptionSection noHeading />
        <ToolboxSection innerSep>
          <Button
            text={'Save Template'}
            noWrap
            navPrimary
            onClick={handleSaveNew}
            disabled={!salesDoc.docTypeName || !salesDoc.docTemplateName}
            loading={salesDocSaving}
          />
        </ToolboxSection>
      </SalesDocToolbox>
    );
  } else {
    return (
      <SalesDocToolbox className={'settings-toolboxes template-settings-toolbox'} heading={'General Settings'} noSettings onBack={handleExitSettings}>
        <SettingsToolboxNameAndDescriptionSection />
        {salesDoc.isPresentation() &&
          <>
            <PresentationBlockSection
              section={'header'}
              forceShowBlock={!salesDoc.template.showTitleBlock && !salesDoc.template.showFooterBlock}
              image={salesDoc.template.headerImage}
              inSettings
              onImageChange={salesDoc.setTemplateHeaderBlockImage}
              onTextChange={salesDoc.setTemplateHeaderBlockText}
              showBlock={salesDoc.template.showHeaderBlock}
              setShowBlock={salesDoc.setTemplateShowHeaderBlock}
              text={salesDoc.template.headerText}
            />
            <PresentationBlockSection
              section={'title'}
              forceShowBlock={!salesDoc.template.showHeaderBlock && !salesDoc.template.showFooterBlock}
              image={salesDoc.template.titleImage}
              inSettings
              onImageChange={salesDoc.setTemplateTitleBlockImage}
              onTextChange={salesDoc.setTemplateTitleBlockText}
              showBlock={salesDoc.template.showTitleBlock}
              setShowBlock={salesDoc.setTemplateShowTitleBlock}
              text={salesDoc.template.titleText}
            />
            <PresentationBlockSection
              section={'footer'}
              forceShowBlock={!salesDoc.template.showTitleBlock && !salesDoc.template.showHeaderBlock}
              image={salesDoc.template.footerImage}
              inSettings
              onImageChange={salesDoc.setTemplateFooterBlockImage}
              onTextChange={salesDoc.setTemplateFooterBlockText}
              showBlock={salesDoc.template.showFooterBlock}
              setShowBlock={salesDoc.setTemplateShowFooterBlock}
              text={salesDoc.template.footerText}
            />
          </>
        }
        <CustomerActionsSection inSettings />
        {!salesDoc.isPresentation() &&
          <SalesDocStatusSection inSettings />
        }
        {!salesDoc.isPresentation() &&
          <ToolboxSection className={'document-terms'} heading={'Terms'} hideShow storageKey={'salesdoc|toolbox|general|terms'}>
            <WysiwygEditor value={salesDoc.template.terms} onChange={salesDoc.setTemplateTerms} />
          </ToolboxSection>
        }
        {!salesDoc.isPresentation() &&
          <ToolboxSection className={'document-details'} heading={'Document Details'} hideShow storageKey={'salesdoc|toolbox|general|documentDetails'}>
            <Switch label={'Show Reference to Customer'} checked={salesDoc.template.showReference} onChange={salesDoc.setTemplateShowReference} />
            <Switch label={'Show Deadline to Customer'} checked={salesDoc.template.showDeadline} onChange={salesDoc.setTemplateShowDeadline} />
          </ToolboxSection>
        }
        <ToolboxSection className={'pricing-settings'} heading={'Pricing Settings'} hideShow storageKey={'salesdoc|toolbox|general|pricingSettings'}>
          {!salesDoc.isPresentation() &&
            <Switch label={'Hide Totals'} checked={salesDoc.template.hideTotals} onChange={salesDoc.setTemplateHideTotals} />
          }
          <Field>
            <TaxSelect label='Tax' value={salesDoc.template.tax} onChange={salesDoc.setTemplateTax} />
          </Field>
          <Field>
            <Select label='Round Unit Price and Sell Price to' value={salesDoc.getRounding().toString()} onChange={setRounding}>
              {allowedDecimals.map((v) => (
                <SelectItem key={v} value={v.toString()}>
                  {v === 2 ? 'The nearest cent' : `${v} decimal places`}
                </SelectItem>
              ))}
            </Select>
          </Field>
          <ProductSettingsSection />
          <DecorationSettingsSection />
          <AdditionalCostSettingsSection />
          <DiscountSettingsSection />
          <SurchargeSettingsSection />
        </ToolboxSection>

        <ToolboxSection heading={'Description Settings'} hideShow storageKey={'salesdoc|toolbox|general|description'}>
          <ToolboxSection heading={'Catalog Product Description Settings'} innerSep>
            <WysiwygProvider>
              <WysiwygSubstitution substitutions={productSubstitutions} value={salesDoc.template.productDescription}
                onChange={salesDoc.setTemplateProductDescription}>
                <BodyText descriptive>
                  Click to insert the below attributes into the descriptions for any Catalog Product. You can insert
                  these into the settings in any order to
                  completely customise your automated product descriptions.
                  <br />
                  If the chosen attribute is empty for the product, nothing will be added to the description.
                </BodyText>
              </WysiwygSubstitution>
            </WysiwygProvider>
          </ToolboxSection>
          <ToolboxSection heading={'Catalog Decoration Description Settings'} innerSep>
            <WysiwygProvider>
              <WysiwygSubstitution substitutions={decorationSubstitutions}
                value={salesDoc.template.decorationDescription} onChange={salesDoc.setTemplateDecorationDescription}>
                <BodyText descriptive>
                  Click to insert the below attributes into the descriptions for any Catalog Decoration. You can insert
                  these into the settings in any order to completely customise your automated descriptions.
                  <br />
                  If the chosen attribute is empty for the decoration, nothing will be added to the description.
                </BodyText>
              </WysiwygSubstitution>
            </WysiwygProvider>
          </ToolboxSection>
          <ToolboxSection heading={'Additional Cost Description Settings'} innerSep>
            <WysiwygProvider>
              <WysiwygSubstitution
                substitutions={additionalCostSubstitutions}
                value={salesDoc.template.additionalCostDescription}
                onChange={salesDoc.setTemplateAdditionalCostDescription}
              >
                <BodyText descriptive>
                  Click to insert the below attributes into the descriptions for any Additional Cost. You can insert
                  these into the settings in any order to completely customise your automated descriptions.
                  <br />
                  If the chosen attribute is empty for the additional cost, nothing will be added to the description.
                </BodyText>
              </WysiwygSubstitution>
            </WysiwygProvider>
          </ToolboxSection>
          <ToolboxSection className={'template-description-section'} heading={'Discount Description Settings'} innerSep>
            <TextInput label={'Name'} value={salesDoc.template.discountName} />
            <WysiwygProvider>
              <WysiwygSubstitution
                substitutions={additionalCostSubstitutions}
                value={salesDoc.template.discountDescription}
                onChange={salesDoc.setTemplateDiscountDescription}
              >
                <BodyText descriptive>
                  Click to insert the below attributes into the descriptions for any Discount. You can insert
                  these into the settings in any order to completely customise your automated descriptions.
                  <br />
                  If the chosen attribute is empty for the discount, nothing will be added to the description.
                </BodyText>
              </WysiwygSubstitution>
            </WysiwygProvider>
          </ToolboxSection>
          <ToolboxSection className={'template-description-section'} heading={'Surcharge Description Settings'} innerSep>
            <TextInput label={'Name'} value={salesDoc.template.surchargeName} />
            <WysiwygProvider>
              <WysiwygSubstitution
                substitutions={additionalCostSubstitutions}
                value={salesDoc.template.surchargeDescription}
                onChange={salesDoc.setTemplateSurchargeDescription}
              >
                <BodyText descriptive>
                  Click to insert the below attributes into the descriptions for any Surcharge. You can insert
                  these into the settings in any order to completely customise your automated descriptions.
                  <br />
                  If the chosen attribute is empty for the surcharge, nothing will be added to the description.
                </BodyText>
              </WysiwygSubstitution>
            </WysiwygProvider>
          </ToolboxSection>
        </ToolboxSection>
        <ToolboxSection className={'save-template-section'} stickyFooter>
          <Row alignCenter gap>
            <WarningIcon />
            <BodyText>
              Updating the Pricing and Description settings will not update any items already on this document. Any new
              items added to this document will match the new settings.
            </BodyText>
            <Button text={'Save Template'} menu navPrimary>
              {salesDoc.docTypeName && salesDoc.docTemplateName && salesDoc.docTemplateId &&
                <PopupItem onClick={handleSave}>Update this Template</PopupItem>
              }
              <PopupItem onClick={handleSaveAs}>As a new Template</PopupItem>
            </Button>
          </Row>
        </ToolboxSection>
      </SalesDocToolbox>
    );
  }
}

function SettingsToolboxNameAndDescriptionSection({noHeading}) {
  const {salesDoc} = usePageContext();

  return (
    <ToolboxSection className={'template-name-description'} heading={noHeading ? undefined : 'Template Details'} hideShow={!noHeading} storageKey={'salesdoc|toolbox|general|documentType'}>
      <Row gap>
        <Column>
          <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
            <TextInput label={'Document Type'} value={salesDoc.docTypeName} onChange={salesDoc.setDocTypeName}></TextInput>
            <CaptionText>
              The Document Type is shown to your customer. This does not need to be unique.
            </CaptionText>
            <BodyText>
              Eg: Quote / Invoice / Estimate
            </BodyText>
          </PermissionBasedContent>
          <PermissionBasedContent allowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
            <TextInput label={'Document Type'} value={salesDoc.docTypeName} disabled />
            <CaptionText>
              The Document Type is shown to your customer.
            </CaptionText>
          </PermissionBasedContent>
        </Column>
        <Column>
          <TextInput label={'Template Name'} value={salesDoc.docTemplateName} onChange={salesDoc.setDocTemplateName}></TextInput>
          <CaptionText>
            The Template Name will give you a unique identifier for this template. The template name must be unique and is not shown to your customer.
          </CaptionText>
          <BodyText>
            Eg: Quote - Averaged Pricing / Quote - No Accept Button
          </BodyText>
        </Column>
      </Row>
      <Column className={'template-description-field'}>
        <TextInput multiline label={'Description'} value={salesDoc.docTypeDescription} onChange={salesDoc.setDocTypeDescription} />
        <CaptionText>Use this optional field for internal notes about the use of this template.</CaptionText>
      </Column>
    </ToolboxSection>
  );
}
