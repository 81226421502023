// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {useState, useEffect} from 'react';
import {intersection} from 'lodash';
import FileList from './FileList';
import {getFiles, deleteFile, updateFile} from '../../actions/files';
import {useSelector, useDispatch} from 'react-redux';
import FileUploadModal from './FileUploadModal';
import FileEditModal from './FileEditModal';
import AddExistingFileModal from '../../componentsOld/AddExistingFileModal';

const standardFilePreviewOptions = {
    thumbWidth: 80,
    thumbHeight: 80,
    tooltipMaxWidth: 650
};

export const Files = ({
    canUpload = true,
    getEntityIds,
    setEntityIds,
    customerId = null,
    isWorkable = true,
    isProof = false,
    canCreateTask = true,
    showAddButton = true,
    showCustomerBtn = false,
    showUploadFileBtn = false,
    showTitle = true,
    canDelete = true,
    createTaskState = {},
    filePreviewOptions = standardFilePreviewOptions,
    selectable = false,
    selected = [],
    onSelect = () => null,
    entityTitle = 'Job'
}) => {
    const fileActionOptions = {
        view: true,
        download: true,
        edit: true,
        delete: canDelete,
        createTask: canCreateTask,
        createTaskState
    };

    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAddExistingModalOpen, setIsAddExistingModalOpen] = useState(false);
    const [file, setFile] = useState({});

    // This is how we are going to currently do it until an overhaul
    const filesSelector = (state) => getEntityIds ?
            state.filesReducer.list.filter((file) => intersection(getEntityIds, file.entities).length) :
            state.filesReducer.list;
    const files = useSelector(filesSelector);

    const dispatch = useDispatch();

    const handleUploadAction = () => {
        setIsUploadModalOpen(true);
    };

    const handleUploadExistingAction = () => {
        setIsAddExistingModalOpen(true);
    };

    const handleUploadModalClose = () => {
        setIsUploadModalOpen(false);
    };

    const handleDeleteAction = (file) => {
        dispatch(deleteFile(file._id));
    };

    const handleEditAction = (file) => {
        setFile(file);
        setIsEditModalOpen(true);
    };

    const handleEditModalSave = (file) => {
        dispatch(updateFile(file._id, file));
        setIsEditModalOpen(false);
    };

    const handleEditModalClose = () => {
        setIsEditModalOpen(false);
    };

    const handleSelectionChange = (selections) => {
        onSelect(selections);
    };

    useEffect(() => {
        const isProofObj = typeof isProof === 'boolean' && {isProof: isProof,};
        dispatch(
            getFiles({
                entityIds: getEntityIds,
                isWorkable,
                customerId,
                ...isProofObj
            })
        );
    }, [getEntityIds, isAddExistingModalOpen,  customerId, dispatch, isProof, isWorkable]);

    return (
        <>
            <FileList
                canUpload={canUpload}
                files={files}
                fileActionOptions={fileActionOptions}
                filePreviewOptions={filePreviewOptions}
                onUploadExistingAction={handleUploadExistingAction}
                onUploadAction={handleUploadAction}
                onDeleteAction={handleDeleteAction}
                onEditAction={handleEditAction}
                selectable={selectable}
                selected={selected}
                onSelect={handleSelectionChange}
                showTitle={showTitle}
                showAddButton={showAddButton}
                showCustomerBtn={showCustomerBtn}
                showUploadFileBtn={showUploadFileBtn}
            />
            <FileUploadModal openModal={isUploadModalOpen} onCloseModal={handleUploadModalClose} entities={setEntityIds} customerId={customerId} />
            {isEditModalOpen ? <FileEditModal openModal={isEditModalOpen} onSave={handleEditModalSave} onCloseModal={handleEditModalClose} file={file} /> : null}
            <AddExistingFileModal
                existingAssociatedFiles={files}
                getEntityIds={getEntityIds}
                customerId={customerId}
                isOpen={isAddExistingModalOpen}
                onCloseModal={() => setIsAddExistingModalOpen(false)}
                title='Customer Files'
                subTitle={`Any file linked with this customer will display here and can be reused on this ${entityTitle.toLowerCase()}.`}
                onSelect={handleSelectionChange}
                handleUploadAction={handleUploadAction}
                handleDeleteAction={handleDeleteAction}
                handleEditAction={handleEditAction}
            />
        </>
    );
};

export default Files;
