import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {SET_ACTIVE_PURCHASE_ORDER, SET_FORM_PURCHASE_ORDER} from '../../../actions/purchaseOrders';
import {usePurchaseOrderCountForJobVendors} from '../../../hooks/api';
import {Avatar, Chip} from '@mui/material';
import {useHistory} from 'react-router';
import {useLazyQuery, gql, useQuery} from '@apollo/client';
import {GQL_GET_DRAFT_PO} from '../../../queries/purchaseOrders';
import {ModalDialog} from '../../../componentsLib/Popovers';
import {BodyText, HeadingText} from '../../../componentsLib/Text';
import {Column, Row} from '../../../componentsLib/Layout';
import {Select, SelectItem} from '../../../componentsLib/Pickers';
import {valueFromEvent} from '../../../utils';
import {registerGlobalStyle} from '../../../theme';

const GET_DECORATORS = gql`
    query decorators($jobId: String!){
        decorators(jobId: $jobId){
            _id
            name
        }
    }
`;

registerGlobalStyle('.add-purchase-order-modal, .decorator-spec-sheet-modal', () => ({
  '.modal-dialog-inner': {
    minWidth: 500,
    maxWidth: 700
  }
}));

export const AddPrurchaseOrderModal = ({
  jobId,
  closeModal = () => null,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [vendorId, setVendorId] = useState();
  const {data} = usePurchaseOrderCountForJobVendors(jobId);
  const vendors = data?.vendorCounts;

  const [getDraftPo, {loading}] = useLazyQuery(GQL_GET_DRAFT_PO, {
    variables: {
      jobId,
      vendorId
    },
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result) {
        dispatch({
          type: SET_FORM_PURCHASE_ORDER,
          payload: {
            locked: true,
            ...result.getDraftPO
          }
        });

        // We do this to have additional data available
        dispatch({
          type: SET_ACTIVE_PURCHASE_ORDER,
          payload: result.getDraftPO
        });
        closeModal();
        history.push('/purchase-orders/new');
      }
    },

  });

  const handleSelectChange = useCallback((e) => {
    setVendorId(valueFromEvent(e));
  }, []);

  const handleCancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleNext = useCallback(() => {
    getDraftPo(jobId, vendorId);
  }, [getDraftPo, jobId, vendorId]);

  return (
    <ModalDialog
      className={'add-purchase-order-modal'}
      alignInnerLeft
      disableOk={loading || !vendorId}
      loading={loading}
      okText={'NEXT'}
      onOk={handleNext}
      onCancel={handleCancel}
      title={'Add Purchase Order'}
    >

      {(vendors?.length > 0) &&
        <Select
          className={'vendor-select'}
          placeholder={'Select Vendor'}
          value={vendorId}
          onChange={handleSelectChange}
        >
          {vendors?.map((vendor) => (
            <SelectItem value={vendor._id} key={vendor.name}>
              <Row justifySpaceBetween>
                {vendor.name}
                <Chip color='primary' avatar={<Avatar>{vendor.count}</Avatar>} label={'Already created.'} size='small' />
              </Row>
            </SelectItem>
          ))}
        </Select>
      }

      {(!vendors?.length > 0) &&
        <Column>
          <HeadingText>No linked Vendors</HeadingText>
          <BodyText>
            {'Use the job builder to ensure each product or service that\'s required to be purchased is linked to the correct vendor.'}
          </BodyText>
        </Column>
      }

    </ModalDialog>
  );
};

export const DecoratorSpecSheetModal = ({jobId, onClose: closeModal}) => {
  const [vendorId, setVendorId] = useState();

  const handleSelectChange = useCallback((e) => {
    setVendorId(valueFromEvent(e));
  }, []);

  const handleCreate = useCallback(() => {
    window.open(`${process.env.REACT_APP_BASE_URL}/jobs/${jobId}/decorator/${vendorId}/pdf`, '_blank');
  }, [jobId, vendorId]);

  const handleCancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const {data, loading} = useQuery(GET_DECORATORS, {variables: {jobId}});

  return (
    <ModalDialog
      className={'decorator-spec-sheet-modal'}
      alignInnerLeft
      disableOk={loading || !vendorId}
      loading={loading}
      okText={'CREATE'}
      onCancel={handleCancel}
      onOk={handleCreate}
      title={'Create Decorator Spec Sheet'}
    >

      {(data?.decorators.length > 0) &&
        <Select
          className={'vendor-select'}
          placeholder={'Select Vendor'}
          value={vendorId}
          onChange={handleSelectChange}
        >
          {data.decorators.map((vendor) => (
            vendor && <SelectItem value={vendor._id} key={vendor.name} text={vendor.name} />
          ))}
        </Select>
      }

      {(!data?.decorators.length > 0) &&
        <>
          <HeadingText>No linked decorations</HeadingText>
          <BodyText>
            There are no decorations linked with a vendor that are also linked with a product on this
            job.<br /><br />
            <b>To correct this</b>: Edit the job and ensure that the line items have been created using the Item Builder,
            that the products & decorations are within the same item builder. Also check that the decorations are
            linked with a vendor.
          </BodyText>
        </>
      }

    </ModalDialog>
  );
};
