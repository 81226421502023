import * as React from 'react';
import {Collapse} from '@mui/material';
import {Mention, MentionsInput} from 'react-mentions';
import {Column, Row} from '../../componentsLib/Layout';
import {BusySpinner, Button, UserAvatar} from '../../componentsLib/Basic';
import {BodyText} from '../../componentsLib/Text';
import {formatDateTimeRelative} from '../../utils';
import {useCallback, useMemo, useState} from 'react';
import {useUsersCache} from '../../hooks/api';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../../componentsLib';

registerGlobalStyle('.comments-viewer', (theme) => ({
  '.comment-title .text-body': {
    color: theme.colors.text.medium,
    lineHeight: 1.5,
    '&:first-child': {fontWeight: theme.typography.bold},
    '&:last-child': {fontWeight: theme.typography.light},
  },
  'textarea': {outline: 'none'},
  '.comment-entry .mentions:focus-within textarea': {borderColor: theme.colors.border.highlight},
  '.mentions': {
    marginTop: theme.spacing(.5),
    'textarea': {
      padding: theme.spacing(.5, 1),
      color: theme.colors.text.main,
      borderRadius: 4,
      borderColor: theme.colors.palette.greyLighter,
      lineHeight: '200%',
    },
    'textarea:read-only': {
      border: '1px solid transparent',
      outlineColor: 'transparent',
    },
    'textarea::placeholder': {color: theme.colors.palette.greyLighter},
  },
  '.mentions__readonly': {
    'textarea': {
      padding: 0,
      border: 0,
    },
    '.mentions__highlighter': {padding: 0},
    '.mentions__control': {minHeight: 0},
  },
  '.mentions__control': {minHeight: 60},
  '.mentions__input': {lineHeight: '200%'},
  '.mentions__highlighter': {
    lineHeight: '200%',
    padding: theme.spacing(.5, 1),
  },
  '.comment': {zIndex: 0},
  '.mentions__mention': {
    position: 'relative',
    zIndex: 1,
    pointerEvents: 'none',
    cursor: 'pointer',
    padding: theme.spacing(0, '1px'),
    margin: theme.spacing(0, 0, 0, -.5),
    color: theme.colors.text.main,
    background: theme.colors.text.light,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderImage: 'initial',
    borderRadius: 25,
  },
  '.mentions__suggestions__list': {
    borderRadius: 4,
    backgroundColor: theme.colors.background.paper,
    minWidth: 200,
    boxShadow: theme.shadows.hoverElevation,
    maxHeight: 520,
    overflow: 'auto'
  },
  '.mentions__suggestions__item': {padding: theme.spacing(2, 3)},
  '.mentions__suggestions__item--focused': {backgroundColor: theme.colors.background.hover},
}));

const displayTransform = (id, display) => ` @${display} `;

export function CommentsViewer({comments: _comments, isLoading, onAddComment}) {
  const comments = useMemo(() => ({
    ..._comments,
    comments: _comments?.comments.toSorted((a, b) => new Date(b.madeAt).getTime() - new Date(a.madeAt).getTime()) ?? [],
  }), [_comments]);

  const {data: {users: _users}} = useUsersCache();
  const users = useMemo(() => (_users ?? [])
    .filter(({status}) => status === 'ACTIVE')
    .map((user) => ({email: user.username, id: user._id.toString(), display: user.fullName})),
    [_users]);
  const [newComment, setNewComment] = useState(null);
  const [isSavingComment, setIsSavingComment] = useState(false);

  const handleClearComment = useCallback(() => {
    setNewComment(null);
  }, []);

  const handleSaveComment = useCallback(async () => {
    try {
      setIsSavingComment(true);
      await onAddComment(newComment);
    } finally {
      setNewComment(null);
      setIsSavingComment(false);
    }
  }, [newComment, onAddComment]);

  const handleFocusComment = useCallback(() => {
    setNewComment((prev) => prev ?? '');
  }, []);

  const handleChangeComment = useCallback((ev) => {
    setNewComment(ev.target.value);
  }, []);

  return (
    <Column className={'comments-viewer'} paper fillWidth pad gap>
      {(isLoading || !comments) &&
        <BusySpinner message={'Loading comments'} left />
      }
      {!isLoading && comments &&
        <>
          <Column className={'comment-entry'} alignStretch gap>
            <MentionsInput
              value={newComment ?? ''}
              onChange={handleChangeComment}
              readOnly={false}
              className='mentions'
              placeholder={'Add a comment & mention people using \'@\''}
              onFocus={handleFocusComment}
            >
              <Mention
                trigger='@'
                data={users}
                className={'mentions__mention'}
                displayTransform={displayTransform}
              />
            </MentionsInput>
            <Collapse in={newComment != null}>
              <Row gap>
                <Button navPositive disabled={!newComment} onClick={handleSaveComment} loading={isSavingComment}>Save</Button>
                <Button navNegative disabled={newComment == null} onClick={handleClearComment}>Cancel</Button>
              </Row>
            </Collapse>
          </Column>
          {comments.comments.map((comment) => (
            <>
              <Row gap className={'comment'}>
                <UserAvatar name={comment.madeBy}/>
                <Column>
                  <Row className={'comment-title'} gap>
                    <BodyText text={comment.madeBy}/>
                    <BodyText text={formatDateTimeRelative(comment.madeAt)}/>
                  </Row>
                  <MentionsInput
                    value={comment.content}
                    readOnly={true}
                    className='mentions mentions__readonly'
                  >
                    <Mention
                      trigger='@'
                      data={users}
                      className={'mentions__mention'}
                      appendSpaceOnAdd={false}
                    />
                  </MentionsInput>
                </Column>
              </Row>
            </>
          ))}
        </>
      }
    </Column>
  );
}

CommentsViewer.propTypes = {
  comments: HoopsPropTypes.arrayOfObject,
  isLoading: HoopsPropTypes.bool,
  onAddComment: HoopsPropTypes.func,
};
