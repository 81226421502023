import React from 'react';
import {Warning as WarningIcon} from '@mui/icons-material';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';
import {Row} from '../Layout';
import {BodyText} from '../Text';

registerGlobalStyle('.info-notice', (theme) => ({
  alignSelf: 'start',
  border: `1px solid ${theme.colors.border.warning}`,
  backgroundColor: theme.colors.background.warning,
  padding: theme.spacing(1, 1, 1),
  marginBlock: theme.spacing(2),
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  columnGap: theme.spacing(1.5),
  p: {color: theme.colors.text.main,},
  svg: {
    width: '1.5rem',
    height: '1.5rem',
    color: theme.colors.border.warning,
  },
  '&:is(.page-header .info-notice)': {
    alignSelf: 'stretch',
    padding: theme.spacing(0.375, 1, 0.25),
    marginBlock: '-3px -2px',
    p: {lineHeight: '1.2'},
  },
}));

export function InfoNotice({className, text, children}) {
  return (
    <Row className={['info-notice', className]} alignCenter>
      <WarningIcon />
      <BodyText text={text ?? children}/>
    </Row>
  );
}

InfoNotice.propTypes = {
  className: HoopsPropTypes.className,
  text: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
