// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React from 'react';
import {get} from 'lodash';
import {Button, Grid, MenuItem, MuiThemeProvider} from '@material-ui/core';
import {renderSelectField, renderTextField, RadioGroupField} from '../shared/formHelpers/fields';
import {Field, reduxForm, FieldArray, getFormValues} from 'redux-form';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import AddressFields from '../address/addressFields';
import ContactFields from '../contacts/contactFields';
import Icon from '@material-ui/core/Icon';
import red from '../../ui/theme/red';
import Fab from '@material-ui/core/Fab';
import {GetUsers} from '../../actions/action-types';
import {email, required} from '../shared/formHelpers/validation';
import {AppAccess, permissionCheck, SubscriptionTypes} from '../../componentsHoops/AccessControl';

const CUSTOMER_TYPES = [{
  label: 'Business / Company',
  value: 'COMPANY'
}, {
  label: 'Individual',
  value: 'INDIVIDUAL'
}];

const styles = (theme) => ({
  gridPadding: {
    paddingTop: 0,
    paddingBottom: 0
  },
  formBlock: {marginBottom: theme.spacing(4)},
  customerContact: {
    borderColor: theme.palette.secondary.light,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: theme.spacing.radius,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1)
  },
  deleteButton: {
    color: 'white',
    backgroundColor: theme.colors.red
  }
});

class CustomerForm extends React.Component {
  state = {sameAddress: true};

  componentDidMount() {
    const {getUsers} = this.props;
    getUsers();
  }

  renderContactFields = ({fields}) => {
    const {classes} = this.props;
    return (
      <Grid container>
        {fields.map((contact, index) => (
          <Grid container key={index} className={classes.customerContact}>
            <Grid container item justifyContent={'center'} alignItems={'center'} xs={2}>
              <MuiThemeProvider theme={red}>
                <Fab size='small' color={'primary'} disabled={fields.length === 1} onClick={() => fields.splice(index, 1)}>
                  <Icon>delete</Icon>
                </Fab>
              </MuiThemeProvider>
            </Grid>
            <Grid data-intercom-target={'customer-form-contact-sheet'} item xs={10}>
              <ContactFields contact={contact} />
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent={'flex-end'}>
          <Grid item xs={10}>
            <Button data-intercom-target={'customer-form-add-contact'} variant='text' color={'primary'} onClick={() => fields.push({})}>Add Another
              Contact</Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderAddressFields = ({fields}) => (
    <Grid container>
      {fields.map((address, index) => (
        <Grid container key={index} className={this.props.classes.formBlock}>
          <Grid container item justifyContent={'center'} alignItems={'center'} xs={2}>
            {fields.length >= 1 && <MuiThemeProvider theme={red}>
              <Fab size='small' color={'primary'} onClick={() => fields.splice(index, 1)}>
                <Icon>delete</Icon>
              </Fab>
            </MuiThemeProvider>}
          </Grid>
          <Grid item xs={10}>
            <AddressFields address={address} showLabelInput={true} showAddressLine2={true} />
          </Grid>
        </Grid>
      ))}
      <Grid container>
        <Button data-intercom-target={'customer-form-add-address'} variant={(fields.length ? 'text' : 'outlined')} color={'primary'} onClick={() => fields.push({})}>
          {(fields.length ? 'Add Another Address' : 'Add Address')}
        </Button>
      </Grid>
    </Grid>
  );

  handleSubmit = (values) => {
    console.log(values);
  };

  render() {
    const {handleSubmit, classes, users, formValues, company} = this.props;
    const type = get(formValues, 'type');

    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item md={12} lg={6} data-intercom-target={'customer-form-company-info'} >
            <Grid container spacing={2} className={classes.formBlock}>
              <Grid item container>
                <Field
                  name={'type'}
                  selections={CUSTOMER_TYPES}
                  component={RadioGroupField}
                />
              </Grid>
              <Grid item xs={6} className={classes.gridPadding}>
                <Field fullWidth
                  label={'Company Name *'}
                  name={'name'}
                  type={'text'}
                  disabled={type === 'INDIVIDUAL'}
                  validate={type === 'COMPANY' ? required : null}
                  component={renderTextField} />
              </Grid>
              <Grid item xs={6} className={classes.gridPadding}>
                <Field fullWidth
                  label={'Company Email'}
                  name={'email'}
                  type={'email'}
                  validate={email}
                  disabled={type === 'INDIVIDUAL'}
                  component={renderTextField} />
              </Grid>
              <Grid item xs={6} className={classes.gridPadding}>
                <Field fullWidth
                  label={'Company Phone'}
                  name={'phone'}
                  type={'text'}
                  disabled={type === 'INDIVIDUAL'}
                  component={renderTextField} />
              </Grid>
              <Grid item xs={6} className={classes.gridPadding}>
                <Field fullWidth
                  label={'Company Website'}
                  name={'website'}
                  type={'text'}
                  disabled={type === 'INDIVIDUAL'}
                  component={renderTextField} />
              </Grid>
              <Grid item xs={6}>
                <Field fullWidth
                  label={'Customer Rep'}
                  name={'customerRepId'}
                  component={renderSelectField}>
                  {users.map((user, index) => <MenuItem key={index} value={user._id}>{user.fullName}</MenuItem>)}
                </Field>
              </Grid>
              {permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) &&
                <Grid item xs={6}>
                  <Field fullWidth
                    label={'Industry Type'}
                    name={'industryType'}
                    component={renderSelectField}>
                    {company?.industryTypes?.map((industryType, index) => <MenuItem key={index} value={industryType}>{industryType}</MenuItem>)}
                  </Field>
                </Grid>
              }
              <Grid item xs={6}>
                <Field fullWidth
                  label={'Minimum Markup (%)'}
                  name={'settings.minimumMarkup'}
                  type={'text'}
                  component={renderTextField}
                  data-intercom-target={'customer-form-markup'} />
              </Grid>
              <Grid item xs={6}>
                <Field fullWidth
                  label={'Sales Discount (%)'}
                  name={'settings.salesDiscount'}
                  type={'text'}
                  component={renderTextField} />
              </Grid>
              {permissionCheck({allowedAppAccess: [AppAccess.MultiCompany]}) &&
                <Grid item xs={6} className={classes.gridPadding}>
                  <Field fullWidth
                    label={'Your Company Branding to Display'}
                    name={'companyTradingEntityId'}
                    component={renderSelectField}>
                    {company?.companyTradingEntities?.map((companyEntity, index) => <MenuItem key={index} value={companyEntity._id}>{companyEntity.name}</MenuItem>)}
                  </Field>
                </Grid>
              }
              <Grid item xs={12} className={classes.gridPadding}>
                <Field fullWidth
                  label={'Profile'}
                  name={'profile'}
                  type={'text'}
                  multiline
                  rows={4}
                  component={renderTextField} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <Grid item xs={12}>
                  <FieldArray name={'addresses'} component={this.renderAddressFields} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container>
                  <FieldArray name={'contacts'} component={this.renderContactFields} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.authReducer.users,
    initialValues: state.customerReducer.formCustomer,
    company: state.companyReducer.company,
    formValues: getFormValues('customerForm')(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {getUsers: () => dispatch(GetUsers())};
}

CustomerForm = reduxForm({
  form: 'customerForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(CustomerForm);

CustomerForm = connect(mapStateToProps, mapDispatchToProps)(CustomerForm);
CustomerForm = withStyles(styles)(CustomerForm);

export default CustomerForm;
