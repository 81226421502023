import React from 'react';
import classNames from 'classnames';
import {Drawer} from '@mui/material';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.sliding-drawer', (theme) => ({
  '.MuiDrawer-paper': {
    width: '70vw',
    backgroundColor: theme.colors.background.empty,
  },
}));

export function SlidingDrawer({className, open, onClose, children}) {
  return (
    <Drawer
      className={classNames([className, 'sliding-drawer'])}
      disableScrollLock
      ModalProps={{
        disableEnforceFocus: true,
        disableRestoreFocus: true,
      }}
      transitionDuration={200}
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      {children}
    </Drawer>
  );
}

SlidingDrawer.propTypes = {
  className: HoopsPropTypes.className,
  open: HoopsPropTypes.bool,
  onClose: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
