import * as React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {compareArrays} from '../../../../utils/compareArrays';
import {useGetViews} from '../../../../hooks/api';
import {
  ActivityContactRenderer,
  ActivityDateRenderer,
  ActivityEmailRenderer,
  ActivitySmsRenderer,
  ActivityStatusRenderer,
  ActivityTab,
  invoiceRenderers,
  proofsRenderers
} from '../../../../componentsHoops/Activity';

const renderers = {
  ...proofsRenderers,
  ...invoiceRenderers,

  CREATE: {renderer: true, heading: 'Created the Job'},
  UPDATE: {heading: 'Edited the Job'},
  DELETE: {heading: 'Deleted the Job'},
  EMAIL_SENT: {renderer: ActivityEmailRenderer, heading: 'Sent the Job by email'},
  SMS_SENT: {renderer: ActivitySmsRenderer, heading: 'Sent the Job by SMS'},

  STATUS: {renderer: ActivityStatusRenderer, heading: 'Updated the Status of the Job', expand: true},

  // Field path renderers
  'jobs.shippingInstructions': {
    renderer: ShippingInstructionsRenderer,
    heading: ({change}) => change[1] ? 'Added Shipping Instructions to the Job' : 'Removed Shipping Instructions from the Job',
  },
  'jobs.trackingNumbers': {
    renderer: TrackingNumbersRenderer,
    heading: ({change}) => {
      const diff = change[1].length - change[0].length;
      if (diff === 0) {
        return 'Updated a Tracking Number for the Job';
      } else if (diff > 0) {
        return 'Added a Tracking Number to the Job';
      } else {
        return 'Removed a Tracking Number from the Job';
      }
    },
  },
  'jobs.deadlineAt': {renderer: ActivityDateRenderer, title: 'Deadline'},
  'jobs.contactId': {renderer: ActivityContactRenderer, title: 'Contact'},
};

export const JobActivityTab = ({job}) => {
  const {data: {views: viewData}, isLoading: isViewsLoading} = useGetViews('jobs');

  return (<ActivityTab entity={job} renderers={renderers} viewState={{fields: viewData?.fields, isViewsLoading}}/>);
};

function ShippingInstructionsRenderer({change}) {
  return (
    <Typography variant='body2' className={change[1] ? '' : 'removed'}>{change[1] || change[0]}</Typography>
  );
}

function TrackingNumbersRenderer({change}) {
  const changes = compareArrays(change[0] ?? [], change[1], '_id');
  const trackingNumber = changes.added[0]?.current ?? changes.changed[0]?.previous ?? changes.removed[0]?.previous ?? {};
  const newTrackingNumber = changes.changed[0]?.current;
  return (
    <Box className={`change-item activity-fields ${changes.removed[0]?.previous ? 'removed' : ''}`} sx={{display: 'grid', gridTemplateColumns: 'max-content auto'}}>
      <Typography variant={'caption'}>Shipping Company</Typography>
      <Grid container gap={1}>
        <Typography variant={'body2'}>{trackingNumber.shippingCompany}</Typography>
        {newTrackingNumber && trackingNumber.shippingCompany !== newTrackingNumber.shippingCompany &&
          <>
            <span>→</span>
            <Typography variant={'body2'}>{newTrackingNumber.shippingCompany}</Typography>
          </>
        }
      </Grid>
      <Typography variant={'caption'}>Tracking Number</Typography>
      <Grid container gap={1}>
        <Typography variant={'body2'}>{trackingNumber.trackingNumber}</Typography>
        {newTrackingNumber && trackingNumber.trackingNumber !== newTrackingNumber.trackingNumber &&
          <>
            <span>→</span>
            <Typography variant={'body2'}>{newTrackingNumber.trackingNumber}</Typography>
          </>
        }
      </Grid>
      <Typography variant={'caption'}>Tracking Link</Typography>
      <Grid container gap={1}>
        <Typography variant={'body2'}>{trackingNumber.trackingLink}</Typography>
            {newTrackingNumber && trackingNumber.trackingLink !== newTrackingNumber.trackingLink &&
              <>
                <span>→</span>
                <Typography variant={'body2'}>{newTrackingNumber.trackingLink}</Typography>
              </>
            }
      </Grid>
    </Box>
  );
}
