import React, {useCallback} from 'react';
import {useCommentSalesDoc, useGetSalesDocComments} from '../../../hooks/api';
import {CommentsViewer} from '../../../componentsHoops/Comments/CommentsViewer';

export function SalesDocCommentsTab({salesDoc}) {
  const {data: {comments}, isLoading, refetch} = useGetSalesDocComments(salesDoc._id);
  const {comment: saveCommentApi} = useCommentSalesDoc();

  const handleSaveComment = useCallback(async (newComment) => {
    await saveCommentApi({id: salesDoc._id, comment: newComment});
    await refetch();
  }, [refetch, salesDoc._id, saveCommentApi]);

  return (
    <CommentsViewer comments={comments} isLoading={isLoading} onAddComment={handleSaveComment}/>
  );
}
