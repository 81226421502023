import React, {useCallback} from 'react';
import {Button} from '../../../componentsLib/Basic';
import {registerGlobalStyle} from '../../../theme';
import {HoopsPropTypes} from '../../../componentsLib';

registerGlobalStyle('.reset-to-template-button', () => ({'.decorator': {alignSelf: 'center'},}));

export const ResetToTemplateButton = ({className, onReset, resetValue}) => {
  const reset = useCallback(() => {
    onReset(resetValue);
  }, [onReset, resetValue]);

  return (
    <Button actionPrimary className={[className, 'reset-to-template-button']} text={'Reset to Template Settings'} onClick={reset} prefix={'sync'} />
  );
};

ResetToTemplateButton.propTypes = {
  className: HoopsPropTypes.className,
  resetValue: HoopsPropTypes.string,
  onReset: HoopsPropTypes.func,
};
