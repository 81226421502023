import React from 'react';
import {TextAutoComplete} from '../../../componentsLib/Basic';
import {HoopsPropTypes} from '../../../componentsLib';

export const VariantSizeList = ({item}) => (
    <TextAutoComplete
      value={item.size}
      allowAnyValue
      options={item.product.sizes}
      emptyList={'No sizes available'}
      onChange={item.setSize}
    />
  );

VariantSizeList.propTypes = {item: HoopsPropTypes.object};
