import {Description, Person} from '@mui/icons-material';
import {Grid, Table, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import * as React from 'react';
import CardContainer from '../../../../componentsOld/common/CardContainer';
import JobItem from '../../../../componentsOld/jobs/SingleJob/JobDetails/JobItem';
import theme from '../../../../ui/theme';
import {CustomerService} from '../../../../servicesOld/CustomerService';
import {PermissionBasedContent, SubscriptionTypes} from '../../../../componentsHoops/AccessControl';

export const JobDetails = ({
  job = {},
  loading = false,
  updateJobBoard
}) => {

  const items = job?.items;
  return (
    <>
      <JobCustomerCard
        job={job}
        loading={loading}
      />

      <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
        <CardContainer
          data-intercom-target={'job-details-product-panel'}
          title='Product Information'
          icon={<Description />}
          iconBackgroundColor={theme.palette.primary.main}
          loading={loading}
        >

          <Table size={'small'} >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell >Image</TableCell>
                <TableCell colSpan={5}>Description</TableCell>
                <TableCell >Total Quantity</TableCell>
                <TableCell >Status</TableCell>
              </TableRow>
            </TableHead>
            {
              items &&
              items.map((item, index) => <JobItem key={index} Item={item} updateJobBoard={updateJobBoard} />)
            }
          </Table>
        </CardContainer>
      </PermissionBasedContent>
    </>
  );
};

export const JobCustomerCard = ({job, button, loading = false, footer = null}) => {
  const customer = job?.customer;
  const contact = job?.contact;
  // const customerName = `${get(customer, 'name', '')} ${get(customer, 'deletedAt', '') ? '(Deleted)' : ''}`;
  return (
    <>

      <CardContainer
        data-intercom-target={'job-details-customer-panel'}
        title='Customer Information'
        icon={<Person />}
        iconBackgroundColor={theme.palette.primary.main}
        buttonComponent={button}
        loading={loading}
      >

        <Grid container sx={{mb: 3, ml: 3}}>
          <Grid item xs={3}>
            <Typography sx={{color: theme.colors.grey.main}}>Company</Typography>
            <Typography>{customer?.name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{color: theme.colors.grey.main}}>Contact Name</Typography>
            <Typography>{contact?.fullName ? contact?.fullName : `${contact?.firstName || ''} ${contact?.lastName || ''}`}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{color: theme.colors.grey.main}}>Email</Typography>
            <Typography>{contact?.email}</Typography>
          </Grid>
        </Grid>

        <Grid container sx={{mb: 3, ml: 3}}>
          <Grid item xs={3}>
            <Typography sx={{color: theme.colors.grey.main}}>Contact Number</Typography>
            <Typography>{contact?.phone}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{color: theme.colors.grey.main}}>Billing Address</Typography>
            <Typography>
              {job?.billTo?.formattedAddress || CustomerService.getAddressByLabel(customer, 'BILLING')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{color: theme.colors.grey.main}}>Shipping Address</Typography>
            <Typography>
              {job?.shipTo?.formattedAddress || CustomerService.getAddressByLabel(customer, 'SHIPPING')}
            </Typography>
          </Grid>
        </Grid>

        {!!footer && <Grid container sx={{mb: 3, ml: 3}}>
          <Grid item xs={12}>
            {footer}
          </Grid>
        </Grid>}

      </CardContainer>
    </>

  );
};
