import {useSnackbar as useRawSnackbar} from 'notistack';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useCallback, useMemo, useState} from 'react';
import {stringify} from 'qs';
import axios from 'axios';
import {GENERAL_ERROR_MESSAGE} from '.';
import {useHeaderContext} from '../componentsHoops/AccessControl';

//
// We use react-query for the guts API and have customised it a little to neatly match our use cases. Full docs
// can be found here: https://tanstack.com/query/v3/docs/react/reference/useQuery
//

export function usePostApiOld(url, options) {
  return useMutationHookApiOld('post', url, {successMessage: 'Saved.', errorMessage: GENERAL_ERROR_MESSAGE, ...options});
}

export function usePatchApiOld(url, options) {
  return useMutationHookApiOld('patch', url, {successMessage: 'Saved.', errorMessage: GENERAL_ERROR_MESSAGE, ...options});
}

export function useDeleteApiOld(url, options) {
  return useMutationHookApiOld('delete', url, {
    successMessage: 'Deleted.',
    errorMessage: GENERAL_ERROR_MESSAGE, ...options
  });
}

export function useGetApiOld(url, {params, ...options} = {}) {
  const {enqueueSnackbar} = useRawSnackbar();
  const checkSession = useCheckSession();
  const queryString = stringify(params, {encodeValuesOnly: true});
  const query = useQuery([url, params], () =>
          fetch(`${process.env.REACT_APP_BASE_URL}/rest/${url}?${queryString}`, {
            method: 'get',
            credentials: 'include',
          })
          .then((res) => {
            checkSession(res);
            return res.json();
          })
          .catch(() => {
            enqueueSnackbar(GENERAL_ERROR_MESSAGE, {variant: 'error'});
          }),
      {refetchOnWindowFocus: false, ...options}
  );
  const queryClient = useQueryClient();
  const updateCache = useCallback((data) => queryClient.setQueryData([url, params], data), [queryClient, url, params]);
  return useMemo(() => ({...query, updateCache}), [query, updateCache]);
}

export function useQueryApiOld(entity, initParams = {}, options) {
  const [params, setParams] = useState(initParams);
  const {enqueueSnackbar} = useRawSnackbar();
  const checkSession = useCheckSession();
  const query = useQuery([entity, params], () =>
          fetch(`${process.env.REACT_APP_BASE_URL}/rest/${entity}/query`, {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify(params),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          })
          .then((res) => {
            checkSession(res);
            return res.json();
          })
          .catch(() => {
            enqueueSnackbar(GENERAL_ERROR_MESSAGE, {variant: 'error'});
          }),
      {refetchOnWindowFocus: false, ...options}
  );
  const queryClient = useQueryClient();
  const updateCache = useCallback((data) => queryClient.setQueryData([entity, params], data), [queryClient, entity, params]);
  return useMemo(() => ({...query, params, setParams, updateCache}), [query, params, setParams, updateCache]);
}

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/rest`,
  timeout: 30000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

function useMutationHookApiOld(method, url, options) {
  const {enqueueSnackbar} = useRawSnackbar();
  const checkSession = useCheckSession();
  const mutation = useMutation(({
    data,
    id = options?.id ?? '',
    query = options?.query ?? {}
  }) => axiosClient[method](id ? `${url}/${id}` : url, data, {params: query})
  .then((response) => {
    checkSession(response);
    return response.data;
  }), options);

  const mutateAsync = useCallback(async (args, optionsOverride = {}) => {
    try {
      const res = await mutation.mutateAsync(args);
      const successMessage = optionsOverride.successMessage ?? options.successMessage;
      if (successMessage) {
        enqueueSnackbar(successMessage, {variant: 'success'});
      }
      return res;
    } catch (error) {

      const errorMessage = optionsOverride.getErrorMessage?.(error) ?? options.getErrorMessage?.(error) ?? optionsOverride.errorMessage ?? options.errorMessage;

      if (errorMessage) {
        enqueueSnackbar(errorMessage, {variant: 'error'});
      } else {
        throw error;
      }
    }
    return undefined;
  }, [enqueueSnackbar, mutation, options]);

  return {
    ...mutation,
    mutateAsync
  };
}

function useCheckSession() {
  const headerContext = useHeaderContext();
  return useCallback((res) => {
    if (res.headers['x-session-changed']) {
      headerContext?.onSessionChanged();
    }
  }, [headerContext]);
}
