// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {useCallback} from 'react';
import {useGetViews} from '../../../hooks/api';
import StatusButton from './StatusPicker/StatusPicker';
import {get} from 'lodash';

function StatusButtons({field, job, onSelect}) {
  const path = field.path;
  const value = get(job, path);
  const options = field.options || [];
  const selected = options.find(({statusLabel}) => statusLabel === value);
  const text = (selected && selected.statusLabel) ?? value;
  const color = selected && selected.color;
  const _onSelect = useCallback(async (selected) => {
    await onSelect(selected, path);
  }, [path, onSelect]);
  return (<div style={{marginRight: 10, marginBottom: 10, position: 'relative', width: 120, overflow: 'hidden',  whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
    {field.title}
    <div style={{height: 40, width: 120}}>
      <StatusButton
        onSelect={_onSelect}
        rowId={job._id} statuses={options} text={text} color={color}/>
    </div>
  </div>);
}

export function JobStatusBar({job, onSelect}) {
  const {data: {views: viewData}} = useGetViews('jobs');
  const statusFields = viewData?.fields.filter(({type}) => type === 'STATUS');
  if (!viewData) {return null;}
  return (
    <div style={{display: 'block', maxWidth: '100%', marginBottom: 20}}>
      <div style={{display: 'inline-flex', maxWidth: '100%', flexFlow: 'wrap'}}>
        {statusFields.map((field, index) => <StatusButtons key={index} job={job} onSelect={onSelect} field={field}/>)}
      </div>
    </div>
  );
}
