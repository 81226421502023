import React, {Component} from 'react';
import {ReportErrorBoundary} from './ReportErrorBoundary';

// Error boundaries have to be class components
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      didCatch: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return {didCatch: true, error};
  }

  componentDidCatch() {
    this.setState({...this.state, location: window.location.pathname});
  }

  componentDidUpdate() {
    // If the user navigates, we will clear the error
    if (this.state.didCatch && window.location.pathname !== this.state.location) {
      this.setState({
        didCatch: false,
        error: null,
      });
    }
  }

  render() {
    if (this.state.didCatch) {
      return this.props.fallback ?? (<ReportErrorBoundary error={this.state.error}/>);
    }
    return this.props.children;
  }
}
