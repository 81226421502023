import {get} from 'lodash';

const sizes = [
  'YOUTH XS',
  'YOUTH SM',
  'YOUTH MED',
  'YOUTH LRG',
  'YOUTH XL',

  '2X-SMALL-PETITE',
  'X-SMALL-PETITE',
  'SMALL-PETITE',
  'MEDIUM-PETITE',
  'LARGE-PETITE',
  'X-LARGE-PETITE',
  '2X-LARGE-PETITE',

  '3XS',
  'XXXS',
  '2XS',
  'XXS',
  'XS',
  'XSM',
  'X-SMALL',
  'ADULT SM',
  'S',
  'SML',
  'SMALL',
  'ADULT MED',
  'M',
  'MED',
  'MEDIUM',
  'ADULT LRG',
  'L',
  'LRG',
  'LARGE',
  'ADULT XL',
  'XL',
  'XLG',
  'X-LARGE',
  '2XL',
  '2X',
  'XXL',
  'XX',
  '2X-LARGE',
  '3XL',
  '3X',
  'XXXL',
  '3X-LARGE',
  '4XL',
  '4X',
  '4X-LARGE',
  '5XL',
  '5X',
  '5X-LARGE',
  '6XL',
  '6X',
  '6X-LARGE',

  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
];

export function sortSizes(collection, path = 'name') {
  return [...collection].sort((a, b) => {
    const sizeA = get(a, path) ?? a?.toString() ?? '';
    const sizeB = get(b, path) ?? b?.toString() ?? '';
    return bySizeComparison(sizeA, sizeB);
  });
}

export function sortSizesAutoManual(collection, path = 'name') {
  collection = [...collection];
  // If any size cannot be found in out sort list, return the original order
  if (collection.some((a) => sizes.indexOf(get(a, path) ?? a?.toString() ?? '') === -1)) {
    return collection;
  }
  return collection.sort((a, b) => {
    const sizeA = get(a, path) ?? a?.toString() ?? '';
    const sizeB = get(b, path) ?? b?.toString() ?? '';
    return bySizeComparison(sizeA, sizeB);
  });
}

export function bySizeComparison(sizeA, sizeB) {
  const indexA = sizes.indexOf(`${sizeA}`.toUpperCase());
  const indexB = sizes.indexOf(`${sizeB}`.toUpperCase());
  if (indexA < indexB) {
    return -1;
  }
  if (indexA > indexB) {
    return 1;
  }
  if (sizeA < sizeB) {
    return -1;
  }
  if (sizeA > sizeB) {
    return 1;
  }
  return 0;
}
