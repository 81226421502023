// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Button, DialogActions, DialogContent, FormControl, FormHelperText, Grid, makeStyles, MenuItem, Typography} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {createEmailTemplate, getEmailTemplate, updateEmailTemplate} from '../../actions/emailTemplate';
import {permissionCheck, SubscriptionTypes} from '../../componentsHoops/AccessControl';
import HoopsSelectField from '../../componentsOld/HoopsSelectField';
import {HoopsTextField} from '../../componentsOld/HoopsTextField';
import EditorField from '../../componentsOld/companies/editor';
import EmailTemplateService from '../../servicesOld/EmailTemplateService';
import theme from '../../ui/theme';
import {featureFlagEnabled, featureFlags} from '../../utils/featureFlag';
import {EmailTemplateContext} from './EmailTemplateManyViewPage';
import ShortcodeList from './ShortcodeList';
import templateType from './templateType';
import {categories} from '../../models/ShortCodes';

const useStyles = makeStyles((theme) => ({
    actionsContainer: {padding: theme.spacing(3),},
    greyText: {color: theme.colors.grey.main}
}));

export const EmailTemplateForm = ({handleClose = () => null,}) => {
    const {control, handleSubmit, setValue, errors, reset, watch} = useForm({mode: 'onBlur', reValidateMode: 'onBlur'});
    const {activeEmailTemplate} = useContext(EmailTemplateContext);
    const [editorValue, setEditorValue] = useState('');
    const dispatch = useDispatch();
    const {quote, job, proof} = categories;
    const {email, sms} = templateType;
    const smsBodyLimit = 160;
    const classes = useStyles();
    const entity = watch('entity');
    const type = watch('via');
    const body = watch('body');

    useEffect(() => {
        if (activeEmailTemplate) {
            dispatch(getEmailTemplate(activeEmailTemplate)).then((emailTemplate) => {
                reset(emailTemplate);
                setEditorValue(emailTemplate.body);
            });
        }
    }, [activeEmailTemplate, dispatch, reset]);

    const submitForm = (data) => {
        activeEmailTemplate
            ? dispatch(updateEmailTemplate(activeEmailTemplate, data))
            : dispatch(createEmailTemplate(data));
        handleClose();
    };

    useEffect(() => {
        if (type === sms) {
            setValue('body', EmailTemplateService.stripHtml(body));
        }
    }, [type, sms, setValue, body]);

    const handleEditorValueChange = (value) => {
        // This is a hack fix, you can't set the value for EditorText without it rerendering
        setValue('body', value);
    };

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <DialogContent style={{overflow: 'unset'}}>
                <Grid container spacing={6}>
                    <Grid item sm={12} md={5}>
                        <Grid container spacing={3}>
                            {featureFlagEnabled(featureFlags.sms_notifications) &&
                                <Grid item xs={12}>
                                    <Controller
                                        name='via'
                                        defaultValue={email}
                                        control={control}
                                        rules={{required: true}}
                                        render={(props) =>
                                            <FormControl fullWidth error={errors.entity} >
                                                <HoopsSelectField
                                                    labelId='send-from-select-label'
                                                    label='Template Type'
                                                    {...props}
                                                >
                                                    <MenuItem value={email}>Email</MenuItem>
                                                    {featureFlagEnabled(featureFlags.sms_notifications) && <MenuItem value={sms}>SMS</MenuItem>}
                                                </HoopsSelectField>
                                                <FormHelperText>{errors.entity && 'Send from is required'}</FormHelperText>
                                            </FormControl>
                                        }
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Controller
                                    name='title'
                                    defaultValue=''
                                    control={control}
                                    rules={{required: true}}
                                    render={(props) => <HoopsTextField {...props} label='Template name *' fullWidth helperText={errors.title && 'Title is required'} error={errors.title} />}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                {type !== sms &&
                                    <Controller
                                        name='subject'
                                        defaultValue=''
                                        control={control}
                                        rules={{required: true}}
                                        render={(props) => <HoopsTextField {...props} label={'Subject *'} fullWidth helperText={errors.subject && 'Subject is required'} error={errors.subject} />}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name='entity'
                                    control={control}
                                    defaultValue={permissionCheck({allowedSubscriptions: [SubscriptionTypes.proofs]}) ? proof : quote}
                                    rules={{required: true}}
                                    render={(props) =>
                                        <FormControl fullWidth error={errors.entity} >
                                            <HoopsSelectField
                                                labelId='send-from-select-label'
                                                label='Send From'
                                                disabled={permissionCheck({allowedSubscriptions: [SubscriptionTypes.proofs]})}
                                                {...props}
                                            >
                                                <MenuItem value={quote}>Quote</MenuItem>
                                                <MenuItem value={job}>Job</MenuItem>
                                                <MenuItem value={proof}>Proof</MenuItem>
                                            </HoopsSelectField>
                                            <FormHelperText>{errors.entity && 'Send from is required'}</FormHelperText>
                                        </FormControl>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ShortcodeList entity={entity || quote} />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={7}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>

                                {type === sms
                                    ?
                                    <>
                                        <Controller
                                            name='body'
                                            defaultValue={' '}
                                            control={control}
                                            rules={{required: true, maxLength: smsBodyLimit}}
                                            render={(props) =>
                                                <FormControl fullWidth error={errors.body} >
                                                    <HoopsTextField
                                                        {...props}
                                                        label='Body'
                                                        multiline
                                                        rows={10}
                                                        style={{paddingBottom: theme.spacing(1)}}
                                                        inputProps={{maxlength: smsBodyLimit}}
                                                    />
                                                    <FormHelperText>{errors.body && errors.body.type === 'required' && 'Text is required in the sms template'}</FormHelperText>
                                                    <FormHelperText>{errors.body && errors.body.type === 'maxLength' && `The maximum length of the sms message is ${smsBodyLimit}`}</FormHelperText>
                                                </FormControl>
                                            }
                                        />
                                        <Typography variant='body2' className={classes.greyText} align='right' gutterBottom>{`${body ? body.length : 0} / ${smsBodyLimit}`}</Typography>
                                        <Typography variant='body2' className={classes.greyText} >{`Max ${smsBodyLimit} Characters. Please note that using short codes will affect the number of characters depending on the length of the text string that is inserted by the short code.`}</Typography>
                                    </>
                                    :
                                    <Controller
                                        name={'body'}
                                        control={control}
                                        rules={{required: true}}
                                        render={() =>
                                            <FormControl fullWidth error={errors.body} >
                                                <EditorField input={{value: editorValue || '', onChange: handleEditorValueChange}} meta={true} className={classes.body} />
                                                <FormHelperText>{errors.body && 'Text is required in the email template'}</FormHelperText>
                                            </FormControl>
                                        }
                                    />

                                }

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.actionsContainer}>
                <Grid container justifyContent={'space-between'} component={'div'}>
                    <Grid item component={'div'}>
                        <Button variant={'text'} color={'secondary'} onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid item component={'div'}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            type={'submit'}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </form>
    );
};

export default EmailTemplateForm;
