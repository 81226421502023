import React, {useCallback} from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {usePageContext} from '../../../componentsLib/Layout';
import {HoopsPropTypes} from '../../../componentsLib';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../../theme';
import {Button} from '../../../componentsLib/Basic';

registerGlobalStyle('.salesdoc-alert', (theme) => ({
  color: theme.colors.text.warning,
  position: 'absolute',
  zIndex: theme.zIndex.alerts,
}));

export const SalesDocAlert = ({className, section, groupId, itemId, variantId}) => {
  const {setSelection} = usePageContext();

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    setSelection({section, groupId, itemId, variantId});
  }, [variantId, groupId, itemId, section, setSelection]);

  return (
    <Button
      className={classNames([className, 'salesdoc-alert'])}
      prefix={ReportProblemIcon}
      onClick={handleClick}
    />
  );
};

SalesDocAlert.propTypes = {
  className: HoopsPropTypes.className,
  section: HoopsPropTypes.string.isRequired,
  groupId: HoopsPropTypes.string,
  itemId: HoopsPropTypes.string,
  variantId: HoopsPropTypes.string,
};
