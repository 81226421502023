import React, {useCallback} from 'react';
import {Row} from '../../componentsLib/Layout';
import {useHeaderContext} from './HeaderContext';
import {registerGlobalStyle} from '../../theme';
import {BodyText} from '../../componentsLib/Text';
import {Button} from '../../componentsLib/Basic';

registerGlobalStyle('.update-version-banner', (theme) => ({
  width: '100%',
  padding: theme.spacing(1.25, 3, 1),
  backgroundColor: theme.colors.background.urgent,
  border: `1px solid ${theme.colors.border.urgent}`,
  color: theme.colors.text.urgent,
  alignItems: 'baseline',
  '.text': {
    color: theme.colors.text.urgent,
    fontSize: theme.fontSize(14),
  },
  '.button': {
    display: 'flex',
    fontSize: theme.fontSize(14),
  },
  '&:has(~* .toolbox-drawer)': {display: 'none'},
}));

export function UpdateVersionBanner() {
  const {minReleaseDate} = useHeaderContext();

  const handleUpdate = useCallback(() => {
    window.location.reload();
  }, []);

  const buildDate = (process.env.REACT_APP_BUILD || '').split(' ')[0];
  const currentVersion = buildDate && new Date(buildDate);
  const newVersion = minReleaseDate && new Date(minReleaseDate);

  return (
    <>
      {newVersion && currentVersion && newVersion.getTime() > currentVersion.getTime() &&
        <Row className={'update-version-banner'}>
          <BodyText text={'An update is available.'}/>
          <Button actionInline onClick={handleUpdate}>Click here to update</Button>
        </Row>
      }
    </>
  );
}
