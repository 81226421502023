import React from 'react';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';
import classNames from 'classnames';

registerGlobalStyle('.text.text-body', (theme) => ({
  fontSize: '0.875rem',
  '&.dark': {fontWeight: theme.typography.dark},
  '&.descriptive': {color: theme.colors.text.medium},
  '&.italic': {fontStyle: 'italic'},
  '&.nowrap': {textWrap: 'nowrap'},
  '&.small': {fontSize: '0.75rem'},
}));

export function BodyText({className, text, currency, dark, descriptive, italic, noWrap, small, style, onClick, children}) {
  return (
    <p
      className={classNames([
        className,
        'text text-body',
        currency && 'currency',
        dark && 'dark',
        descriptive && 'descriptive',
        italic && 'italic',
        noWrap && 'nowrap',
        small && 'small',
      ])}
      style={style}
      onClick={onClick}
    >{text ?? children}</p>
  );
}

BodyText.propTypes = {
  className: HoopsPropTypes.className,
  text: HoopsPropTypes.string,
  currency: HoopsPropTypes.bool,
  dark: HoopsPropTypes.bool,
  descriptive: HoopsPropTypes.bool,
  italic: HoopsPropTypes.bool,
  noWrap: HoopsPropTypes.bool,
  small: HoopsPropTypes.bool,
  style: HoopsPropTypes.object,
  onClick: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
