import {Divider, MenuItem, MuiThemeProvider, Paper, withStyles} from '@material-ui/core';
import {Popover, Switch, Tooltip} from '@mui/material';
import {Box} from '@mui/system';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import {get, omit} from 'lodash';
import moment from 'moment';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {change as _change, Field, Form, getFormValues, reduxForm, reset as _reset, submit as _submit} from 'redux-form';
import {setFormCustomer as _getCustomer, snackSuccess} from '../../actions/action-types';
import {getCompany as _getCompany} from '../../actions/action-types/company';
import {openCustomerModal as _openCustomerModal, openSendQuoteModal as _openSendQuoteModal} from '../../actions/modals';
import {clearQuoteForm as _clearQuoteForm, getQuote as _getQuote, SET_QUOTE} from '../../actions/quote';
import TaxService from '../../modules/tax/TaxService';
import {initialStateQuote} from '../../reducers/quote';
import {QuoteService} from '../../servicesOld/QuoteService';
import {editEntity} from '../../ui/theme/editEntity';
import green from '../../ui/theme/green';
import ContactAutocomplete from '../customers/ContactAutocomplete';
import EntityListHero from '../EntityList/EntityListHero';
import {datePickerField, renderSelectField, renderTextField, UserAutocompleteField} from '../shared/formHelpers/fields';
import {required} from '../shared/formHelpers/validation';
import {QuoteEditTabs} from './QuoteEditTabs';
import QuoteSettingsMenu from './QuoteSettingsMenu';
import SingleQuoteTable from './SingleQuoteTable';
import {ConfirmDialog} from '../modal/ConfirmDialog';
import {asCurrencyRounded, getLocalStorageItem, putLocalStorageItem} from '../../utils';
import {AddressToField} from '../address/AddressToField';
import {getInvoice} from '../../actions/invoice';
import {permissionCheck, SubscriptionTypes} from '../../componentsHoops/AccessControl';
import {SalesDoc} from '../../pages/SalesDoc/Models/SalesDoc';

const styles = (theme) => ({
  ...editEntity,
  link: {paddingLeft: theme.spacing(2)},
  vertDivider: {
    margin: '0 auto',
    color: theme.colors.grey.light,
  },
  settings: {[theme.breakpoints.down('sm')]: {paddingTop: theme.spacing(3)}},
});

const QUOTE_SHOW_CUSTOMER_LOCALSTORAGE_KEY = 'quote_showCustomerDetails';
const QUOTE_UPDATE_JOB_ON_SAVE = 'quote_updateJobOnSave';
const QUOTE_UPDATE_INVOICE_ON_SAVE = 'quote_updateInvoiceOnSave';

class _QuoteEditPage extends React.Component {
  state = {
    openSendEmailDialog: false,
    openItemBuilderModal: false,
    quoteIdx: 0,
    deletedQuotes: [],
    isLoaded: false,
    dataLoading: false,
    showCustomer: getLocalStorageItem(QUOTE_SHOW_CUSTOMER_LOCALSTORAGE_KEY),
    changed: false,
    showCancelConfirmation: false,
    anchorEl: null,
    updateJobOnSave: getLocalStorageItem(QUOTE_UPDATE_JOB_ON_SAVE, false),
    updateInvoiceOnSave: getLocalStorageItem(QUOTE_UPDATE_INVOICE_ON_SAVE, false),
  };

  handleChanged = () => {
    this.setState({changed: true});
  };

  initialQuoteTerms = () => {
    const {company} = this.props;
    return company.quoteTerms || '';
  };

  initialQuoteItems = () => {
    const {company} = this.props;

    const defaultValue = TaxService.getRevenueDefaultTax(company);
    return [{tax: defaultValue || {}}, {tax: defaultValue || {}}, {tax: defaultValue || {}}];
  };

  initQuote = () => {
    const {company, change, quote, initialOwnerId} = this.props;
    if (company && !get(quote, '_id')) {
      change('items', this.initialQuoteItems());
      change('terms', this.initialQuoteTerms());
      change('quoteOwnerId', initialOwnerId);
    }
  };

  async componentDidMount() {
    const {getQuote, match, clearQuoteForm, resetForm} = this.props;
    clearQuoteForm();
    resetForm();
    this.initQuote();
    this.setState({quoteIdx: 0, isLoaded: false, deletedQuotes: []});

    if (match.params.quoteNumber) {
      await getQuote({quoteNumber: match.params.quoteNumber});
    }

  }

  componentDidUpdate({company: prevCompany}) {
    const {company, quote} = this.props;

    // Redirect all SalesDocs to SalesDoc Edit Page
    if (quote?.documentType && (quote.documentType !== SalesDoc.Type.CLASSIC_QUOTE)) {
        window.location.assign(`/sales/edit/${quote.number}`);
    }

    // First proper load
    if (quote && quote._id && quote.siblings.length > 0 && quote.siblings[0]._id && !this.state.isLoaded) {
      const index = quote.siblings.findIndex((sibling) => sibling._id === quote._id);
      if (index !== -1) {
        this.handleTabChange({current: index});
      } else {
        this.handleTabChange({current: 0});
      }
      this.setState({isLoaded: true});
    }

    if (company && (JSON.stringify(prevCompany) !== JSON.stringify(company))) {
      this.initQuote();
    }
  }

  handleConfirmClose = async (confirmed) => {
    if (confirmed) {
      const {history, resetForm, clearQuoteForm} = this.props;
      clearQuoteForm();
      resetForm();
      history.goBack();
    }
    this.setState({showCancelConfirmation: false});
  };

  cancelAndDeleteDraft = async () => {
    if (this.state.changed) {
      this.setState({showCancelConfirmation: true});
    } else {
      this.handleConfirmClose(true);
    }
  };

  handleSuccess = ({quoteId, openSendEmailDialog}) => {
    const {modalContext, resetForm, clearQuoteForm} = this.props;
    const onClose = get(modalContext, 'onClose');
    if (onClose) {
      onClose({quoteId, openSendEmailDialog});
    }
    clearQuoteForm();
    resetForm();
  };

  saveAndSend = () => {
    const {submit, openSendQuoteModal} = this.props;
    setTimeout(() => openSendQuoteModal({renderSiblings: true}), 200);
    submit();
  };

  saveQuote = async (values) => {
    const {success, history, dispatch, quote, company} = this.props;
    const {openSendEmailDialog, quoteIdx} = this.state;
    const quotes = quote.siblings;
    quotes[quoteIdx] = values; // ensure that the quote has the latest values

    putLocalStorageItem(QUOTE_UPDATE_JOB_ON_SAVE, this.state.updateJobOnSave);
    putLocalStorageItem(QUOTE_UPDATE_INVOICE_ON_SAVE, this.state.updateInvoiceOnSave);

    const quotesPayload = quotes.map((quoteChild) => ({
      ...quoteChild,
      _id: quoteChild._id ? quoteChild._id : undefined, // this is a hack because using defined when updating the firm doesn't work
      customerId: get(values, 'customer._id', null),
      contactId: get(values, 'contact._id', null),
      shipTo: get(values, 'shipTo', null),
      billTo: get(values, 'billTo', null),
    }));

    // If job is marked as complete prevent job from being updated
    const canUpdateJob = !quote?.job?.completed;

    // If quote is paid prevent invoice from being updated
    const canUpdateInvoice =
      quote?.invoice?._id &&
      (company?.accountingPlatform?.platform === 'QUICKBOOKS' ||
        (company?.accountingPlatform?.platform === 'XERO' && asCurrencyRounded(quote.invoice.balance) === asCurrencyRounded(quote.invoice.totalAmount)));

    await QuoteService.upsertQuotes({
      quotes: quotesPayload,
      deletedQuotes: this.state.deletedQuotes,
      updateJob: canUpdateJob && this.state.updateJobOnSave,
      updateInvoice: canUpdateInvoice && this.state.updateInvoiceOnSave,
    }).then((quotesResponse) => {
      const [quoteResponse] = quotesResponse;
      success('Quotes Saved');
      this.handleSuccess({quoteId: quoteResponse._id, openSendEmailDialog});

      const invoiceId = quote?.invoice?._id;

      if (this.state.updateInvoiceOnSave && invoiceId && canUpdateInvoice) {
        dispatch(getInvoice(invoiceId));
      }

      // This is used for the Save & Send
      dispatch({
        type: SET_QUOTE,
        payload: quoteResponse,
      });

      if (!openSendEmailDialog) {
        history.push('/quotes');
      }
    });
  };

  handleSelectCustomerContact = (value) => {
    const {change, quote} = this.props;
    const contact = value._id ? value : null;
    const customer = contact && value.customer ? value.customer : null;

    change('contact', contact);
    change('customer', customer);

    quote.siblings.forEach((_, idx) => {
      change(`siblings[${idx}].contact`, contact);
      change(`siblings[${idx}].customer`, customer);
    });

    this.handleChanged();
  };

  openEditCustomerModal = (_id) => {
    const {getCustomer, openCustomerModal, change, quote} = this.props;
    getCustomer(_id, () => {
      openCustomerModal((customer) => {
        const contact = JSON.parse(JSON.stringify(customer.contacts[0]?._id ? {customer, ...customer.contacts[0]} : null));
        if (customer && customer.contacts) {
          change('customer', customer);
          change('contact', contact);
          quote.siblings.forEach((_, idx) => {
            change(`siblings[${idx}].contact`, contact);
            change(`siblings[${idx}].customer`, customer);
          });
        }
      });
      this.handleChanged();
    });
  };

  handleCustomerProfileOpen = (e) => {
    this.setState({anchorEl: e.currentTarget});
  };

  handleCustomerProfileClose = () => {
    this.setState({anchorEl: null});
  };

  handleRenameOption = (index, newTitle) => {
    const {change} = this.props;
    change('title', newTitle);
    change(`siblings[${index}].title`, newTitle);
    this.handleChanged();
  };

  handleRemoveOption = (index) => {
    const {quote, change} = this.props;
    const quoteClone = JSON.parse(JSON.stringify(quote));
    const [quoteRemoved] = quoteClone.siblings.splice(index, 1);
    const nextIdx = index === 0 ? 1 : index - 1; // up if Zero, down if anyything else

    this.setState((prev) => {
      const deletedQuotes = quoteRemoved._id ? [...prev.deletedQuotes, quoteRemoved._id] : prev.deletedQuotes;

      return {
        ...prev,
        deletedQuotes,
      };
    });

    // update quotes (we mutated above with splice)
    const newPayload = {
      ...quoteClone.siblings[nextIdx],
      siblings: quoteClone.siblings,
    };
    // this was the only way that I could find with provided methods to
    // update an entire
    Object.keys(newPayload).map((key) => change(key, newPayload[key]));

    // change the tab selected
    setTimeout(() => {
      this.setState({quoteIdx: nextIdx});
    }, 50);

    this.handleChanged();
  };

  handleDuplicateOption = async () => {
    const {quote} = this.props;
    const quoteReduced = omit(quote, ['isQuoteAccepted', 'quoteAcceptedAt', 'createdAt', 'updatedAt', 'status']);
    const quoteClone = JSON.parse(JSON.stringify(quoteReduced));
    const {items = []} = quoteClone;

    this.setState({dataLoading: true});

    // iterate over items and duplicate from server
    const promises = items.map(async (lineItem) => await QuoteService.duplicateQuoteItemData(lineItem));

    const duplicatedItems = await Promise.all(promises);
    const payload = {
      ...quoteClone,
      items: duplicatedItems,
    };
    this.handleAddOption(payload);
    this.setState({dataLoading: false});
    this.handleChanged();
  };

  handleAddOption = (quoteData = {}) => {
    const {change, quote} = this.props;
    const quoteClone = JSON.parse(JSON.stringify(quote));

    const payload = [
      ...(quoteClone.siblings || []),
      {
        ...initialStateQuote,
        terms: this.initialQuoteTerms(),
        items: this.initialQuoteItems(),
        ...quoteData,
        title: `Option ${quoteClone.siblings.length + 1}`,
        _id: null, // DO NOT clone the quote ID (we can't use undefined which is preferred)
        customer: quoteClone.customer,
        contact: quoteClone.contact,
        shipTo: quoteClone.shipTo,
        billTo: quoteClone.billTo,
      },
    ];

    change('siblings', payload);

    setTimeout(() => {
      this.handleTabChange({previous: this.state.quoteIdx, current: payload.length - 1});
    }, 50);

    this.handleChanged();
  };

  handleTabChange = ({previous, current}) => {
    const {quote, change, resetForm} = this.props;
    const quoteClone = JSON.parse(JSON.stringify(quote));

    const newPayload = {
      ...JSON.parse(JSON.stringify(quoteClone.siblings[current])),
      siblings: JSON.parse(JSON.stringify(quoteClone.siblings)),
    };

    if (typeof previous === 'number') {
      // Store the previous quote details as a sibling
      newPayload.siblings[previous] = omit(quoteClone, 'siblings');
    }

    resetForm();

    // this was the only way that I could find with provided methods to
    // update an entire
    Object.keys(newPayload).map((key) => change(key, newPayload[key]));

    this.setState({quoteIdx: current});
  };

  handleShowCustomer = () => {
    const newStatus = !this.state.showCustomer;
    if (newStatus) {
      putLocalStorageItem(QUOTE_SHOW_CUSTOMER_LOCALSTORAGE_KEY, 'show');
    } else {
      putLocalStorageItem(QUOTE_SHOW_CUSTOMER_LOCALSTORAGE_KEY, '');
    }
    this.setState({showCustomer: newStatus});
  };

  handleCommentsToggle = (isTrue) => {
    const {change} = this.props;
    change('settings.canContactComment', isTrue);
  };

  handleAcceptOnlineToggle = (isTrue) => {
    const {change} = this.props;
    change('settings.canContactAccept', isTrue);
  };

  handlePayOnlineToggle = (isTrue) => {
    const {change} = this.props;
    change('settings.canContactPay', isTrue);
  };

  handleHideTotalToggle = (isTrue) => {
    const {change} = this.props;
    change('settings.hideTotal', isTrue);
  };

  handleReadyForSaveCheck = () => {
    const {invalid, match, quote} = this.props;
    // disable save if form is invalid
    if (invalid) {
      return true;
    }
    // disable save if not a new quote and the form hasn't been updated
    if (match.path !== '/quotes/new' && !this.state.changed) {
      return true;
    }
    // disable save if new or duplicate form and quantity isn't defined
    if (!match?.params?.quoteNumber && !quote?.items[0]?.quantity) {
      return true;
    }
    return false;
  };

  render() {
    const {classes, quote, handleSubmit, invalid, match, user, company} = this.props;
    const {showCustomer} = this.state;
    if (!quote) {
      return null;
    }
    const addresses = get(quote, 'customer.addresses');
    const activeQuote = quote.siblings[this.state.quoteIdx];
    const defaultShipTo = quote.shipTo || addresses?.find(({label}) => label === 'SHIPPING') || addresses?.[0];
    const defaultBillTo = quote.billTo || addresses?.find(({label}) => label === 'BILLING') || addresses?.[0];
    const open = Boolean(this.state.anchorEl);

    // If job is marked as complete prevent job from being updated
    const canUpdateJob = !quote?.job?.completed;

    // If quote is paid prevent invoice from being updated
    const canUpdateInvoice =
      quote?.invoice?._id &&
      (company?.accountingPlatform?.platform === 'QUICKBOOKS' ||
        (company?.accountingPlatform?.platform === 'XERO' && asCurrencyRounded(quote.invoice.balance) === asCurrencyRounded(quote.invoice.totalAmount)));

    return (
      <>
        <Form autoComplete={'off'} onSubmit={handleSubmit((values) => this.saveQuote(values))}>
          <Grid container>
            <EntityListHero
              title={(match.params.quoteNumber ? 'Edit ' : 'New ') + 'Quote'}
              subtitle='Create a perfect quote in less than 1 minute.'
              helpArticleUrl='http://help.hoopscrm.com/en/articles/4632984-creating-quotes'
              videoId='oJjG9FUEMUo'
            >
              <Grid container direction='column' alignItems='flex-end'>
                <Grid item direction={'row'}>
                  <Button
                    data-intercom-target={'quote-create'}
                    onClick={this.cancelAndDeleteDraft}
                    className={`${classes.whiteButton} ${classes.buttons}`}
                    disableElevation
                    variant={'contained'}
                  >
                    Cancel
                  </Button>
                  <Button
                    color={'primary'}
                    type='submit'
                    data-intercom-target={'quote-save'}
                    disabled={this.handleReadyForSaveCheck()}
                    className={classes.buttons}
                    variant={'contained'}
                  >
                    Save
                  </Button>
                  <MuiThemeProvider theme={green}>
                    <Button
                      data-intercom-target={'quote-save-send'}
                      disabled={this.handleReadyForSaveCheck()}
                      onClick={this.saveAndSend}
                      color={'primary'}
                      className={classes.buttons}
                      variant={'contained'}
                    >
                      Save &amp; Send
                    </Button>
                  </MuiThemeProvider>
                </Grid>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto max-content auto',
                    alignItems: 'center',
                    paddingTop: 1,
                    columnGap: 1,
                    '>*': {marginTop: -1},
                  }}
                >
                  {quote?.job?._id && (
                    <>
                      <Tooltip
                        placement='left'
                        arrow
                        classes={{tooltip: classes.tooltip}}
                        title={
                          'This will update the products, decorations and other line items on the Job. This will not update the Customer/Contact, Reference, or Deadline of the Job.'
                        }
                      >
                        <InfoIcon fontSize='small' className={classes.icon} />
                      </Tooltip>
                      <Typography variant='body2' display='inline'>
                        Update Job
                      </Typography>
                      <Tooltip arrow title={canUpdateJob ? '' : 'This Job is marked as complete and can no longer be updated.'}>
                        <Switch
                          disabled={!canUpdateJob}
                          checked={canUpdateJob && this.state.updateJobOnSave}
                          color='primary'
                          onChange={(e, checked) => this.setState({updateJobOnSave: checked})}
                          name='allowComments'
                          inputProps={{'aria-label': 'secondary checkbox'}}
                          sx={{'& .MuiButtonBase-root.Mui-disabled': {pointerEvents: 'auto'}}}
                        />
                      </Tooltip>
                    </>
                  )}
                  {quote?.invoice?._id && (
                    <>
                      <Tooltip
                        placement='left'
                        arrow
                        classes={{tooltip: classes.tooltip}}
                        title={'This will update the invoice line items. It will not update the Customer or the Reference.'}
                      >
                        <InfoIcon fontSize='small' className={classes.icon} />
                      </Tooltip>
                      <Typography variant='body2' display='inline'>
                        Update Invoice
                      </Typography>
                      <Tooltip
                        arrow
                        title={
                          canUpdateInvoice
                            ? ''
                            : 'As there are payments applied, the invoice cannot be updated. Learn more in Xero Help. ' +
                              'To make changes, first Remove & Redo any payments and delete any credit note allocations.'
                        }
                      >
                        <Switch
                          disabled={!canUpdateInvoice}
                          checked={canUpdateInvoice && this.state.updateInvoiceOnSave}
                          color='primary'
                          onChange={(e, checked) => this.setState({updateInvoiceOnSave: checked})}
                          name='allowComments'
                          inputProps={{'aria-label': 'secondary checkbox'}}
                          sx={{'& .MuiButtonBase-root.Mui-disabled': {pointerEvents: 'auto'}}}
                        />
                      </Tooltip>
                    </>
                  )}
                </Box>
              </Grid>
            </EntityListHero>
            <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={3} style={{minWidth: 'fit-content'}}>
              <Grid item xs={12} data-intercom-target={'quote-table'}>
                <Paper>
                  <Grid container className={classes.header}>
                    <Grid container justifyContent={'space-between'}>
                      <Grid item xs={12}>
                        <Grid container spacing={4} alignItems={'center'}>
                          <Grid item xs={12}>
                            <Grid container direction='row' justifyContent='flex-start' alignItems='center' alignContent='center' spacing={2}>
                              <Grid item xs={6}>
                                <Typography variant='caption' display='block' className={classes.labelText}>
                                  Select Customer
                                </Typography>
                                <Field
                                  name={'contact'}
                                  component={ContactAutocomplete}
                                  fullWidth
                                  className={classes.inputStyles}
                                  textFieldProps={{variant: 'outlined'}}
                                  validate={required}
                                  size='small'
                                  onChange={this.handleSelectCustomerContact}
                                  data-intercom-target={'quote_customer_name'}
                                />
                              </Grid>
                              <Grid item xs={4} style={{paddingTop: 40}}>
                                {quote?.customer?._id && (
                                  <Button
                                    color='primary'
                                    variant='outlined'
                                    style={{marginRight: 16}}
                                    onClick={() => this.openEditCustomerModal(quote?.customer?._id)}
                                  >
                                    Edit Customer
                                  </Button>
                                )}
                                {quote?.customer?.profile?.length > 0 && (
                                  <Button color='primary' variant='outlined' onClick={this.handleCustomerProfileOpen}>
                                    View Profile
                                  </Button>
                                )}
                              </Grid>
                              <Grid item xs={2} style={{paddingTop: 40, textAlign: 'right'}}>
                                <Button color='primary' className={classes.link} style={invalid ? {display: 'none'} : null} onClick={this.handleShowCustomer}>
                                  {showCustomer ? 'Hide' : 'Show'} details
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          {quote && quote.customer && quote.customer._id && showCustomer && (
                            <Grid item xs={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item xs={3}>
                                      <Typography variant='caption' className={classes.greyText}>
                                        Company
                                      </Typography>
                                      <Typography variant='body2' className={classes.breakWordOverflow}>
                                        {quote.customer.name}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography variant='caption' className={classes.greyText}>
                                        Contact Name
                                      </Typography>
                                      <Typography variant='body2' className={classes.breakWordOverflow}>
                                        {get(quote, 'contact.fullName', '')}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography variant='caption' className={classes.greyText}>
                                        Email
                                      </Typography>
                                      <Typography variant='body2' className={classes.breakWordOverflow}>
                                        {quote.contact.email}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography variant='caption' className={classes.greyText}>
                                        Contact Number
                                      </Typography>
                                      <Typography variant='body2' className={classes.breakWordOverflow}>
                                        {quote.customer.phone}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item xs={3}>
                                      <AddressToField
                                        classes={classes}
                                        type={'BILLING'}
                                        addresses={addresses}
                                        defaultValues={defaultBillTo}
                                        name={'billTo'}
                                        change={(...value) => {
                                          this.handleChanged();
                                          this.props.change(...value);
                                        }}
                                        value={quote.billTo}
                                        setAddressTo={this.setBillingAddressTo}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <AddressToField
                                        classes={classes}
                                        type={'SHIPPING'}
                                        name={'shipTo'}
                                        addresses={addresses}
                                        defaultValues={defaultShipTo}
                                        change={(...value) => {
                                          this.handleChanged();
                                          this.props.change(...value);
                                        }}
                                        value={quote.shipTo}
                                        setAddressTo={this.setShippingAddressTo}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography variant='caption' className={classes.greyText}>
                                        Minimum Margin
                                      </Typography>
                                      {quote.customer.settings && <Typography variant='body2'>{quote.customer.settings?.minimumMarkup}</Typography>}
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography variant='caption' className={classes.greyText}>
                                        Sales Discount
                                      </Typography>
                                      {quote.customer.settings && <Typography variant='body2'>{quote.customer.settings?.salesDiscount}</Typography>}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <QuoteEditTabs
                    quotes={quote.siblings}
                    activeIndex={this.state.quoteIdx}
                    onRenameOption={this.handleRenameOption}
                    onRemoveOption={this.handleRemoveOption}
                    onDuplicateOption={this.handleDuplicateOption}
                    onAddOption={this.handleAddOption}
                    onTabChange={this.handleTabChange}
                  />

                  <Grid container justifyContent={'space-between'} alignItems={'flex-start'} className={classes.entityDetails}>
                    {/* description */}
                    <Grid item md={9} sm={12}>
                      <Grid container spacing={3}>
                        <Grid item md={permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) ? 1 : 2} sm={4}>
                          <Typography variant='caption' className={classes.greyText}>
                            Quote Number
                          </Typography>
                          <Typography variant='body1' className={classes.headerInfo}>
                            {' '}
                            {activeQuote && activeQuote.number ? <span>{activeQuote.number}</span> : <span>DRAFT</span>}
                          </Typography>
                        </Grid>
                        <Grid item md={permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) ? 1 : 2} sm={4}>
                          <Typography variant='caption' className={classes.greyText}>
                            Date
                          </Typography>
                          <Typography variant='body1' className={classes.headerInfo}>
                            {get(activeQuote, 'createdAt') ? moment(activeQuote.createdAt, 'x').format('DD MMM. YYYY') : '--'}
                          </Typography>
                        </Grid>
                        <Grid item md={2} sm={4}>
                          <Typography variant='caption' className={classes.greyText}>
                            Creator
                          </Typography>
                          <Typography variant='body1' className={classes.headerInfo}>
                            {get(activeQuote, 'createdBy.fullName', '--')}
                          </Typography>
                        </Grid>
                        <Grid item md={2} sm={12}>
                          <Typography variant='caption' className={classes.greyText}>
                            Quote Owner
                          </Typography>
                          <Field
                            name={'quoteOwnerId'}
                            component={UserAutocompleteField}
                            className={classes.inputStyles}
                            onChange={this.handleChanged}
                            props={{disableClearable: true}}
                          />
                        </Grid>
                        {permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) && (
                          <Grid item md={2} sm={12}>
                            <Typography variant='caption' className={classes.greyText}>
                              Lead Source
                            </Typography>
                            <Field
                              name={'leadSource'}
                              variant='outlined'
                              className={classes.inputStyles}
                              component={renderSelectField}
                              onChange={this.handleChanged}
                            >
                              {company?.leadTypes?.map((lead) => (
                                <MenuItem key={lead} value={lead}>
                                  {lead}
                                </MenuItem>
                              ))}
                            </Field>
                          </Grid>
                        )}
                        <Grid item md={2} sm={12}>
                          <Typography variant='caption' className={classes.greyText}>
                            Deadline
                          </Typography>
                          <Field
                            data-intercom-target={'quote-deadlineAt-field'}
                            name={'deadlineAt'}
                            component={datePickerField}
                            fullWidth
                            className={classes.inputStyles}
                            onChange={this.handleChanged}
                          />
                        </Grid>

                        <Grid item md={2} sm={12}>
                          <Typography variant='caption' className={classes.greyText}>
                            Reference Number
                          </Typography>
                          <Field
                            component={renderTextField}
                            name={'customReference'}
                            variant='outlined'
                            size='small'
                            fullWidth
                            className={classes.inputStyles}
                            onChange={this.handleChanged}
                          />
                        </Grid>
                      </Grid>
                      <Grid item md={12} sm={12}>
                        <Typography variant='caption' className={classes.greyText}>
                          Description
                        </Typography>
                        <Field
                          component={renderTextField}
                          name={'description'}
                          variant='outlined'
                          size='small'
                          fullWidth
                          placeholder='Give the quote some context to increase conversions..'
                          onChange={this.handleChanged}
                        />
                      </Grid>
                    </Grid>

                    <Grid item md={3} sm={12} className={classes.settings}>
                      <Grid container>
                        <Grid item md={3} sm={0} className={classes.hideIfSmall}>
                          <Divider orientation='vertical' light variant='fullWidth' className={classes.vertDivider} />
                        </Grid>
                        <Grid item md={9} sm={12}>
                          <QuoteSettingsMenu
                            quoteIsDraft={!quote?.number}
                            canContactComment={get(quote, 'settings.canContactComment')}
                            canContactAccept={get(quote, 'settings.canContactAccept')}
                            canContactPay={get(quote, 'settings.canContactPay')}
                            hideTotal={get(quote, 'settings.hideTotal')}
                            handleCommentsToggle={this.handleCommentsToggle}
                            handleAcceptOnlineToggle={this.handleAcceptOnlineToggle}
                            handlePayOnlineToggle={this.handlePayOnlineToggle}
                            handleHideTotalToggle={this.handleHideTotalToggle}
                            user={user}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.entityTable}>
                    <SingleQuoteTable quote={quote} onChange={this.handleChanged} />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          {this.state.showCancelConfirmation && (
            <ConfirmDialog
              title={'Cancel Editing?'}
              content={'Changes you have made so far will not be saved.'}
              cancelText={'Keep Editing'}
              okText={'Yes, Cancel'}
              onClose={this.handleConfirmClose}
            />
          )}
        </Form>
        <Popover
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleCustomerProfileClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            '& .MuiPopover-paper': {
              p: 2,
              width: 366,
              mt: 2,
            },
          }}
        >
          <Typography variant='body2'>
            <b>{quote?.customer?.name}</b>
          </Typography>
          <Typography variant='caption' style={{whiteSpace: 'pre-line'}}>
            {quote?.customer?.profile}
          </Typography>
        </Popover>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authReducer.newUser,
    initialOwnerId: state.authReducer.userData._id,
    company: state.companyReducer.company,
    quote: getFormValues('quoteForm')(state),
    initialValues: state.quoteReducer.quoteForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submit: () => dispatch(_submit('quoteForm')),
    openSendQuoteModal: (context) => dispatch(_openSendQuoteModal(context)),
    change: (field, value) => dispatch(_change('quoteForm', field, value)),

    // this actually resets the form we are using
    resetForm: () => dispatch(_reset('quoteForm')),

    // This is only for the initial quote from in Redux
    clearQuoteForm: () => dispatch(_clearQuoteForm()),
    getQuote: (args) => dispatch(_getQuote(args)),
    success: (message) => dispatch(snackSuccess(message)),
    getCompany: () => dispatch(_getCompany()),
    openCustomerModal: (callback) => dispatch(_openCustomerModal({callback})),
    getCustomer: (_id, cb) => dispatch(_getCustomer(_id, cb))
  };
}

export const QuoteEditPage = connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(reduxForm({
        form: 'quoteForm',
        enableReinitialize: true,
        validate: (values) => {
          const errors = {};
          if (!values.contact || !values.contact._id) {
            errors.contact = 'Contact is required';
          }
          return errors;
        }
      })(_QuoteEditPage))));
