import {makeStyles} from '@material-ui/core';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmailIcon from '@mui/icons-material/Email';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import SettingsIcon from '@mui/icons-material/Settings';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {PermissionBasedContent, roles, SubscriptionTypes} from '../../componentsHoops/AccessControl';
import {featureFlagEnabled, featureFlags} from '../../utils/featureFlag';

const useStyles = makeStyles ((theme) => ({
  settingsIcon: {color: theme.palette.common.white},
  navSettings: {
    display: 'flex',
    justifyContent: 'space-between',
    direction: 'ltr',
    alignItems: 'center',
    width: '100%',
    padding: '16px 26px',
    cursor: 'pointer'
  }
}));

function SettingsMenu({collapsed = false}) {
  const [anchorEl, setAnchorEl] = useState(false);
  const classes = useStyles();
  const {admin, standard} = roles;

  const toggleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        onClick={toggleMenu}
        size='large'
        className={classes.navSettings}
        style={{padding: collapsed ? '18px 28px 18px 20px' : '18px 26px',}}
      >
        <ListItemIcon className={classes.listItemIcon}><SettingsIcon /></ListItemIcon>
        <ListItemText primary='Settings' secondaryTypographyProps={{className: classes.badgeContainer}} />
        <ArrowForwardIosIcon fontSize='small' />
      </div>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={4}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <PermissionBasedContent allowedRoles={[admin, standard]}>
          <MenuItem to={'/settings/organisation'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <Icon>business</Icon>
            </ListItemIcon>
            <ListItemText primary='My Organisation' />
          </MenuItem>
        </PermissionBasedContent>
        <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]} allowedRoles={[admin]}>
          <MenuItem to={'/settings/decorations'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <Icon>highlight</Icon>
            </ListItemIcon>
            <ListItemText primary='Decorations' />
          </MenuItem>
        </PermissionBasedContent>
        <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]} allowedRoles={[admin, standard]}>
          <MenuItem to={'/settings/vendors'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <Icon>store</Icon>
            </ListItemIcon>
            <ListItemText primary='Vendors' />
          </MenuItem>
        </PermissionBasedContent>
        <PermissionBasedContent allowedRoles={[admin]}>
          <MenuItem to={'/settings/users'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <Icon>person</Icon>
            </ListItemIcon>
            <ListItemText primary='Users' />
          </MenuItem>
        </PermissionBasedContent>
        <PermissionBasedContent allowedRoles={[admin]}>
          <MenuItem to={'/settings/billing'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <Icon>credit_card</Icon>
            </ListItemIcon>
            <ListItemText primary='Billing' />
          </MenuItem>
        </PermissionBasedContent>
        <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]} allowedRoles={[admin, standard]}>
          <MenuItem to={'/settings/integrations'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary='Integrations' />
          </MenuItem>
        </PermissionBasedContent>
        <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]} allowedRoles={[admin, standard]}>
          <MenuItem to={'/settings/statuses'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <Icon>label</Icon>
            </ListItemIcon>
            <ListItemText primary='Status' />
          </MenuItem>
        </PermissionBasedContent>
        <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]} allowedRoles={[admin, standard]}>
          <MenuItem to={'/settings/resources'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <ViewCompactIcon />
            </ListItemIcon>
            <ListItemText primary='Resources' />
          </MenuItem>
        </PermissionBasedContent>
        <PermissionBasedContent allowedRoles={[admin, standard]}>
          <MenuItem to={'/settings/email-templates'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary='Templates' />
          </MenuItem>
        </PermissionBasedContent>
        <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]} allowedRoles={[admin]}>
          <MenuItem to={'/settings/taxes'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary='Taxes' />
          </MenuItem>
        </PermissionBasedContent>

        {featureFlagEnabled(featureFlags.markups) && <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]} allowedRoles={[admin, standard]}>
          <MenuItem to={'/settings/markups'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary='Markups' />
          </MenuItem>
        </PermissionBasedContent>}

        {featureFlagEnabled(featureFlags.automations) && <PermissionBasedContent allowedRoles={[admin, standard]}>
          <MenuItem to={'/settings/automations'} component={Link} onClick={handleClose}>
            <ListItemIcon>
              <FlashOnIcon />
            </ListItemIcon>
            <ListItemText primary='Automations' />
          </MenuItem>
        </PermissionBasedContent>}

      </Menu>
    </>
  );
}

export default SettingsMenu;
