import {useCallback, useState} from 'react';
import {getLocalStorageItem, putLocalStorageItem, removeLocalStorageItem} from '../utils';

// Similar to useState but first arg is key to the value in local storage. If the key is falsey this
// acts exactly the same useState()
export function useLocalStorageState(keys, initialValue) {
  const key = makeStorageKey(keys);
  const [value, _setValue] = useState(() => getLocalStorageItem(key, initialValue));

  // Fetch a stored value record using the keys
  const getValue = useCallback(() => getLocalStorageItem(key, value), [key, value]);

  // Remove a stored value record using the keys
  const removeValue = useCallback(() => {
    removeLocalStorageItem(key);
    _setValue(initialValue);
  }, [key, initialValue]);

  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = useCallback((newValue) => {
    _setValue((prev) => {
      try {
        // Allow value to be a function, so we have same API as useState
        let valueToStore = typeof newValue === 'function' ? newValue(prev) : newValue;
        if (valueToStore === undefined) {
          valueToStore = prev;
        }

        // Save to local storage
        putLocalStorageItem(key, valueToStore);

        // Return the new value
        return valueToStore;
      } catch (error) {
        console.log(`Error storing value ${key}`, error);
        return prev;
      }
    });
  }, [key]);

  return [value, setValue, getValue, removeValue];
}

function makeStorageKey(keys) {
  if (!Array.isArray(keys)) {
    return keys;
  }
  if (keys.some((key) => !key)) {
    return null;
  }
  return keys.join('|');
}

// Similar to useState but first arg is a variable where the state will be stored across mounts and unmounts.
export function useMemoryState(store, key, initialValue) {
  const [value, _setValue] = useState(key ? store[key] ?? initialValue : initialValue);

  const setValue = useCallback((newValue) => {
    _setValue((prev) => {
      // Allow value to be a function, so we have same API as useState
      newValue = typeof newValue === 'function' ? newValue(prev) : newValue;
      if (newValue === undefined) {
        newValue = prev;
      }

      if (key) {
        store[key] = newValue;
      }

      return newValue;
    });
  }, [key, store]);

  return [value, setValue];
}
