import React, {createContext, useContext} from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {useCompany} from '../../hooks';

registerGlobalStyle('.hoops-page', (theme) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  minWidth: 600,
  overflow: 'hidden',
  '&.page-paper': {backgroundColor: theme.colors.background.white,},
  '.page-scroll-container': {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '&.page-pad': {padding: theme.spacing(3)},
    '&.align-center': {alignItems: 'center'},
  },
  '.page-header .text-heading': {
    fontSize: '2.125rem',
    span: {color: theme.colors.text.highlight}
  },
}));

export const PageContext = createContext(null);
export const usePageContext = () => useContext(PageContext);

export function Page({className, testId, alignCenter, context, pad, paper, title, children}) {
  const {company} = useCompany();
  const currencySymbol = context?.currencySymbol ?? company.currencySymbol;

  if (title) {
    document.title = title;
  }

  document.documentElement.style.setProperty('--currencyContent', `"${currencySymbol}"`);
  document.documentElement.style.setProperty('--currencySymbol', `${currencySymbol}`);

  return (
    <div className={classNames([className, 'hoops-page', paper && 'page-paper'])} id={testId}>
      <div className={classNames(['page-scroll-container', pad && 'page-pad', alignCenter && 'align-center'])}>
        <PageContext.Provider value={context}>
          {children}
        </PageContext.Provider>
      </div>
    </div>
  );
}

Page.propTypes = {
  className: HoopsPropTypes.className,
  testId: HoopsPropTypes.string,
  alignCenter: HoopsPropTypes.bool,
  context: HoopsPropTypes.object,
  pad: HoopsPropTypes.bool,
  paper: HoopsPropTypes.bool,
  title: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};

export function PageContainer({className, style, children}) {
  return (
    <div className={classNames([className, 'page-container'])} style={style}>
      {children}
    </div>
  );
}

PageContainer.propTypes = {
  className: HoopsPropTypes.className,
  style: HoopsPropTypes.object,
  children: HoopsPropTypes.children,
};
